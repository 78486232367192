import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'mobx-react-form';
import { inject, observer } from 'mobx-react';
import validatorjs from 'validatorjs';

import { MASTER_CREATORS_ID, NEWSLETTER_GROUP_ID } from 'constants/mailchimp';
import { interpretSubscribeResponse } from 'utils/mailchimpUtils';

import mailchimp from 'services/mailchimp';

import NewsletterForm from 'components/forms/NewsletterSubscription';

class FormHandler extends Form {

  constructor(submitCallback, submitCompleteCallback) {
    super();
    this.submitCallback = submitCallback;
    this.submitCompleteCallback = submitCompleteCallback;
  }

  plugins() {
    return {
      dvr: validatorjs,
    };
  }

  setup() {
    return {
      fields: [
        {
          name: 'EMAIL',
          label: 'Email',
          type: 'email',
          rules: 'required|string|email',
        },
        {
          name: 'GROUP',
          label: 'group',
          rules: 'required',
          type: 'hidden',
          value: NEWSLETTER_GROUP_ID,
        },
      ],
    };
  }

  options() {
    return {
      validateOnChange: false,
      validateOnBlur: false,
    };
  }

  hooks() {
    return {
      onSuccess(form) {
        // Change from the default Mailchimp URL to the JSON version
        mailchimp.addToList(this.mailchimpListId, form.values().EMAIL, {
          GROUP: form.values().GROUP,
        })
          .then(results => {
            const { isSuccess, error } = interpretSubscribeResponse(results);
            this.submitCompleteCallback(false, isSuccess, error);
          })
          .catch(error => {
            this.submitCompleteCallback(false, false, error.statusText);
          });
      },

      onError() {
        this.state.options.set({
          validateOnChange: true,
        });
      },
    };
  }

  onSubmit(e) {
    this.submitCallback(true);
    super.onSubmit(e);
  }
}

@inject('translationStore') @observer
class NewsletterSubscriptionForm extends Component {

  constructor(props) {
    super(props);

    const { mailchimpListId } = props;

    this.formHandler = new FormHandler(this.onSubmitCallback, this.onSubmitCompleteCallback);

    // if no id is passed in use the master creators list ID
    this.mailchimpListId = mailchimpListId || MASTER_CREATORS_ID;
    this.formHandler.mailchimpListId = this.mailchimpListId;

    this.state = {
      pending: false,
      successStatus: undefined,
      errorStatus: undefined,
    };
  }

  onSubmitCallback = (pending) => {
    this.setState({
      pending: pending,
    });
  }

  onSubmitCompleteCallback = (pending, successStatus, errorStatus) => {
    this.setState({
      pending: pending,
      successStatus: successStatus,
      errorStatus: errorStatus,
    });
  }

  render() {
    const {
      translationStore,
      inputPlaceholder,
      darkMode,
      lightButton,
      inlineMessages,
      hideLabel,
      buttonLabel,
    } = this.props;

    return (
      <NewsletterForm
        mailchimpListId={this.mailchimpListId}
        form={this.formHandler}
        inputPlaceholder={inputPlaceholder}
        globalTranslations={translationStore.globalTranslations}
        success={this.state.successStatus}
        errorMessage={this.state.errorStatus}
        pending={this.state.pending}
        darkMode={darkMode}
        lightButton={lightButton}
        inlineMessages={inlineMessages}
        hideLabel={hideLabel}
        buttonLabel={buttonLabel}
      />
    );
  }
}

NewsletterSubscriptionForm.propTypes = {
  translationStore: PropTypes.object,
  inputPlaceholder: PropTypes.string,
  darkMode: PropTypes.bool,
  lightButton: PropTypes.bool,
  mailchimpListId: PropTypes.string,
  inlineMessages: PropTypes.bool,
  hideLabel: PropTypes.bool,
  buttonLabel: PropTypes.string,
};

export default NewsletterSubscriptionForm;
