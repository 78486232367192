import React from 'react';
import PropTypes from 'prop-types';

const PaymentIconMaestro = ({ className, ...props }) => (
  <svg className={className} version='1.1' width='72' height='43' viewBox='0 0 72 43' {...props}>
    <title>Maestro Card Icon</title>
    <path fill='#006' d='M0 0h71v43H0z' />
    <path d='M59.396 21.488c0 7.923-6.337 14.345-14.157 14.345S31.082 29.41 31.082 21.488c0-7.923 6.338-14.345 14.156-14.345 7.82 0 14.156 6.422 14.156 14.345' fill='#C00' />
    <path d='M34.304 30.598c-.39-.48-.75-.988-1.08-1.52h5.02c.3-.486.572-.994.814-1.518H32.41c-.227-.492-.428-1-.6-1.52h7.847c.472-1.43.727-2.96.727-4.552 0-1.042-.11-2.06-.317-3.037h-8.665c.11-.517.247-1.023.41-1.52h7.844c-.172-.52-.373-1.026-.6-1.517h-6.645c.244-.525.517-1.03.817-1.52h5.014c-.326-.53-.687-1.036-1.077-1.517h-2.858c.44-.54.918-1.048 1.43-1.518-2.513-2.31-5.848-3.715-9.506-3.715-7.82 0-14.16 6.422-14.16 14.345 0 7.923 6.34 14.345 14.16 14.345 3.657 0 6.992-1.406 9.504-3.713.512-.47.99-.98 1.43-1.522h-2.86' fill='#06C' />
    <path d='M35.532 25.365c-.605.162-1.19.242-1.808.24-1.973-.002-3-.912-3-2.654 0-2.033 1.307-3.53 3.083-3.53 1.453 0 2.38.84 2.38 2.154 0 .436-.063.86-.217 1.462h-3.51c-.123.85.49 1.224 1.533 1.224.625 0 1.19-.114 1.816-.37l-.278 1.475zm-.943-3.53c0-.127.194-1.04-.822-1.06-.56 0-.962.377-1.125 1.06h1.946zM36.72 21.437c0 .75.42 1.27 1.368 1.657.726.3.84.39.84.658 0 .373-.32.544-1.033.54-.534-.005-1.022-.07-1.598-.232l-.255 1.37c.512.12 1.23.16 1.866.175 1.895 0 2.77-.628 2.77-1.987 0-.817-.365-1.298-1.265-1.656-.752-.305-.84-.37-.84-.646 0-.324.297-.487.878-.487.354 0 .836.03 1.294.088l.256-1.38c-.466-.064-1.173-.117-1.583-.117-2.007 0-2.702.917-2.696 2.017M29.76 25.507h-1.47l.034-.625c-.45.493-1.047.723-1.858.723-.96 0-1.618-.666-1.618-1.622 0-1.455 1.143-2.294 3.11-2.294.202 0 .46.014.723.045.055-.195.07-.28.07-.385 0-.398-.31-.544-1.137-.544-.815.003-1.366.125-1.877.264l.252-1.334c.883-.228 1.46-.316 2.116-.316 1.522 0 2.327.605 2.327 1.742.013.304-.09.913-.143 1.18-.06.387-.482 2.635-.526 3.165zm-1.29-2.654c-.187-.018-.27-.024-.396-.024-1.004 0-1.513.302-1.513.9 0 .375.25.61.636.61.722 0 1.243-.612 1.273-1.487zM43.78 25.413c-.484.134-.86.192-1.267.192-.902 0-1.394-.467-1.394-1.298-.03-.23.19-1.284.24-1.578.05-.296.83-4.596.83-4.596h1.753l-.265 1.423h.9l-.245 1.45h-.903s-.495 2.522-.495 2.714c0 .307.183.44.602.44.2 0 .356-.02.476-.056l-.234 1.31M51.607 19.42c-1.284 0-2.287.536-2.87 1.43l.506-1.326c-.932-.347-1.533.148-2.076.85 0 0-.09.118-.182.225v-1.044H45.34c-.223 1.84-.617 3.707-.925 5.55l-.074.402h1.77c.168-.936.306-1.696.443-2.3.376-1.663 1.01-2.17 1.96-1.946-.22.477-.34 1.03-.34 1.642 0 1.486.795 2.7 2.772 2.7 1.994 0 3.44-1.078 3.44-3.54 0-1.485-.964-2.644-2.778-2.644zm-.515 4.74c-.625.01-1.004-.52-1.004-1.316 0-.942.553-2.007 1.442-2.007.717 0 .962.577.962 1.19 0 1.34-.542 2.134-1.4 2.134zM24.023 25.508H22.26l1.048-5.592-2.41 5.592H19.29l-.293-5.56-1.05 5.56h-1.6l1.362-7.273h2.753l.23 4.054 1.744-4.055h2.975l-1.39 7.273' fill='#006' />
    <path d='M35.974 24.917c-.604.162-1.19.242-1.808.24-1.973-.002-3-.912-3-2.654 0-2.034 1.308-3.53 3.083-3.53 1.452 0 2.38.838 2.38 2.153 0 .436-.064.86-.217 1.462h-3.51c-.124.85.49 1.224 1.532 1.224.625 0 1.19-.114 1.816-.37l-.276 1.475zm-.943-3.53c0-.128.196-1.04-.82-1.06-.56 0-.962.377-1.125 1.06h1.946zM37.164 20.988c0 .752.417 1.27 1.366 1.658.726.3.84.388.84.657 0 .373-.32.544-1.032.54-.535-.004-1.023-.07-1.598-.232l-.256 1.372c.513.12 1.23.16 1.866.175 1.895 0 2.77-.63 2.77-1.988 0-.817-.365-1.298-1.265-1.656-.752-.305-.84-.37-.84-.646 0-.324.298-.488.88-.488.35 0 .833.033 1.29.09l.258-1.38c-.466-.065-1.174-.117-1.583-.117-2.008 0-2.703.915-2.696 2.015M30.204 25.06H28.73l.037-.626c-.45.492-1.047.722-1.858.722-.962 0-1.62-.665-1.62-1.622 0-1.454 1.144-2.293 3.11-2.293.202 0 .46.016.723.045.055-.194.07-.277.07-.383 0-.4-.31-.545-1.137-.545-.816.004-1.366.126-1.877.265l.25-1.334c.884-.228 1.46-.316 2.116-.316 1.523 0 2.327.605 2.327 1.742.013.304-.092.91-.144 1.18-.06.386-.483 2.634-.526 3.165zm-1.293-2.655c-.185-.02-.266-.024-.394-.024-1.004 0-1.513.303-1.513.9 0 .377.25.612.635.612.722 0 1.243-.612 1.273-1.487zM44.22 24.965c-.482.134-.858.192-1.265.192-.902 0-1.394-.467-1.394-1.298-.027-.23.193-1.286.243-1.58.05-.295.83-4.594.83-4.594h1.753l-.265 1.422h.9l-.245 1.452h-.902s-.496 2.52-.496 2.712c0 .307.183.44.603.44.2 0 .355-.02.475-.056l-.234 1.31M52.935 21.578c0 1.342-.543 2.135-1.4 2.135-.626.01-1.004-.52-1.004-1.316 0-.943.554-2.008 1.442-2.008.717 0 .963.575.963 1.188zm1.89.038c0-1.485-.962-2.644-2.775-2.644-2.086 0-3.435 1.41-3.435 3.484 0 1.486.795 2.7 2.772 2.7 1.995 0 3.44-1.078 3.44-3.54zM45.782 19.108c-.223 1.84-.617 3.706-.925 5.55l-.074.402h1.77c.637-3.562.85-4.576 2.188-4.22l.644-1.684c-.933-.347-1.533.148-2.076.852.05-.317.14-.623.12-.9H45.78M24.466 25.06h-1.762l1.047-5.592-2.41 5.592h-1.607l-.294-5.56-1.052 5.56H16.79l1.36-7.273h2.755l.146 4.502 1.94-4.503h2.865l-1.39 7.273' fill='#FFF' />
  </svg>
);

PaymentIconMaestro.propTypes = {
  className: PropTypes.string,
};

export default PaymentIconMaestro;
