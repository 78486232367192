import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { ABOUT_PAGE_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Class that loads and stores data for the about page
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class AboutStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable aboutData = undefined;
  @observable loading = false;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.aboutStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData() {
    this.loading = true;

    return this.requestAboutData()
      .then(response => ({
        aboutStore: {
          aboutData: response.items[0],
        },
      }));
  }

  requestAboutData() {
    return contentful.getEntries('aboutPage', {
      'sys.id': ABOUT_PAGE_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the about page data
   */
  @action('Load about page data')
  loadAboutData() {
    if (!this.aboutData) {
      this.loading = true;
      this.requestAboutData()
        .then(response => this.onAboutDataLoaded(response.items[0]));
    } else {
      this.onAboutDataLoaded(this.aboutData);
    }
  }

  @action('Successfully loaded about page data')
  onAboutDataLoaded(aboutData) {
    this.aboutData = aboutData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.aboutData, 'pageMetadata.fields'), getField(this.aboutData, 'title'));
  }

  @action('Preloaded about page data found')
  onPreloadedDataFound(preloadedData) {
    this.onAboutDataLoaded(preloadedData.aboutStore.aboutData);
  }
}

export default AboutStore;
