import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import globalStyles from 'globalStyles';
import styles from './NewsletterSubscription.styl';

import { generateMailchimpUrl } from 'utils/mailchimpUtils';

import ActionButton from 'components/common/buttons/ActionButton';
import TextInput from 'components/common/forms/TextInput';

const NewsletterSubscription = observer(({
  mailchimpListId,
  form,
  inputPlaceholder,
  globalTranslations,
  success,
  errorMessage,
  pending,
  darkMode,
  lightButton,
  inlineMessages,
  hideLabel,
  buttonLabel,
}) => {

  const formClasses = classNames({
    [styles.form]: true,
    [styles.formInline]: darkMode,
  });

  const titleClasses = classNames({
    [globalStyles.hidden]: !darkMode,
    [styles.title]: darkMode,
  });

  const inputClasses = classNames({
    [styles.input]: true,
    [styles.footerInput]: darkMode,
  });

  const errorClasses = classNames({
    [styles.error]: true,
    [styles.errorPopup]: !inlineMessages,
    [styles.errorInline]: inlineMessages,
  });

  const successClasses = classNames({
    [styles.successOverlay]: !inlineMessages,
    [styles.successInline]: inlineMessages,
  });

  const labelClasses = classNames({
    [styles.label]: true,
    [styles.labelHidden]: hideLabel,
  });

  const emailFieldId = darkMode ? 'form__newsletter-subscription__email__footer' : 'form__newsletter-subscription__email';

  return (
    <div id={darkMode ? 'newsletter' : null}>
      <h2 className={titleClasses}>{globalTranslations.newsletter}</h2>
      <form
        onSubmit={form.onSubmit}
        action={generateMailchimpUrl(false, mailchimpListId)}
        method='post'
        className={formClasses}
      >
        { form.fields.has('GROUP') &&
          <input {...form.$('GROUP').bind()} />
        }

        <label className={labelClasses} htmlFor={emailFieldId}>{inputPlaceholder}</label>
        <div className={styles.formRow}>
          <TextInput
            {...form.$('EMAIL').bind()}
            id={emailFieldId}
            className={inputClasses}
            darkMode={darkMode}
            isSmall={true}
            errorMessage={(form.$('EMAIL').error || form.error || errorMessage)}
            placeholder={inputPlaceholder}
            size={10}
            field={form.$('EMAIL')}
          />
          <ActionButton
            label={buttonLabel}
            disabled={pending}
            type='submit'
            light={lightButton}
            onButtonClicked={(e) => form.onSubmit(e)}
          />
        </div>
        { form.$('EMAIL').error && <p className={errorClasses}>
          {form.$('EMAIL').error}
        </p> }
        { form.error && <p className={errorClasses}>
          {form.error}
        </p> }
        { errorMessage &&
          <p className={errorClasses} dangerouslySetInnerHTML={{__html: errorMessage}}></p>
        }
        { success &&
          <section className={successClasses}>
            <h2 className={styles.successTitle}>Thank you</h2>
            <p className={styles.successMessage}>Please check your email to confirm your subscription.</p>
          </section>
        }
      </form>
    </div>
  );
});

NewsletterSubscription.propTypes = {
  mailchimpListId: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string,
  form: PropTypes.object.isRequired,
  globalTranslations: PropTypes.object.isRequired,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  pending: PropTypes.bool,
  darkMode: PropTypes.bool,
  lightButton: PropTypes.bool,
  inlineMessages: PropTypes.bool,
  hideLabel: PropTypes.bool,
  buttonLabel: PropTypes.string,
};

export default withStyles(globalStyles, styles)(NewsletterSubscription);
