const DEFAULT_JSONP_OPTIONS = {
  callback: 'callback',
  callbackFunctionName: null,
};

const generateCallbackName = () => {
  return `jsonp_${Date.now()}_${Math.ceil(Math.random() * 100000)}`;
};

const removeScriptTag = (script, callback) => {
  if (script) {
    if (script.removeEventListener) {
      script.removeEventListener('error', callback);
    }
    if (script.parentNode) {
      script.parentNode.removeChild(script);
    }
  }
};

const clearCallback = functionName => {
  try {
    delete window[functionName];
  } catch (e) {
    // IE8 throws an error when deleting properties on window object
    window[functionName] = undefined;
  }
};

const generateScriptLoadErrorMessage = (url) => {
  return `JSONP request to ${url} failed}`;
};

export const fetchJsonp = (url, options) => {
  const chosenOptions = options ? Object.assign({}, DEFAULT_JSONP_OPTIONS, options) : {};
  const callback = chosenOptions.callback || DEFAULT_JSONP_OPTIONS.callback;
  let finalUrl = url;

  // Promise that will resolve once the script has loaded
  return new Promise((resolve, reject) => {
    // Prepare a unique callback function name
    const callbackFunctionName = chosenOptions.callbackFunction || generateCallbackName();
    // Script ID will be the callback name
    const scriptId = `${callback}_${callbackFunctionName}`;
    // Create script element that will load the JSON
    const script = document.createElement('script');
    // Error event will only fire in HTML5 browsers
    const scriptLoadErrorCallback = () => {
      removeScriptTag(script, scriptLoadErrorCallback);
      clearCallback(callbackFunctionName);
      // Reject the promise with an appropriate error message
      const errorMessage = generateScriptLoadErrorMessage(url);
      reject(errorMessage);
    };
    // Create the callback function on the window object
    window[callbackFunctionName] = response => {
      resolve({
        ok: true,
        json: () => {
          return Promise.resolve(response);
        },
      });
      removeScriptTag(script, scriptLoadErrorCallback);
      clearCallback(callbackFunctionName);
    };
    // Prepare the URL for the callback method name to be appended
    finalUrl = finalUrl.indexOf('?') < 0 ? `${finalUrl}?` : `${finalUrl}&`;
    // Set the source to be the url with it's callback method
    script.setAttribute('src', `${finalUrl}${callback}=${callbackFunctionName}`);
    // Set an ID on the script tag so it can be removed later
    script.setAttribute('id', scriptId);
    script.addEventListener('error', scriptLoadErrorCallback);
    // Start load by attatching the script element to the DOM
    document.getElementsByTagName('head')[0].appendChild(script);
  });
};
