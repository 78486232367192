import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { CONTACT_PAGE_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Class that loads and stores data for the contact page
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class ContactStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable contactData = undefined;
  @observable loading = false;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.contactStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData() {
    this.loading = true;

    return this.requestContactData()
      .then(response => ({
        contactStore: {
          contactData: response.items[0],
        },
      }));
  }

  requestContactData() {
    return contentful.getEntries('contactPage', {
      'sys.id': CONTACT_PAGE_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the contact page data
   */
  @action('Load contact page data')
  loadContactData() {
    this.loading = true;
    this.requestContactData()
      .then(response => this.onContactDataLoaded(response.items[0]));
  }

  @action('Successfully loaded contact page data')
  onContactDataLoaded(contactData) {
    this.contactData = contactData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.contactData, 'pageMetadata.fields'), getField(this.contactData, 'title'));
  }

  @action('Preloaded contact page data found')
  onPreloadedDataFound(preloadedData) {
    this.onContactDataLoaded(preloadedData.contactStore.contactData);
  }
}

export default ContactStore;
