import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './SecondaryContentWrapper.styl';

const SecondaryContentWrapper = ({ children }) => (
  <div className={styles.secondaryContentWrapper}>
    <div className={styles.content}>
      {children}
    </div>
  </div>
);

SecondaryContentWrapper.propTypes = {
  children: PropTypes.node,
};

export default withStyles(styles)(SecondaryContentWrapper);
