exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteFooter_SiteInformation_siteInformation_2B8sI{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-webkit-flex:1;-ms-flex:1;flex:1;width:100%;color:#888b8d;font-size:12px;line-height:16px;text-align:center;background:#323234}@media (min-width:544px){.siteFooter_SiteInformation_siteInformation_2B8sI{display:inline}}@media (min-width:850px){.siteFooter_SiteInformation_siteInformation_2B8sI{width:auto;text-align:left}}.siteFooter_SiteInformation_siteInformation_2B8sI p{margin:0;padding:0}.siteFooter_SiteInformation_copyrightNotice_1W9Za{display:inline-block}.siteFooter_SiteInformation_additionalLinks_1Fz5i{display:inline-block;margin:0 0 0 6px}@media (min-width:850px){.siteFooter_SiteInformation_additionalLinks_1Fz5i{margin-right:6px}}.siteFooter_SiteInformation_footerLinkItem_2Hrj2{list-style:none;display:inline-block;margin:0 6px}.siteFooter_SiteInformation_footerLink_3x4-C{color:#888b8d}.siteFooter_SiteInformation_footerLink_3x4-C:focus,.siteFooter_SiteInformation_footerLink_3x4-C:hover{outline:none;color:#e8e8e9}", ""]);

// exports
exports.locals = {
	"siteInformation": "siteFooter_SiteInformation_siteInformation_2B8sI",
	"copyrightNotice": "siteFooter_SiteInformation_copyrightNotice_1W9Za",
	"additionalLinks": "siteFooter_SiteInformation_additionalLinks_1Fz5i",
	"footerLinkItem": "siteFooter_SiteInformation_footerLinkItem_2Hrj2",
	"footerLink": "siteFooter_SiteInformation_footerLink_3x4-C"
};