import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { NOT_FOUND_PAGE_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Class that loads and stores data for the 404 page
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class NotFoundStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable pageData = undefined;
  @observable loading = false;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.notFoundStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData() {
    this.loading = true;

    return this.requestNotFoundData()
      .then(response => ({
        notFoundStore: {
          pageData: response.items[0],
        },
      }));
  }

  requestNotFoundData() {
    return contentful.getEntries('notFoundPage', {
      'sys.id': NOT_FOUND_PAGE_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the 404 page data
   */
  @action('Load 404 page data')
  loadNotFoundData() {
    if (!this.pageData) {
      this.loading = true;
      this.requestNotFoundData()
        .then(response => this.onNotFoundDataLoaded(response.items[0]));
    } else {
      this.onNotFoundDataLoaded(this.pageData);
    }
  }

  @action('Successfully loaded 404 page data')
  onNotFoundDataLoaded(pageData) {
    this.pageData = pageData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.pageData, 'pageMetadata.fields'), getField(this.pageData, 'title'));
  }

  @action('Preloaded 404 page data found')
  onPreloadedDataFound(preloadedData) {
    this.onNotFoundDataLoaded(preloadedData.notFoundStore.pageData);
  }
}

export default NotFoundStore;
