import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './LocaleSelection.styl';

import SelectField from 'components/common/forms/SelectField';

const LocaleSelection = ({
  form,
  label,
  runningOnServer,
  currentPath,
  currentLocale,
  submitButtonLabel,
}) => {
  const wrapperClasses = classNames({
    [styles.dropdownWrapper]: true,
    [styles[currentLocale]]: currentLocale,
  });

  return (
    <aside className={styles.localeSelection}>
      <form
        className={styles.localSelectionForm}
        action={currentPath}
        method='get'
        onSubmit={form.onSubmit}
      >
        <SelectField
          {...form.$('locale').bind()}
          id='form__locale-select'
          label={label}
          field={form.$('locale')}
          form={form}
          className={styles.languageDropdown}
          wrapperClass={wrapperClasses}
          onChange={(e) => {
            form.$('locale').onChange(e);
            form.submit(form);
          }}
        />
        { runningOnServer && <button
          type='submit'
        >{submitButtonLabel}</button> }
      </form>
    </aside>
  );
};

LocaleSelection.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  runningOnServer: PropTypes.bool,
  currentPath: PropTypes.string.isRequired,
  currentLocale: PropTypes.string,
  submitButtonLabel: PropTypes.string,
};

export default withStyles(styles)(LocaleSelection);
