import { observable, action } from 'mobx';

import shopify from 'services/shopify';

/**
 * Handles cart creation and updates
 * @class
 */
class CartStore {

  @observable cart;
  @observable subtotal;
  @observable subtotalFormatted;
  @observable tax;
  @observable taxFormatted;
  @observable total;
  @observable totalFormatted;
  @observable hasAgreedToTerms = false;
  @observable updatingCart = false;
  @observable updateCartError = false;
  @observable checkboxErrorMessage = undefined;
  @observable totalItems = 0;
  @observable products = [];
  @observable lastProductIdAdded = undefined;
  currency_symbol = undefined;
  currency_code = undefined;
  tax_rate = undefined;
  tax_included = undefined;
  siteUIStore = undefined;

  constructor(siteUIStore) {
    this.siteUIStore = siteUIStore;
    shopify.runningOnServer = siteUIStore.runningOnServer;
    if (siteUIStore.regionData) {
      shopify.setRegionData(siteUIStore.regionData);
      this.currency_symbol = shopify.currency_symbol;
      this.currency_code = shopify.currency_code;
      this.tax_rate = shopify.tax_rate;
      this.tax_included = shopify.tax_included;
      this.getCart();
    }
  }

  /**
   * Fetches an existing cart or create a new one
   */
  @action('Get an existing cart, or create a new one')
  getCart() {
    return shopify.getCart()
      .then(cart => {
        this.onCartUpdated(cart);
      });
  }

  /**
   * Add a variant with desired quantity to the cart
   * @param {object} variant Shopify object representing a product variant
   * @param {number} quantity Number of items to add
   */
  @action('Add a product variant to the cart')
  addItem = (variantId, quantity=1) => {
    this.updatingCart = true;
    this.lastProductIdAdded = variantId;

    return shopify.addToCart(variantId, quantity)
      .then(cart => this.onCartUpdated(cart))
      .catch(error => this.onUpdateCartError(error));

  }

  /**
   * Update an existing item in the cart
   * @param {string} variantId Shopify ID of the product to update
   * @param {number} quantity Update the item's quantity to this number
   */
  @action('Update a cart item')
  updateItem = (lineIndex, quantity=1) => {
    if (!quantity) {
      return this.removeItem(lineIndex);
    }

    return shopify.updateItemByIndex(lineIndex, quantity)
      .then(cart => this.onCartUpdated(cart));
  }

  /**
   * Remove an item from the cart
   * @param {string} variantId Shopify ID of the product to remove
   */
  @action('Remove an item from the cart')
  removeItem = (lineIndex) => {
    return shopify.updateItemByIndex(lineIndex, 0)
      .then(cart => this.onCartUpdated(cart));
  }

  @action('Validate cart before checkout')
  validateCart = (e) => {
    if (this.products.length === 0) {
      alert('error: cart is empty');
      e.preventDefault();
    }
    if (this.hasAgreedToTerms === false) {
      this.checkboxErrorMessage = 'The terms field is required.';
      e.preventDefault();
    }
  }

  @action('Set state of agreed to terms')
  agreeToTerms = (e) => {
    this.hasAgreedToTerms = e.target.checked;
  }

  @action('Cart has been updated')
  onCartUpdated = (cart) => {
    // JSONP isn't supported server-side, so don't keep trying to get the cart
    if (this.siteUIStore.runningOnServer) return undefined;
    // Older Safari versions without native fetch do not provide responses to the fetch calls for updateItem
    // or addToCart - so if we don't get a cart here, reload it instead, as we are on a bad browser
    if (!cart) {
      return this.getCart();
    }

    this.updatingCart = false;
    this.cart = cart;
    this.subtotal = cart ? cart.total_price : undefined;
    this.subtotalFormatted = cart ? this.formatPrice(this.subtotal) : undefined;
    this.subtotalFormattedCode = cart ? this.formatPriceWithCode(this.subtotal) : undefined;
    this.tax = cart ? ((this.tax_rate * this.subtotal) / (1 + this.tax_rate)).toFixed(2) : undefined;
    this.taxFormatted = this.tax ? this.formatPrice(this.tax) : undefined;
    this.taxFormattedCode = this.tax ? this.formatPriceWithCode(this.tax) : undefined;
    if (this.subtotal) {
      this.total = this.subtotal;
    }
    this.totalFormatted = this.total ? this.formatPrice(parseFloat(this.total)) : undefined;
    this.totalFormattedCode = this.total ? this.formatPriceWithCode(parseFloat(this.total)) : undefined;
    this.totalItems = cart ? cart.item_count : 0;
    const cartItems = (cart && cart.items) ? cart.items : [];
    this.products = cartItems.map(product => {
      product.currency_symbol = this.currency_symbol;
      product.currency_code = this.currency_code;
      product.per_price_formatted = this.formatPrice(product.price);
      product.per_price_formatted_with_code = this.formatPriceWithCode(product.price);
      product.per_price_formatted_with_symbol = this.formatPriceWithSymbol(product.price);
      product.line_price_formatted = this.formatPrice(product.line_price);
      product.line_price_formatted_with_code = this.formatPriceWithCode(product.line_price);
      if (product.line_price != product.original_line_price) {
        product.original_line_price_formatted = this.formatPrice(product.original_line_price);
        product.original_line_price_formatted_with_code = this.formatPriceWithCode(product.original_line_price);
      }

      return product;
    });

    return cart;
  }

  formatPrice(price) {
    return this.currency_symbol + (price / 100).toFixed(2);
  }

  formatPriceWithSymbol(price) {
    return this.currency_symbol + (price / 100).toFixed(2);
  }

  formatPriceWithCode(price) {
    return this.currency_code + ' ' + (price / 100).toFixed(2);
  }

  onUpdateCartError() {
    this.updateCartError = true;
  }
}

export default CartStore;
