import 'bindpolyfill';
import React from 'react';
import { render } from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { CookiesProvider } from 'react-cookie';
import { StaticRouter } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';

import environmentVars from './constants/environmentVars';
import * as DEFAULT_ENV_VARS from './constants/roli';
import { getRegionData } from 'utils/shopifyUtils';

import AppContextProvider from 'containers/AppContextProvider';
import StoreProvider from 'containers/StoreProvider';
import SiteHeader from 'containers/SiteHeader';
import SiteFooter from 'containers/SiteFooter';

const serverSide = window.static;

const css = [];
const context = {
  insertCss: (...styles) => styles.forEach(stylesheet => css.push(serverSide ? stylesheet._getCss() : stylesheet._insertCss())),
};

// Load data required by the route being served
const loadInitialData = () => {
  // Load shared data, eg header content, footer content, etc.
  // App preload comes first because it sets the region data in ShopifyService
  let promises = [];
  promises.push(StoreProvider.__preloadServerSideData('en-US'));

  return Promise.all(promises);
};

if (!serverSide) {
  const data = {};
  const cookies = new Cookies();
  const foundCookies = (cookies) ? cookies.getAll() : undefined;
  if (foundCookies && foundCookies.region) {
    data.regionName = foundCookies.region;
  } else {
    // Fall back to global store if no cookie is found
    data.regionName = 'global';
  }
  data.userData = window.__preloadedData__ ? window.__preloadedData__.userData : undefined;
  data.regionData = getRegionData(foundCookies.region);
  // Render out the site header
  if (document.getElementById('global-header')) {
    render(
      <AppContextProvider context={context}>
        <BrowserRouter location={window.location.pathname}>
          <CookiesProvider>
            <StoreProvider region={undefined} preloadedData={data}>
              <SiteHeader outsideApp={true} />
            </StoreProvider>
          </CookiesProvider>
        </BrowserRouter>
      </AppContextProvider>,
      document.getElementById('global-header')
    );
  }
  // Render out the site footer
  if (document.getElementById('global-footer')) {
    render(
      <AppContextProvider context={context}>
        <BrowserRouter location={window.location.pathname}>
          <CookiesProvider>
            {/* /////////////
                // TODO: Set region via cookie here
                /////////////
            */}
            <StoreProvider region={undefined}>
              <SiteFooter outsideApp={true} />
            </StoreProvider>
          </CookiesProvider>
        </BrowserRouter>
      </AppContextProvider>,
      document.getElementById('global-footer')
    );
  }
} else {
  module.exports = function render() {
    return loadInitialData()
      .then(data => {
        data = data.length ? Object.assign(...data) : {};
        // Add the region to loaded data
        data.regionName = 'staging';
        data.regionData = getRegionData(data.regionName);
        // Respond with string rendered by React's renderToString method

        // Generate HTML for the site header
        const headerHTML = renderToStaticMarkup(
          <AppContextProvider context={context}>
            <StaticRouter context={context}>
              <CookiesProvider>
                <StoreProvider server={true} preloadedData={data}>
                  <SiteHeader outsideApp={true} />
                </StoreProvider>
              </CookiesProvider>
            </StaticRouter>
          </AppContextProvider>
        );
        // Generate HTML for the site footer
        const footerHTML = renderToStaticMarkup(
          <AppContextProvider context={context}>
            <StaticRouter context={context}>
              <CookiesProvider>
                <StoreProvider server={true}  preloadedData={data}>
                  <SiteFooter outsideApp={true} />
                </StoreProvider>
              </CookiesProvider>
            </StaticRouter>
          </AppContextProvider>
        );

        let envVars = {};
        environmentVars.forEach(name => {
          envVars[name] = process.env[name] || DEFAULT_ENV_VARS[name];
        });
        envVars = JSON.stringify(envVars);

        return `<html>
          <head>
            <meta charset='utf-8' />
            <style>${css.join('')}</style>
          </head>
          <div id='global-header'>${headerHTML}</div>
          <div>Site content would go here</div>
          <div id='global-footer'>${footerHTML}</div>
          <script>window.__ENV__ = ${envVars}</script>
          <script src='./shared-header-footer.min.js' async></script>
        </html>`;
      });
  };
}
