exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".buttons_AccountButton_accountButton_1KHr4{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;will-change:opacity;-webkit-transition:opacity .15s linear;transition:opacity .15s linear}.buttons_AccountButton_accountButton_1KHr4:focus,.buttons_AccountButton_accountButton_1KHr4:hover{opacity:.5}.buttons_AccountButton_accountIcon_1DQKC{width:32px;height:32px}", ""]);

// exports
exports.locals = {
	"accountButton": "buttons_AccountButton_accountButton_1KHr4",
	"accountIcon": "buttons_AccountButton_accountIcon_1DQKC"
};