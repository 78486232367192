exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".buttons_CloseButton_closeButton_uLAkk{cursor:pointer;position:absolute;z-index:2;width:32px;height:32px;top:40px;right:40px;background-color:transparent;outline:none;border:0}@media site-small-min{.buttons_CloseButton_closeButton_uLAkk{top:56px;right:56px}}.buttons_CloseButton_closeIcon_36KJJ{position:absolute;top:50%;left:50%;-webkit-transform:translateX(-50%) translateY(-50%);-ms-transform:translateX(-50%) translateY(-50%);transform:translateX(-50%) translateY(-50%);width:16px;height:16px}.buttons_CloseButton_smallCloseIcon_EgY1y{width:8px;height:8px}", ""]);

// exports
exports.locals = {
	"closeButton": "buttons_CloseButton_closeButton_uLAkk",
	"closeIcon": "buttons_CloseButton_closeIcon_36KJJ",
	"smallCloseIcon": "buttons_CloseButton_smallCloseIcon_EgY1y"
};