exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteFooter_FooterWrapper_footerWrapper_1Rdb3{display:block;background:#272729;color:#fafafa}.siteFooter_FooterWrapper_footerWrapper_1Rdb3 p{margin:0;padding:0}.siteFooter_FooterWrapper_footerWrapperGrid_uuWNw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;max-width:1176px;-webkit-box-sizing:border-box;box-sizing:border-box;margin-left:auto;margin-right:auto;padding:32px 8px 24px;-webkit-box-align:start;-webkit-align-items:flex-start;-ms-flex-align:start;align-items:flex-start}@media (min-width:544px){.siteFooter_FooterWrapper_footerWrapperGrid_uuWNw{padding-left:12px;padding-right:12px;padding-top:48px;padding-bottom:24px}}@media (min-width:850px){.siteFooter_FooterWrapper_footerWrapperGrid_uuWNw{padding-top:44px}}", ""]);

// exports
exports.locals = {
	"footerWrapper": "siteFooter_FooterWrapper_footerWrapper_1Rdb3",
	"footerWrapperGrid": "siteFooter_FooterWrapper_footerWrapperGrid_uuWNw"
};