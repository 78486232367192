import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';

import SiteMetaStore from 'stores/domain/SiteMetaStore';

import { COMPETITION_PAGE_ID } from 'constants/contentful';

/**
 * Loads and manages the careers page data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class CompetitionStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable competitionData = undefined;
  @observable success = false;
  @observable errorMessage = undefined;
  @observable pending = false;
  siteMetaStore = undefined;

  get pending() {
    return this.pendingStatus;
  }

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.competitionStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);

    return this.requestCompetitionPage()
      .then(response => ({
        competitionStore: {
          competitionData: response.items[0],
        },
      }))
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  @action('Load the competition page')
  loadCompetitionPage() {
    if (!this.competitionData) {
      return this.requestCompetitionPage()
        .then(response => this.onCompetitionPageLoadComplete(response.items[0]))
        .catch(error => {
          console.log(error); // eslint-disable-line no-console
        });
    } else {
      this.onCompetitionPageLoadComplete(this.competitionData);
    }
  }

  requestCompetitionPage() {
    return contentful.getEntries('competitionPage', {
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
      'sys.id': COMPETITION_PAGE_ID,
    });
  }

  @action('Updating pending status')
  setPendingStatus(value) {
    this.pending = value;
  }

  @action('Updating success status')
  setSuccessStatus(value) {
    this.success = value;
  }

  @action('Updating error message')
  setErrorMessage(message) {
    this.errorMessage = message;
  }

  @action('Successfully loaded competition page')
  onCompetitionPageLoadComplete(competitionData) {
    this.competitionData = competitionData;
    this.siteMetaStore.setPageMetadata(getField(this.competitionData, 'pageMetadata.fields'), getField(this.competitionData, 'title'));
  }

  @action('Preloaded competition data found')
  onPreloadedDataFound(preloadedData) {
    this.onCompetitionPageLoadComplete(preloadedData.competitionStore.competitionData);
  }
}

export default CompetitionStore;
