import parse from 'url-parse';
import { CONTENTFUL_IMAGE_PROXY } from 'constants/contentful';

export const buildContentfulImageUrl = (url, options) => {
  url = parse(url);
  if (CONTENTFUL_IMAGE_PROXY) {
    // Protection against env vars missing a protocol
    let proxyUrl = CONTENTFUL_IMAGE_PROXY;
    if (proxyUrl.indexOf('http') < 0) {
      proxyUrl = `https://${proxyUrl.replace('//', '')}`;
    }
    const parsedProxy = parse(proxyUrl);
    url.set('hostname', parsedProxy.hostname);
    url.set('protocol', parsedProxy.protocol);
  }
  let params = [];
  for (var key in options) {
    params.push(`${key}=${options[key]}`);
  }
  let finalUrl = url.href;
  if (params.length) {
    finalUrl = finalUrl.indexOf('?') < 0 ? `${finalUrl}?` : `${finalUrl}&`;
    finalUrl += params.join('&');
  }
  if (finalUrl.indexOf('http') === 0) {
    return finalUrl;
  } else if (finalUrl.indexOf('//') === 0) {
    return `https:${finalUrl}`;
  } else {
    return `https://${finalUrl}`;
  }
};
