import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './SlimBanner.styl';

import CloseButton from 'components/common/buttons/CloseButton';
import TextLink from 'components/common/links/TextLink';

const SlimBanner = ({
  copy,
  linkLabel,
  linkUrl,
  outsideApp,
  onCloseButtonClicked,
}) => (
  <div className={styles.banner}>
    <div className={styles.bannerText}>
      {copy}
      {linkLabel && linkUrl &&
        <TextLink
          label={linkLabel}
          to={linkUrl}
          className={styles.bannerLink}
          outsideApp={outsideApp}
        >
          {linkLabel}
        </TextLink>
      }
    </div>
    <CloseButton
      onButtonClicked={onCloseButtonClicked}
      className={styles.bannerCloseButton}
    />
  </div>
);

SlimBanner.propTypes = {
  copy: PropTypes.string,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  outsideApp: PropTypes.bool,
  onCloseButtonClicked: PropTypes.func,
};

export default withStyles(styles)(SlimBanner);
