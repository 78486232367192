import { observable, action } from 'mobx';

import contentful from 'services/contentful';

/**
 * Class that loads soundpack data for the SoundpackCarousel component
 * @class
 */
class SoundpackCarouselStore {

  MAX_SOUNDPACKS = 25;
  @observable soundpacks = [];
  arrangedSoundpacks = [];

  constructor(preloadedData) {
    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.soundpackCarouselStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);

    return this.requestLatestSoundpacks()
      .then(soundpacks => ({
        soundpackCarouselStore: {
          soundpacks: soundpacks.items,
        },
      }))
      .catch(error => {
        console.log('SoundpackCarouselStore preload error', error); //eslint-disable-line no-console
      });
  }

  requestLatestSoundpacks() {
    return contentful.getEntries('soundpack', {
      limit: this.MAX_SOUNDPACKS,
      include: 1,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Show priotitised soundpacks before the remaining ones
   * @param {array} prioritisedSoundpacks Array of soundpacks to prioritise
   * @returns {array}
   */
  @action('Arrange soundpacks to show prioritised ones first')
  arrangeSoundpacks(prioritisedSoundpacks) {
    this.arrangedSoundpacks = prioritisedSoundpacks.slice(0);
    const prioritisedIds = (prioritisedSoundpacks || []).map((soundpack) => (
      soundpack.sys.id
    ));
    this.soundpacks.forEach(soundpack => {
      if (prioritisedIds.indexOf(soundpack.sys.id) < 0) {
        this.arrangedSoundpacks.push(soundpack);
      }
    });

    return this.arrangedSoundpacks;
  }

  /**
   * Load the latest 25 soundpacks
   */
  @action('Load the latest 25 soundpacks')
  loadLatestSoundpacks() {
    this.requestLatestSoundpacks()
      .then(soundpacks => this.onSoundpacksLoaded(soundpacks.items));
  }

  @action('Successfully loaded the latest 25 soundpacks')
  onSoundpacksLoaded(soundpacks) {
    this.soundpacks = soundpacks;
  }

  @action('Preloaded 25 latest soundpacks found')
  onPreloadedDataFound(preloadedData) {
    this.onSoundpacksLoaded(preloadedData.soundpackCarouselStore.soundpacks);
  }
}

export default SoundpackCarouselStore;
