import { MAILCHIMP_USER_ID, MAILCHIMP_BASE_URL } from 'constants/mailchimp';

export const generateMailchimpUrl = (jsonp, listId) => {
  return jsonp
    ? `${MAILCHIMP_BASE_URL}-json?u=${MAILCHIMP_USER_ID}&amp;id=${listId}`
    : `${MAILCHIMP_BASE_URL}?u=${MAILCHIMP_USER_ID}&amp;id=${listId}`;
};

export const interpretSubscribeResponse = (responseJson) => {
  const isSuccess = responseJson.result === 'success' || responseJson.msg.indexOf('already subscribed') !== -1;
  const error = isSuccess
    ? null
    : responseJson.msg.replace('0 - ', '');

  return { isSuccess, error };
};
