import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Class that loads and stores data for the product registration complete page - a page users are directed to after successfully registering a new product
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class RegistrationCompleteStore {

  INCLUDE_REFERENCE_DEPTH = 2;

  @observable pageData = undefined;
  @observable loading = false;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.registrationCompleteStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match) {
    this.loading = true;

    return this.requestPageData(match.params.slug)
      .then(response => ({
        registrationCompleteStore: {
          pageData: response.items[0],
        },
      }));
  }

  requestPageData(slug) {
    return contentful.getEntries('registrationCompletePage', {
      'fields.slug': slug,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the start page data
   */
  @action('Load registration complete page data')
  loadPageData(slug) {
    if (!this.pageData) {
      this.loading = true;
      this.requestPageData(slug)
        .then(response => this.onPageDataLoaded(response.items[0]));
    } else {
      this.onPageDataLoaded(this.pageData);
    }
  }

  @action('Successfully loaded registration complete page data')
  onPageDataLoaded(pageData) {
    this.pageData = pageData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.pageData, 'pageMetadata.fields'), getField(this.pageData, 'title'));
  }

  @action('Preloaded registration complete page data found')
  onPreloadedDataFound(preloadedData) {
    this.onPageDataLoaded(preloadedData.registrationCompleteStore.pageData);
  }
}

export default RegistrationCompleteStore;
