import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import MainNav from 'components/siteHeader/MainNav';

@inject('siteUIStore', 'siteHeaderStore', 'translationStore') @observer
class SiteHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slideOutMenuOpen: false,
      slideOutMenuUsed: false,
    };
  }

  onMenuButtonClicked = () => {
    this.setState({ slideOutMenuUsed: true});
    if (this.state.slideOutMenuOpen) {
      this.setState({ slideOutMenuOpen: false});
    } else {
      this.setState({ slideOutMenuOpen: true });
    }
  }

  onLinkClicked = () => {
    if (this.state.slideOutMenuOpen) {
      this.setState({ slideOutMenuOpen: false });
    }
  }

  render() {
    const {
      siteUIStore,
      siteHeaderStore,
      translationStore,
      outsideApp,
      children,
      minimal,
      matchingRoute,
    } = this.props;

    return (
      <MainNav
        siteUIStore={siteUIStore}
        outsideApp={outsideApp}
        matchingRoute={matchingRoute}
        mainNavLinks={siteHeaderStore.mainNavOptions}
        globalTranslations={translationStore.globalTranslations}
        slideOutMenuOpen={this.state.slideOutMenuOpen}
        slideOutMenuUsed={this.state.slideOutMenuUsed}
        minimal={minimal}
        onMenuButtonClicked={this.onMenuButtonClicked}
        onLinkClicked={this.onLinkClicked}
        bannerData={siteHeaderStore.globalBannerData}
      >
        {children}
      </MainNav>
    );
  }
}

SiteHeader.propTypes = {
  siteUIStore: PropTypes.object,
  siteHeaderStore: PropTypes.object,
  translationStore: PropTypes.object,
  outsideApp: PropTypes.bool,
  minimal: PropTypes.bool,
  children: PropTypes.node,
  matchingRoute: PropTypes.object,
};

export default SiteHeader;
