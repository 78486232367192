import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';

import SiteMetaStore from 'stores/domain/SiteMetaStore';

import { CAREERS_PAGE_ID } from 'constants/contentful';

/**
 * Loads and manages the careers page data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class CareersStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable careersData = undefined;
  @observable loading = true;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.careersStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);
    this.loading = true;

    return this.requestCareersPage()
      .then(response => ({
        careersStore: {
          careersData: response.items[0],
        },
      }))
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  @action('Load the careers page')
  loadCareersPage() {
    if (!this.careersData) {
      this.loading = true;

      return this.requestCareersPage()
        .then(response => this.onCareersPageLoadComplete(response.items[0]))
        .catch(error => {
          console.log(error); // eslint-disable-line no-console
        });
    } else {
      this.onCareersPageLoadComplete(this.careersData);
    }
  }

  requestCareersPage() {
    return contentful.getEntries('careersPage', {
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
      'sys.id': CAREERS_PAGE_ID,
    });
  }

  @action('Successfully loaded careers page')
  onCareersPageLoadComplete(careersPageData) {
    this.careersData = careersPageData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.careersData, 'pageMetadata.fields'), getField(this.careersData, 'title'));
  }

  @action('Preloaded careers page data found')
  onPreloadedDataFound(preloadedData) {
    this.onCareersPageLoadComplete(preloadedData.careersStore.careersData);
  }
}

export default CareersStore;
