import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './CurrencyOptions.styl';

import PaymentIconVisa from 'components/cart/icons/PaymentIconVisa';
import PaymentIconMastercard from 'components/cart/icons/PaymentIconMastercard';
import PaymentIconMaestro from 'components/cart/icons/PaymentIconMaestro';
import PaymentIconAmex from 'components/cart/icons/PaymentIconAmex';
import PaymentIconPaypal from 'components/cart/icons/PaymentIconPaypal';

const CurrencyOptions = ({ title, regionConfigs, activeRegionConfig, onCurrencyClicked }) => {
  const options = regionConfigs.map((regionData, i) => {
    const linkClasses = classNames({
      [styles.currencyLink]: true,
      [styles.activeCurrency]: activeRegionConfig ? regionData.REGION === activeRegionConfig.REGION : false,
    });

    return (
      <li className={styles.currencyOption} key={i}>
        <a
          className={linkClasses}
          href={`?region=${regionData.REGION}`}
          onClick={() => {
            if (onCurrencyClicked) onCurrencyClicked(regionData.REGION);
          }}
        >
          {regionData.CURRENCY_CODE}
        </a>
      </li>
    );
  });

  return (
    <aside className={styles.currencyOptions}>
      <h2 className={styles.title}>{title}:</h2>
      <ul className={styles.currencies}>
        {options}
      </ul>
      <ul className={styles.cards}>
        <li className={styles.card}>
          <PaymentIconVisa className={styles.cardIcon} alt={'Visa'} />
        </li>
        <li className={styles.card}>
          <PaymentIconMastercard className={styles.cardIcon} alt={'Mastercard'} />
        </li>
        <li className={styles.card}>
          <PaymentIconMaestro className={styles.cardIcon} alt={'Maestro'} />
        </li>
        <li className={styles.card}>
          <PaymentIconAmex className={styles.cardIcon} alt={'Amex'} />
        </li>
        <li className={styles.card}>
          <PaymentIconPaypal className={styles.cardIcon} alt={'Paypal'} />
        </li>
      </ul>
    </aside>
  );
};

CurrencyOptions.propTypes = {
  title: PropTypes.string,
  regionConfigs: PropTypes.array,
  activeRegionConfig: PropTypes.object,
  onCurrencyClicked: PropTypes.func,
};

export default withStyles(styles)(CurrencyOptions);
