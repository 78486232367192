import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';

import globalStyles from 'globalStyles';
import styles from './SiteInformation.styl';

import { getField } from 'utils/contentfulUtils';
import { isInternal, makeRelative } from 'utils/linkUtils';

const SiteInformation = ({ outsideApp, copyrightNotice, links }) => {
  const linkItems = (links || []).map((link, i) => {
    const internal = isInternal(getField(link, 'url'));
    const url = (!outsideApp && internal) ? makeRelative(getField(link, 'url')) : getField(link, 'url');
    const target = internal ? undefined : '_blank';

    return (
      <li className={styles.footerLinkItem} key={i}>
        <Link to={url} target={target} className={styles.footerLink}>
          {getField(link, 'title')}
        </Link>
      </li>
    );
  });

  return (
    <section className={styles.siteInformation}>
      <h2 className={globalStyles.hidden}>Further Information</h2>
      <p className={styles.copyrightNotice}>{copyrightNotice}</p>
      <ul className={styles.additionalLinks}>
        {linkItems}
      </ul>
    </section>
  );
};

SiteInformation.propTypes = {
  outsideApp: PropTypes.bool,
  copyrightNotice: PropTypes.string,
  links: PropTypes.array,
};

export default withStyles(globalStyles, styles)(SiteInformation);
