exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteFooter_SecondaryContentWrapper_secondaryContentWrapper_24RJK{color:#888b8d;font-size:12px;line-height:16px;background:#323234}.siteFooter_SecondaryContentWrapper_content_18Ssx{margin-left:auto;margin-right:auto;padding:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;max-width:1128px;margin:0 auto;-webkit-box-sizing:content-box;box-sizing:content-box}@media (min-width:544px){.siteFooter_SecondaryContentWrapper_content_18Ssx{padding-left:24px;padding-right:24px}}@media (min-width:850px){.siteFooter_SecondaryContentWrapper_content_18Ssx{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row}}", ""]);

// exports
exports.locals = {
	"secondaryContentWrapper": "siteFooter_SecondaryContentWrapper_secondaryContentWrapper_24RJK",
	"content": "siteFooter_SecondaryContentWrapper_content_18Ssx"
};