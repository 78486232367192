import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobXPropTypes} from 'mobx-react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Isvg from 'react-inlinesvg';

import styles from './SocialLinks.styl';

import { buildContentfulImageUrl } from 'utils/imageUtils';
import { getField } from 'utils/contentfulUtils';

const SocialLinks = ({ title, links }) => {
  const linkItems = (links || []).map((link, i) => (
    <li className={styles.linkItem} key={i}>
      <a className={styles.link} href={getField(link, 'link')} target='_blank' title={getField(link, 'title')}>
        <Isvg src={buildContentfulImageUrl(getField(link, 'icon.fields.file.url'))} className={styles.icon} />
      </a>
    </li>
  ));

  return (
    <section className={styles.socialLinks}>
      <h2 className={styles.title}>{title}</h2>
      <ul className={styles.links}>
        {linkItems}
      </ul>
    </section>
  );
};

SocialLinks.propTypes = {
  title: PropTypes.string,
  links: MobXPropTypes.arrayOrObservableArray,
};

export default withStyles(styles)(SocialLinks);
