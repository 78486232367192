import React from 'react';
import PropTypes from 'prop-types';

const AccountIcon = ({ className }) => (
  <svg className={className} viewBox='0 0 32 32' preserveAspectRatio='xMidYMid meet'>
    <path fill='#F2F2F2' fillRule='evenodd' d='M24 27H8c0-4.5 3.582-9 8-9s8 4 8 9zm-8-10a5 5 0 1 1 0-10 5 5 0 0 1 0 10z' />
  </svg>
);

AccountIcon.propTypes = {
  className: PropTypes.string,
};

export default AccountIcon;
