import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './CloseButton.styl';

import CloseIcon from 'components/common/icons/CloseIcon';

const CloseButton = ({ className, small, onButtonClicked }) => {
  const classes = classNames({
    [styles.closeButton]: true,
    [className]: className,
  });

  const iconClasses = classNames({
    [styles.closeIcon]: true,
    [styles.smallCloseIcon]: small,
  });

  return (
    <button className={classes} onClick={e => {
      e.stopPropagation();
      onButtonClicked();
    }}>
      <CloseIcon className={iconClasses} />
    </button>
  );
};

CloseButton.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
  onButtonClicked: PropTypes.func.isRequired,
};

export default withStyles(styles)(CloseButton);
