import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './CartPreviewButton.styl';

import { ROLI_COM_URL } from 'constants/roli';

import CartIcon from 'components/common/icons/CartIcon';

const CartPreviewButton = ({ outsideApp, totalItems, onButtonClicked }) => {
  const totalClasses = classNames({
    [styles.total]: true,
    [styles.totalSmall]: totalItems > 9,
  });

  return (
    <a
      href={outsideApp ? `${ROLI_COM_URL}/cart` : '/cart'}
      className={styles.cartPreviewButton}
      onClick={(e) => {
        e.preventDefault();
        onButtonClicked(e);
      }}
    >
      <CartIcon className={styles.cartIcon} />
      {totalItems > 0 && <span className={totalClasses}>{totalItems}</span>}
    </a>
  );
};

CartPreviewButton.propTypes = {
  outsideApp: PropTypes.bool,
  totalItems: PropTypes.number.isRequired,
  onButtonClicked: PropTypes.func,
};

export default withStyles(styles)(CartPreviewButton);
