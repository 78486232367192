export default [
  'ROLI_PRODUCTION_PORT',
  'DEFAULT_LOCALE',
  'ROLI_COM_URL',
  'SUPPORT_SITE_URL',
  'CONTENTFUL_API_MODE',
  'CONTENTFUL_IMAGE_PROXY',
  'CONTENTFUL_JSON_PROXY',
  'STAGING_ACCESS_TOKEN',
  'UK_ACCESS_TOKEN',
  'EU_ACCESS_TOKEN',
  'GLOBAL_ACCESS_TOKEN',
  'NODE_ENV',
  'STATIC_ASSET_URL',
  'AUTH_URL',
];
