exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteFooter_AdditionalFooterCopy_additionalFooterCopy_3suCz{-webkit-box-sizing:border-box;box-sizing:border-box;min-width:0;padding-left:8px;padding-right:8px;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;max-width:100%;-webkit-box-ordinal-group:6;-webkit-order:5;-ms-flex-order:5;order:5;color:#888b8d;text-align:center}@media (min-width:544px){.siteFooter_AdditionalFooterCopy_additionalFooterCopy_3suCz{padding-left:12px;padding-right:12px;color:#fafafa;font-size:16px;text-align:left;margin-top:2px}}.siteFooter_AdditionalFooterCopy_additionalFooterCopy_3suCz a{color:#888b8d;font-weight:700;outline:none;text-decoration:none;-webkit-transition:opacity .15s linear;transition:opacity .15s linear}@media (min-width:544px){.siteFooter_AdditionalFooterCopy_additionalFooterCopy_3suCz a{border-bottom:1px solid #272729;color:#fafafa}}.siteFooter_AdditionalFooterCopy_additionalFooterCopy_3suCz a:focus,.siteFooter_AdditionalFooterCopy_additionalFooterCopy_3suCz a:hover{opacity:.5}", ""]);

// exports
exports.locals = {
	"additionalFooterCopy": "siteFooter_AdditionalFooterCopy_additionalFooterCopy_3suCz"
};