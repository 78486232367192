import React from 'react';
import PropTypes from 'prop-types';

const PaymentIconVisa = ({ className, ...props }) => (
  <svg className={className} version='1.1' width='72' height='43' viewBox='0 0 72 43' {...props}>
    <title>Visa Card Icon</title>
    <path fill='#26337A' d='M0 0h72v43H0z'/>
    <path fill='#FFFFFE' d='M29.873 28.342l2.214-13.67h3.538l-2.213 13.67h-3.54M46.248 15.008c-.7-.275-1.8-.574-3.17-.574-3.5 0-5.963 1.855-5.983 4.51-.022 1.966 1.757 3.062 3.1 3.715 1.378.67 1.84 1.097 1.836 1.696-.01.915-1.1 1.335-2.117 1.335-1.417 0-2.17-.206-3.334-.716l-.456-.218-.495 3.06c.825.38 2.354.71 3.942.73 3.718 0 6.134-1.834 6.162-4.67.013-1.56-.93-2.74-2.972-3.715-1.237-.633-1.996-1.053-1.987-1.694 0-.568.64-1.177 2.027-1.177 1.158-.02 1.996.247 2.65.525l.316.156.48-2.962M50.96 23.495c.293-.785 1.41-3.82 1.41-3.82-.02.036.29-.792.47-1.305l.24 1.18s.678 3.262.817 3.945H50.96zm4.365-8.81H52.59c-.848 0-1.483.243-1.854 1.134L45.48 28.346h3.717s.607-1.686.744-2.055l4.534.007c.106.476.43 2.047.43 2.047h3.285l-2.865-13.66zM26.904 14.684l-3.464 9.32-.37-1.895c-.646-2.184-2.655-4.55-4.903-5.735l3.17 11.955 3.744-.002 5.574-13.644h-3.75'/>
    <path d='M20.225 14.674H14.52l-.048.284c4.44 1.132 7.378 3.865 8.597 7.15l-1.24-6.28c-.215-.867-.836-1.123-1.605-1.154' fill='#EC982D'/>
  </svg>
);

PaymentIconVisa.propTypes = {
  className: PropTypes.string,
};

export default PaymentIconVisa;
