import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './FooterLinks.styl';

import { getField } from 'utils/contentfulUtils';
import { isInternal, makeRelative, needsProxy } from 'utils/linkUtils';

const generateLinkList = (outsideApp, links) => (
  (links || []).map((link, i) => {
    const url = getField(link, 'url');
    const proxied = needsProxy(url);
    const internal = isInternal(url) && !outsideApp && !proxied;
    let target = isInternal(url) ? '_self' : '_blank';

    const attrs = {
      target: (!internal) ? target : undefined,
      className: styles.footerLink,
      title: getField(link, 'title'),
    };

    if (internal) {
      attrs.to = makeRelative(url);
    } else {
      attrs.href =url;
    }

    const Component = (internal) ? Link : 'a';

    return (
      <li className={styles.footerLinkItem} key={i}>
        <Component {...attrs}>
          {getField(link, 'title')}
        </Component>
      </li>
    );
  })
);

const FooterLinks = ({
  outsideApp,
  firstLinkGroupTitle,
  firstLinkGroup,
  secondLinkGroupTitle,
  secondLinkGroup,
  thirdLinkGroupTitle,
  thirdLinkGroup,
  fourthLinkGroupTitle,
  fourthLinkGroup,
}) => {
  const firstLinkList = generateLinkList(outsideApp, firstLinkGroup);
  const secondLinkList = generateLinkList(outsideApp, secondLinkGroup);
  const thirdLinkList = generateLinkList(outsideApp, thirdLinkGroup);
  const fourthLinkList = generateLinkList(outsideApp, fourthLinkGroup);

  return (
    <div className={styles.footerLinks}>
      <div className={styles.leftColumn}>
        <h2 className={styles.linkListTitle}>{firstLinkGroupTitle}</h2>
        <ul className={styles.linkList}>
          {firstLinkList}
        </ul>
        <h2 className={styles.linkListTitle}>{secondLinkGroupTitle}</h2>
        <ul className={styles.linkList}>
          {secondLinkList}
        </ul>
      </div>
      <div className={styles.rightColumn}>
        <h2 className={styles.linkListTitle}>{thirdLinkGroupTitle}</h2>
        <ul className={styles.linkList}>
          {thirdLinkList}
        </ul>
        <h2 className={styles.linkListTitle}>{fourthLinkGroupTitle}</h2>
        <ul className={styles.linkList}>
          {fourthLinkList}
        </ul>
      </div>
    </div>
  );
};

FooterLinks.propTypes = {
  outsideApp: PropTypes.bool,
  firstLinkGroupTitle: PropTypes.string,
  firstLinkGroup: PropTypes.array,
  secondLinkGroupTitle: PropTypes.string,
  secondLinkGroup: PropTypes.array,
  thirdLinkGroupTitle: PropTypes.string,
  thirdLinkGroup: PropTypes.array,
  fourthLinkGroupTitle: PropTypes.string,
  fourthLinkGroup: PropTypes.array,
};

export default withStyles(styles)(FooterLinks);
