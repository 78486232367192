import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

@inject('siteUIStore') @observer
class ClickOutsideHandler extends Component {

  constructor(props) {
    super(props);

    this.wrapper = undefined;
  }

  componentWillMount() {
    if (!this.props.siteUIStore.runningOnServer) {
      document.addEventListener('click', this.onDocumentClicked);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClicked);
  }

  onDocumentClicked = (e) => {
    if (this.wrapper) {
      const wrapperBounds = this.wrapper.getBoundingClientRect();
      if (e.pageX < wrapperBounds.left || e.pageX > wrapperBounds.left + wrapperBounds.width ||
          e.pageY < wrapperBounds.top || e.pageY > wrapperBounds.top + wrapperBounds.height) {
        this.props.onDocumentClickedOutside();
      }
    }
  }

  render() {
    const { children, className } = this.props;

    return (
      <div className={className} ref={element => this.wrapper = element}>
        {children}
      </div>
    );
  }
}

ClickOutsideHandler.propTypes = {
  siteUIStore: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onDocumentClickedOutside: PropTypes.func.isRequired,
};

export default ClickOutsideHandler;
