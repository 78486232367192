import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './HeaderLink.styl';

import { ROLI_COM_URL } from 'constants/roli';
import { isInternal, makeRelative } from 'utils/linkUtils';

const HeaderLink = ({
  outsideApp,
  to,
  secondary,
  label,
  target,
  exact,
  activeFamily,
  onClicked,
}) => {
  to = (outsideApp && to.charAt(0) === '/') ? `${ROLI_COM_URL}${to}` : to;
  const classes = classNames({
    [styles.headerLink]: true,
    [styles.secondary]: secondary,
  });

  const internal = isInternal(to) && !outsideApp;

  // Manually make this link active if the active family and label match
  // This is because subnavs can contain Storefront Pages, which technically
  // aren't beneath a family, so we need to make it appear that way in the header
  const isActiveFamily = activeFamily && activeFamily.toLowerCase() === label.toLowerCase();

  const linkClasses = classNames({
    [styles.link]: true,
    [styles.activeLink]: isActiveFamily,
  });

  const attrs = {
    to: (internal) ? makeRelative(to) : undefined,
    href: (!internal) ? to : undefined,
    target: (!internal) ? (target || '_self') : undefined,
    className: linkClasses,
    title: label,
    onClick: (e) => {
      if (onClicked) onClicked(e);
    },
  };
  if (internal) {
    attrs.exact = exact;
    attrs.activeClassName = styles.activeLink;
  }

  const Component = (internal) ? NavLink : 'a';

  return (
    <li className={classes}>
      <Component {...attrs}>{label}</Component>
    </li>
  );
};

HeaderLink.propTypes = {
  outsideApp: PropTypes.bool,
  to: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  label: PropTypes.string.isRequired,
  target: PropTypes.string,
  exact: PropTypes.bool,
  activeFamily: PropTypes.string,
  onClicked: PropTypes.func,
};

export default withStyles(styles)(HeaderLink);
