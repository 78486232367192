exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteFooter_FooterSocialWrapper_footerSocialWrapper_2-ZCs{-webkit-box-sizing:border-box;box-sizing:border-box;min-width:0;padding-left:8px;padding-right:8px;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;max-width:100%;margin-bottom:48px}@media (min-width:544px){.siteFooter_FooterSocialWrapper_footerSocialWrapper_2-ZCs{padding-left:12px;padding-right:12px;-webkit-box-sizing:border-box;box-sizing:border-box;min-width:0;padding-left:8px;padding-right:8px;margin-left:33.33333333333333%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-basis:66.66666666666666%;-ms-flex-preferred-size:66.66666666666666%;flex-basis:66.66666666666666%;max-width:66.66666666666666%;-webkit-box-ordinal-group:4;-webkit-order:3;-ms-flex-order:3;order:3;margin-bottom:32px}}@media (min-width:850px){.siteFooter_FooterSocialWrapper_footerSocialWrapper_2-ZCs{-webkit-box-sizing:border-box;box-sizing:border-box;min-width:0;padding-left:8px;padding-right:8px;margin-left:8.333333333333332%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-basis:41.66666666666667%;-ms-flex-preferred-size:41.66666666666667%;flex-basis:41.66666666666667%;max-width:41.66666666666667%}}@media (min-width:544px) and (min-width:544px){.siteFooter_FooterSocialWrapper_footerSocialWrapper_2-ZCs{padding-left:12px;padding-right:12px}}@media (min-width:850px) and (min-width:544px){.siteFooter_FooterSocialWrapper_footerSocialWrapper_2-ZCs{padding-left:12px;padding-right:12px}}", ""]);

// exports
exports.locals = {
	"footerSocialWrapper": "siteFooter_FooterSocialWrapper_footerSocialWrapper_2-ZCs"
};