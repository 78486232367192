import React from 'react';
import PropTypes from 'prop-types';

const StoreLocatorIcon = ({ className }) => (
  <svg viewBox='0 0 15 21' className={className} preserveAspectRatio='xMidYMid meet'>
    <title>Store Locator</title>
    <path strokeWidth='2' d='M7.3 1.271C3.79 1.271 1 4.13 1 7.725c0 4.795 6.3 11.986 6.3 11.986s6.3-7.191 6.3-11.986c0-3.596-2.79-6.454-6.3-6.454zm0 8.76c-1.26 0-2.25-1.015-2.25-2.306 0-1.29.99-2.305 2.25-2.305s2.25 1.014 2.25 2.305c0 1.29-.99 2.305-2.25 2.305z' fill='none' fillRule='evenodd'></path>
  </svg>
);

StoreLocatorIcon.propTypes = {
  className: PropTypes.string,
};

export default StoreLocatorIcon;
