import React from 'react';
import PropTypes from 'prop-types';
import { XmlEntities } from 'html-entities';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

import { getYoutubeIdFromUrl, getYoutubeStartTimeFromUrl, getVimeoIdFromUrl } from 'utils/mediaUtils';
import { isInternal, isAnchorLink, isMailto, makeRelative } from 'utils/linkUtils';

import { CONTENTFUL_IMAGE_PROXY } from 'constants/contentful';

/* eslint-disable react/prop-types, react/display-name */
const MARKDOWN_RENDERERS = {
  Link: props => {
    if (isAnchorLink(props.href)) {
      return <a href={props.href}>{props.children}</a>;
    } else if (isMailto(props.href)) {
      return <a href={props.href}>{props.children}</a>;
    } else if (isInternal(props.href)) {
      return <Link to={makeRelative(props.href)}>{props.children}</Link>;
    }

    return <a href={props.href} target='_blank'>{props.children}</a>;
  },
  Image: props => (
    <img src={replaceWithProxyDomain(props.src)} alt={props.alt} />
  ),
};
/* eslint-enable react/prop-types, react/display-name */

const prepareForMarkdown = (postContent, allowDoubleSpaceLineBreaks) => {
  if (allowDoubleSpaceLineBreaks) {
    postContent = postContent.replace(/ {2}/g, '<br />');
  }
  postContent = convertMediaCaptions(postContent);
  postContent = convertEmbeds(postContent);
  postContent = convertHTML5Video(postContent);
  const entities = new XmlEntities();

  return entities.decode(postContent);
};

/**
 * An image Url that may need it's domain replaced with the proxy domain
 * @param {object} imageUrl
 */
const replaceWithProxyDomain = assetUrl => {
  let proxyUrl = CONTENTFUL_IMAGE_PROXY || '';
  if (proxyUrl) {
    if (proxyUrl.indexOf('http') < 0) {
      proxyUrl = `https://${proxyUrl.replace('//', '')}`;
    }

    assetUrl = assetUrl.replace(/\/\/(images|videos)\.contentful\.com/g, proxyUrl);
  }

  return assetUrl;
};

const convertMediaCaptions = postContent => {
  const captions = postContent.match(/\[\[[^\]]+\]\]/gi);
  if (captions && captions.length) {
    let i = 0, length = captions.length;
    for (; i < length; i++) {
      const match = captions[i];
      const start = match.indexOf('[[') + 2;
      const end = match.indexOf(']]');
      const captionContent = match.substring(start, end);
      postContent = postContent.replace(match, `<div class="mediaCaption">${captionContent}</div>`);
    }
  }

  return postContent;
};

/**
 * Find the Embedly card HTML that Contentful inserts, and convert
 * them into normal YouTube/Vimeo iframes instead, because why pay a monthly
 * fee to Embedly for something so simple?
 * @param {string} postContent A blog post content chunk
 **/
const convertEmbeds = postContent => {
  const regexp = new RegExp('<a href="[^"\']+" class="embedly-card" [^>]+>[^<]+</a>', 'gi');
  const embedMatches = postContent.match(regexp);
  if (embedMatches && embedMatches.length) {
    let i = 0, length = embedMatches.length;
    for (; i < length; i++) {
      const match = embedMatches[i];
      const start = match.indexOf('href="') + 6;
      const end = match.indexOf('" class');
      const url = match.substring(start, end);
      // Generate iFrame HTML to replace YouTube and Vimeo Embedly anchors
      let iframeHtml = undefined;
      if (url.search(/youtu(be.com|\.be)/gi) >= 0) {
        // Create a YouTube iFrame
        iframeHtml = createYouTubeIframe(url);
      } else if (url.search(/vimeo/gi) >= 0) {
        // Create  Vimeo iFrame
        iframeHtml = createVimeoIframe(url);
      }
      // Replace the anchor HTML with the iFrame HTML
      if (iframeHtml) postContent = postContent.replace(match, iframeHtml);
    }
  }

  return postContent;
};

const convertHTML5Video = postContent => {
  const regexp = new RegExp('!\\[[^\\]]+\\]\\([^\\)]+\\.(mp4|webm)\\)', 'gi');
  const videoMatches = postContent.match(regexp);
  if (videoMatches && videoMatches.length) {
    let i = 0, length = videoMatches.length;
    for (; i < length; i++) {
      const match = videoMatches[i];
      const start = match.indexOf('(') + 1;
      const end = match.indexOf('mp4') > -1 ? match.indexOf('.mp4') + 4 : match.indexOf('.webm') + 5;
      const url = replaceWithProxyDomain(match.substring(start, end));
      const videoTag = `<div class="videoWrapper"><video src="${url}" preload="metadata" controls /></div>`;
      if (url) postContent = postContent.replace(match, videoTag);
    }
  }

  return postContent;
};

/**
* Create a YouTube iframe from a regular YouTube URL
* @param {string} url A YouTube URL (either youtube.com or youtu.be)
*/
const createYouTubeIframe = url => {
  const youtubeId = getYoutubeIdFromUrl(url);
  if (!youtubeId) return undefined;

  const startTime = getYoutubeStartTimeFromUrl(url);
  const startTimeAsSeconds = moment.duration(`PT${startTime.toUpperCase()}`).asSeconds();

  return `<iframe src='https://www.youtube.com/embed/${youtubeId}?start=${startTimeAsSeconds}' allowfullscreen='true' frameborder='0' width='560' height='315'></iframe>`;
};

/**
* Create a Vimeo iframe from a regular Vimeo URL
* @param {string} url A standard Vimeo URL
*/
const createVimeoIframe = url => {
  const vimeoId = getVimeoIdFromUrl(url);
  if (!vimeoId) return undefined;

  return `<iframe src='https://player.vimeo.com/video/${vimeoId}?color=ffffff' width='640' height='360' frameborder='0' allowfullscreen='true'></iframe>`;
};

/**
 * Component that renders raw text as Markdown
 * @param {string} source The text that should be rendered as markdown
 */
const Markdown = ({ source, allowDoubleSpaceLineBreaks, ...props }) => {
  return source
    ? <ReactMarkdown
      source={prepareForMarkdown(source, allowDoubleSpaceLineBreaks)}
      renderers={MARKDOWN_RENDERERS}
      {...props}
    />
    : null;
};

Markdown.propTypes = {
  source: PropTypes.string,
  allowDoubleSpaceLineBreaks: PropTypes.bool,
};

export default Markdown;
