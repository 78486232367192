import { createClient } from 'contentful';

import { SPACE_ID, ACCESS_TOKEN, ACCESS_TOKEN_PREVIEW, CONTENTFUL_API_MODE, CONTENTFUL_HOST, CONTENTFUL_HOST_PREVIEW } from 'constants/contentful';

class ContentfulService {

  DEFAULT_OPTIONS = {
    skip: 0,
    limit: 20,
    order: '-sys.createdAt',
    include: 0,
  };
  client = null;

  constructor() {
    const contentfulSettings = {
      space: SPACE_ID,
      accessToken: (CONTENTFUL_API_MODE === 'preview') ? ACCESS_TOKEN_PREVIEW : ACCESS_TOKEN,
      host: (CONTENTFUL_API_MODE === 'preview') ? CONTENTFUL_HOST_PREVIEW : CONTENTFUL_HOST,
    };
    this.client = createClient(contentfulSettings);
  }

  /**
   * Set the locale used when loading all content
   * @param {string} isoCode The country code for the language that should be used when loading content
   */
  setLocale(isoCode) {
    this.DEFAULT_OPTIONS.locale = isoCode;
  }

  /**
   * Get one or more entries from Contentful, based on content type
   * @param {string} contentType The name of the content type used in Contentful, camelcase
   * @param {object} options Search options required to find the items desired.
   */
  getEntries(contentType, options={}) {
    options = Object.assign({}, this.DEFAULT_OPTIONS, options);
    options['content_type'] = contentType;

    return this.client.getEntries(options)
      .then(response => {
        if (response.errors) {
          const errorIds = response.errors.map(error => error.details.id);
          const filteredResponse = this.checkForUnresolvedLinks(response, errorIds);

          return {items: filteredResponse};
        }

        return response;
      });
  }

  /**
   * Check through a Contentful response object for any unresolved links
   * These appear when a post is published, then set back to draft again
   * @param {object} response The response from Contentful
   * @param {array} errorIds An array of IDs belonging to unresolved linked entries
   */
  checkForUnresolvedLinks(response, errorIds) {
    return response.items.map(item => {
      if (errorIds.indexOf(item.sys) === -1) {
        return Object.assign(item, {
          fields: this.checkFieldsAreResolved(item.fields, errorIds),
        });
      }
    });
  }

  /**
   * Recursively check through a set of fields for unresolved linked entities
   * @param {any} fields Object, string, or array of field values
   * @param {array} errorIds An array of IDs belonging to unresolved linked entries
   * @param {array} resolvedIds Array of IDs already resolved, used to prevent circular reference resolution
   */
  checkFieldsAreResolved(fields, errorIds, resolvedIds = []) {
    const newFields = {};
    if (typeof fields == 'string') return fields;
    Object.keys(fields).forEach(key => {
      if (fields[key].sys) {
        if (errorIds.indexOf(fields[key].sys.id) === -1 && resolvedIds.indexOf(fields[key].sys.id) === -1) {
          const newResolvedIds = resolvedIds.concat(fields[key].sys.id);
          newFields[key] = {
            fields: this.checkFieldsAreResolved(fields[key].fields, errorIds, newResolvedIds),
            sys: fields[key].sys,
          };
        }
      } else if (fields[key] instanceof Array) {
        newFields[key] = fields[key].filter(value => {
          // Remember that if a value doesn't have a sys attribute, just return it raw, as this field is probably just a list of strings
          return ((value.sys && errorIds.indexOf(value.sys.id) === -1) || !value.sys);
        });
        newFields[key].map(value => this.checkFieldsAreResolved(value, errorIds, resolvedIds));
      } else {
        newFields[key] = fields[key];
      }
    });

    return newFields;
  }
}

const service = new ContentfulService();

export default service;
