import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobXPropTypes} from 'mobx-react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import globalStyles from 'globalStyles';
import styles from './MainNav.styl';

import { ROLI_COM_URL, SUPPORT_SITE_URL, THROTTLE_DELAY } from 'constants/roli';

import debounce from 'utils/debounce';

import CartPreviewPanel from 'containers/common/CartPreviewPanel';
import AccountPanel from 'containers/common/AccountPanel';

import HeaderLink from 'components/siteHeader/HeaderLink';
import RoliLogo from 'components/common/icons/RoliLogo';
import MenuButton from 'components/common/buttons/MenuButton';
import StoreLocatorButton from 'components/common/buttons/StoreLocatorButton';
import SlimBanner from 'components/siteHeader/SlimBanner';

@inject('siteUIStore') @observer
class MainNav extends Component {

  constructor(props) {
    super(props);
    this.siteNavElement = undefined;
    this.menuButtonElement = undefined;
    this.subnavElement = undefined;
    this.bannerElement = undefined;
    this.state = {
      bannerVisible: true,
      bannerHeight: 0,
      mainNavHeight: 0,
      bannerClosed: false,
    };

  }

  componentDidMount() {
    if (!this.props.siteUIStore.runningOnServer) {
      window.addEventListener('resize', this.onWindowResized);
      requestAnimationFrame(this.onBannerToggled);
      this.onWindowResized();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.outsideApp !== prevProps.outsideApp
      || this.props.minimal !== prevProps.minimal
      || this.props.bannerData !== prevProps.bannerData) {
      // Update banner visibility and height if the relevant props changed
      this.onBannerToggled();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResized);
  }

  decideBannerVisibility() {
    return (!this.props.outsideApp && !this.props.minimal && this.props.bannerData.text && !this.state.bannerClosed);
  }

  onBannerToggled = () => {
    const bannerVisible = this.decideBannerVisibility();
    const newHeight = (bannerVisible && this.bannerElement) ? this.bannerElement.clientHeight : 0;
    this.setState({
      bannerVisible,
      bannerHeight: newHeight,
    });
    this.props.siteUIStore.setHeaderBannerHeight(newHeight);
  }

  onWindowResized = debounce(() => {
    const mainNavHeight = this.siteNavElement ? this.siteNavElement.clientHeight : 0;
    const bannerHeight = (this.bannerElement && !this.state.bannerClosed) ? this.bannerElement.clientHeight : 0;
    const subnavHeight = this.subnavElement ? this.subnavElement.clientHeight : 0;

    this.props.siteUIStore.updateSiteHeaderHeight(
      mainNavHeight,
      subnavHeight,
      bannerHeight
    );
    if (this.menuButtonElement) {
      this.props.siteUIStore.updateMobileSiteNavVisibility(getComputedStyle(this.menuButtonElement)['display'] !== 'none');
    }
    this.setState({
      mainNavHeight: mainNavHeight,
    });
    this.onBannerToggled();
  }, THROTTLE_DELAY)

  onBannerRef = element => {
    this.bannerElement = element;
    this.onBannerToggled();
  }

  onBannerCloseClicked = () => {
    this.setState({
      bannerClosed: true,
    }, () => {
      this.onWindowResized();
    });
  }

  render() {
    const {
      siteUIStore,
      outsideApp,
      minimal,
      children,
      mainNavLinks,
      slideOutMenuOpen,
      slideOutMenuUsed,
      globalTranslations,
      onMenuButtonClicked,
      onLinkClicked,
      bannerData,
      matchingRoute,
    } = this.props;
    const mainNavItems = (toJS(mainNavLinks) || []).map((navOption, i) => (
      <HeaderLink
        key={i}
        outsideApp={outsideApp}
        to={navOption.slug}
        label={navOption.name}
        activeFamily={(matchingRoute && matchingRoute.params)
          ? matchingRoute.params.family
          : undefined
        }
        onClicked={onLinkClicked}
      />
    ));

    const sidePanelClasses = classNames({
      [styles.sidePanel]: true,
      [styles.sidePanelOpen]: slideOutMenuOpen,
    });

    return (
      <div className={styles.mainNavWrapper} style={{
        paddingTop: (siteUIStore && siteUIStore.mobileSiteNavVisible) ? siteUIStore.siteHeaderHeight : 0,
      }}>
        <header className={styles.mainNav}>
          <h2 className={globalStyles.hidden}>{globalTranslations.siteNavigation}</h2>
          <nav className={styles.siteNavigation}>
            {this.state.bannerVisible &&
              <div className={styles.bannerWrapper} ref={this.onBannerRef}>
                <SlimBanner
                  copy={bannerData.text}
                  linkLabel={bannerData.label}
                  linkUrl={bannerData.url}
                  outsideApp={outsideApp}
                  onCloseButtonClicked={this.onBannerCloseClicked}
                />
              </div>
            }
            <div className={styles.mainSectionsWrapper} ref={element => this.siteNavElement = element}>
              <div className={styles.mainSections}>
                <div className={styles.mainSectionsInner}>
                  <Link to={outsideApp ? ROLI_COM_URL : '/'} className={styles.roliLogoLink} target={outsideApp ? '_self' : undefined}>
                    <h2 className={styles.roliLogoHeader}>
                      <RoliLogo className={styles.roliLogo} />
                    </h2>
                  </Link>
                  {!minimal &&
                    <div
                      className={sidePanelClasses}
                      style={{
                        top: this.state.bannerHeight + this.state.mainNavHeight, // Used in small layouts so that the sidenav sits below the header
                      }}
                    >
                      <div className={styles.sidePanelContent}>
                        <h2 className={styles.mainNavLinksTitle}>Site Navigation</h2>
                        { mainNavItems.length > 0 && <ul className={styles.mainNavLinks}>
                          {mainNavItems}
                        </ul>}

                        <h2 className={globalStyles.hidden}>{globalTranslations.siteSections}</h2>
                        {globalTranslations.blogTitle && <ul className={styles.siteSections}>
                          <HeaderLink
                            outsideApp={outsideApp}
                            to='/stories'
                            secondary={true}
                            label={globalTranslations.blogTitle}
                            onClicked={onLinkClicked}
                          />
                          <HeaderLink
                            outsideApp={outsideApp}
                            to='/learn'
                            secondary={true}
                            label={globalTranslations.learnSectionTitle}
                            onClicked={onLinkClicked}
                          />
                          <HeaderLink
                            outsideApp={outsideApp}
                            to={SUPPORT_SITE_URL}
                            secondary={true}
                            label={globalTranslations.supportSectionTitle}
                            target='_blank'
                            onClicked={onLinkClicked}
                          />
                        </ul>}
                      </div>
                    </div>
                  }
                  {!minimal &&
                    <div className={styles.iconButtons}>
                      <ul className={styles.userOptions}>
                        <CartPreviewPanel outsideApp={outsideApp} />
                        <li className={styles.userOptionsItem}>
                          <StoreLocatorButton />
                        </li>
                        <AccountPanel outsideApp={outsideApp} />
                      </ul>
                      <div className={styles.menuButtonWrapper} ref={element => this.menuButtonElement = element}>
                        <MenuButton
                          open={slideOutMenuOpen}
                          used={slideOutMenuUsed}
                          onButtonClicked={onMenuButtonClicked}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className={styles.secondaryNav} ref={element => this.subnavElement = element}>
              {children}
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

MainNav.propTypes = {
  siteUIStore: PropTypes.object,
  outsideApp: PropTypes.bool,
  minimal: PropTypes.bool,
  children: PropTypes.node,
  mainNavLinks: MobXPropTypes.arrayOrObservableArray,
  slideOutMenuOpen: PropTypes.bool,
  slideOutMenuUsed: PropTypes.bool,
  globalTranslations: PropTypes.object,
  onMenuButtonClicked: PropTypes.func.isRequired,
  onLinkClicked: PropTypes.func.isRequired,
  bannerData: PropTypes.object,
  matchingRoute: PropTypes.object,
};

export default withStyles(globalStyles, styles)(MainNav);
