exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteHeader_SlimBanner_banner_c5TDT{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;max-width:1176px;-webkit-box-sizing:border-box;box-sizing:border-box;margin-left:auto;margin-right:auto;padding:5px 24px 5px 8px;padding-right:8px;position:relative;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center}@media (min-width:544px){.siteHeader_SlimBanner_banner_c5TDT{padding-left:12px;padding-right:12px}}@media (min-width:850px){.siteHeader_SlimBanner_banner_c5TDT{padding-right:24px}}.siteHeader_SlimBanner_bannerText_1v1rW{font-size:16px}.siteHeader_SlimBanner_bannerLink_3zXyM{margin-left:5px;text-decoration:underline;color:#fff;font-weight:700}.siteHeader_SlimBanner_banner_c5TDT .siteHeader_SlimBanner_bannerCloseButton_1jRCI{top:1px;right:0}", ""]);

// exports
exports.locals = {
	"banner": "siteHeader_SlimBanner_banner_c5TDT",
	"bannerText": "siteHeader_SlimBanner_bannerText_1v1rW",
	"bannerLink": "siteHeader_SlimBanner_bannerLink_3zXyM",
	"bannerCloseButton": "siteHeader_SlimBanner_bannerCloseButton_1jRCI"
};