import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './TextInput.styl';

const TextInput = ({ id, darkMode, isSmall, className, field, errorMessage, placeholder, ...props }) => {
  const wrapperClasses = classNames({
    [styles.textInputWrapper]: true,
    [className]: className,
    [styles.floatingLabel]: !isSmall && field && !!field.value,
    [styles.small]: isSmall,
  });

  const inputClasses = classNames({
    [styles.input]: true,
    [styles.inputLight]: !darkMode,
    [styles.inputDark]: darkMode,
    [styles.inputLightError]: !darkMode && !!errorMessage,
    [styles.inputDarkError]: darkMode && !!errorMessage,
  });

  return (
    <div className={wrapperClasses}>
      {placeholder && <label htmlFor={id} className={styles.label}>{placeholder}</label>}
      <input
        className={inputClasses}
        placeholder={placeholder}
        id={id}
        {...props}
      />
    </div>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  darkMode: PropTypes.bool,
  isSmall: PropTypes.bool,
  field: PropTypes.object,
  placeholder: PropTypes.string,
};

export default withStyles(styles)(TextInput);
