exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".buttons_RemoveFromCartButton_crossButton_2VfH7{position:relative;display:inline-block;width:32px;height:32px;overflow:hidden;cursor:pointer;background:transparent;border:0}.buttons_RemoveFromCartButton_crossButton_2VfH7:after,.buttons_RemoveFromCartButton_crossButton_2VfH7:before{content:\"\";position:absolute;top:50%;left:50%;display:block;width:2px;height:16px;pointer-events:none;background:#888b8d}.buttons_RemoveFromCartButton_crossButton_2VfH7:before{-webkit-transform:translateY(-50%) rotate(45deg);-ms-transform:translateY(-50%) rotate(45deg);transform:translateY(-50%) rotate(45deg)}.buttons_RemoveFromCartButton_crossButton_2VfH7:after{-webkit-transform:translateY(-50%) rotate(-45deg);-ms-transform:translateY(-50%) rotate(-45deg);transform:translateY(-50%) rotate(-45deg)}.buttons_RemoveFromCartButton_crossButtonSmall_11KVa:after,.buttons_RemoveFromCartButton_crossButtonSmall_11KVa:before{height:8px;width:1px}.buttons_RemoveFromCartButton_crossButton_2VfH7:focus{outline:none}.buttons_RemoveFromCartButton_crossButton_2VfH7:focus:after,.buttons_RemoveFromCartButton_crossButton_2VfH7:focus:before{background:#cd3443}.buttons_RemoveFromCartButton_removeFromCartButton_xfjpW{opacity:0}", ""]);

// exports
exports.locals = {
	"crossButton": "buttons_RemoveFromCartButton_crossButton_2VfH7",
	"crossButtonSmall": "buttons_RemoveFromCartButton_crossButtonSmall_11KVa",
	"removeFromCartButton": "buttons_RemoveFromCartButton_removeFromCartButton_xfjpW"
};