exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cartPreviewPanel_CartItem_cartItem_2GHAq{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;margin-top:8px;padding:8px 16px;color:#545559;border-radius:4px;background:#f2f2f2}.cartPreviewPanel_CartItem_imageWrapper_bTG5j{width:53%;padding:0 10px 0 0;-webkit-box-sizing:border-box;box-sizing:border-box}.cartPreviewPanel_CartItem_productImage_1zMs6{width:100%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0}.cartPreviewPanel_CartItem_productDetails_19dMS{width:47%}.cartPreviewPanel_CartItem_details_3cKGF{display:block;margin:0;padding:0;font-size:12px;line-height:16px;letter-spacing:-.3px}.cartPreviewPanel_CartItem_title_3OR2o{margin:0;padding:0 0 7px;font-size:18px;line-height:22px}.cartPreviewPanel_CartItem_pricePerItem_3Nt2z,.cartPreviewPanel_CartItem_quantity_3XWpL{display:inline}.cartPreviewPanel_CartItem_removeButtonWrapper_1J8Z7{position:absolute;right:0;top:0}", ""]);

// exports
exports.locals = {
	"cartItem": "cartPreviewPanel_CartItem_cartItem_2GHAq",
	"imageWrapper": "cartPreviewPanel_CartItem_imageWrapper_bTG5j",
	"productImage": "cartPreviewPanel_CartItem_productImage_1zMs6",
	"productDetails": "cartPreviewPanel_CartItem_productDetails_19dMS",
	"details": "cartPreviewPanel_CartItem_details_3cKGF",
	"title": "cartPreviewPanel_CartItem_title_3OR2o",
	"quantity": "cartPreviewPanel_CartItem_quantity_3XWpL",
	"pricePerItem": "cartPreviewPanel_CartItem_pricePerItem_3Nt2z",
	"removeButtonWrapper": "cartPreviewPanel_CartItem_removeButtonWrapper_1J8Z7"
};