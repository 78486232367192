import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './ShopButton.styl';

import { isInternal, makeRelative } from 'utils/linkUtils';

const ShopButton = ({ label, to, inverted, target, onClicked, customBgColor }) => {
  const classes = classNames({
    [styles.button]: true,
    [styles.inverted]: inverted,
  });
  const customStyles = customBgColor ? {
    backgroundColor: customBgColor,
  } : undefined;

  return (
    <Link
      className={classes}
      to={target ? to : (isInternal ? makeRelative(to) : to)}
      target={target}
      onClick={onClicked}
      style={customStyles}
    >
      {label}
    </Link>
  );
};

ShopButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  target: PropTypes.string,
  onClicked: PropTypes.func,
  customBgColor: PropTypes.string,
};

export default withStyles(styles)(ShopButton);
