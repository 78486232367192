import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './AdditionalFooterCopy.styl';

import Markdown from 'components/common/Markdown';

const AdditionalFooterCopy = ({ copy }) => (
  <aside className={styles.additionalFooterCopy}>
    {copy && <Markdown source={copy} softBreak='br' />}
  </aside>
);

AdditionalFooterCopy.propTypes = {
  copy: PropTypes.string,
};

export default withStyles(styles)(AdditionalFooterCopy);
