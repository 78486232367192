import { ROLI_COM_URL } from 'constants/roli';
import urlParse from 'url-parse';

const EXTERNAL_SITE_PATHS = [
  '/mysound',
  '/stores',
  '/stores/map',
  '/stores/list',
  '/education',
  '/press-room',
  '/press',
  '/article',
  '/sea-interface',
  '/affirm',
  '/geoip',
  '/fonts',
];

export const makeRelative = (url) => {
  if (isInternal(url)) {
    const parsedUrl = urlParse(url);
    parsedUrl.set('protocol', '');
    parsedUrl.set('slashes', '');
    parsedUrl.set('hostname', '');
    parsedUrl.set('port', '');

    if (isAnchorLink(url)) {
      parsedUrl.set('pathname', '');
    }

    return parsedUrl.toString();
  }

  return url;
};

export const convertToAnchor = (sectionName) => {
  return sectionName.replace(/ /g, '-');
};

export const isMailto = (url) => (url.indexOf('mailto:') != -1);

export const isInternal = (url) => {
  const parsedUrl = urlParse(url);

  // Check that hostname _starts_ with roli.com in order to not match subdomains.
  // Also allow empty hostname for relative links being parsed server side.
  // Paths needing proxy handling are also exceptions.

  // Check that the path isn't matching an externally hosted site
  let i = 0, length = EXTERNAL_SITE_PATHS.length;
  for (; i < length; i++) {
    if (parsedUrl.pathname === EXTERNAL_SITE_PATHS[i] || parsedUrl.pathname === `${EXTERNAL_SITE_PATHS[i]}/`) {
      return false;
    }
  }

  return parsedUrl.hostname.search(/(preview\.|staging\.)?roli.com/i) === 0 || !parsedUrl.hostname;
};

export const needsProxy = (url) => {
  const parsedUrl = urlParse(url);

  //  Anything matching this array needs to be handled on the server by nginx
  return /^\/(education|press-room|article|stores|sea-interface|affirm)/.test(parsedUrl.pathname);
};

export const getQueryParam = (url, param, forceLowerCase = true) => {
  const parsedUrl = urlParse(url, true);

  return forceLowerCase
    ? (parsedUrl.query[param] || '').toLowerCase()
    : (parsedUrl.query[param] || '');
};

export const getBlogPostUrl = (slug, isAbsolute) => {
  const url = `${ROLI_COM_URL}/stories/${slug}`;

  return isAbsolute ? url : makeRelative(url);
};

export const convertToFragmentId = (uniqueString) => {
  return uniqueString.toLowerCase().replace(/[^a-zA-Z\d]/gi, '-');
};

export const isAnchorLink = (url) => {
  if (url) {
    const parsedUrl = urlParse(url);
    const windowPathname = (typeof window !== 'undefined' && window.location) ? window.location.pathname.replace(/\/$/, '') : '/';
    const urlPathname = parsedUrl.pathname.replace(/\/$/, '');

    let matchingPath = windowPathname === urlPathname || (url.indexOf('#') === 0);

    return (matchingPath && parsedUrl.hash);
  } else {
    return false;
  }
};

export const slugifyString = (originalString) => {
  return originalString ? originalString.replace(/\s/g , '-',).toLowerCase() : undefined;
};

export const stripTrailingSlash = (slug) => {
  return slug ? slug.replace(/\/+$/, '') : '';
};

export const getAnchor = (url) => {
  return url.substring(url.indexOf('#') + 1);
};
