import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { inject, observer } from 'mobx-react';

import styles from './LinkButton.styl';

import { makeRelative, isInternal, isAnchorLink } from 'utils/linkUtils';

@inject('siteUIStore') @observer
class LinkButton extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      icon,
      label,
      to,
      target,
      inverted,
      light,
      dark,
      white,
      dummyButton,
      showHoverState,
      className,
      customBgColor,
    } = this.props;
    const classes = classNames({
      [styles.button]: true,
      [styles.inverted]: inverted,
      [styles.light]: light,
      [styles.dark]: dark,
      [styles.white]: white,
      [styles.hovered]: showHoverState,
      [className]: !!className,
    });
    const internal = isInternal(to);
    const anchorLink = isAnchorLink(to);
    const customStyles = customBgColor ? {
      backgroundColor: customBgColor,
    } : undefined;
    const attrs = {
      className: classes,
      title: label,
      style: customStyles,
    };

    let Component = Link;
    if (dummyButton) {
      Component = 'span';
    } else {
      if (anchorLink) {
        attrs.href = makeRelative(to);
        attrs.target = '_self';
        Component = 'a';
      } else if(internal) {
        attrs.to = makeRelative(to);
      } else {
        attrs.href = to;
        attrs.target = (target || '_blank');
        Component = 'a';
      }
    }

    return (
      <Component {...attrs}>
        {label}
        { icon && <figure className={styles.icon}>
          {icon}
        </figure> }
      </Component>
    );
  }
}

LinkButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  target: PropTypes.string,
  inverted: PropTypes.bool,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  white: PropTypes.bool,
  dummyButton: PropTypes.bool,
  showHoverState: PropTypes.bool,
  siteUIStore: PropTypes.object,
  className: PropTypes.string,
  customBgColor: PropTypes.string,
};

LinkButton.defaultProps = {
  inverted: true,
  white: false,
};

export default withStyles(styles)(LinkButton);
