import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './StoreLocatorButton.styl';

import { STORE_LOCATOR_URL } from 'constants/roli';

import StoreLocatorIcon from 'components/common/icons/StoreLocatorIcon';

const StoreLocatorButton = ({ onButtonClicked }) => (
  <a
    href={STORE_LOCATOR_URL}
    className={styles.storeLocatorButton}
    onClick={(e) => {
      if (onButtonClicked) {
        e.preventDefault();
        onButtonClicked(e);
      }
    }}
    target='_self'
  >
    <StoreLocatorIcon className={styles.storeLocatorIcon} />
  </a>
);

StoreLocatorButton.propTypes = {
  onButtonClicked: PropTypes.func,
};

export default withStyles(styles)(StoreLocatorButton);
