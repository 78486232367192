import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ strokeWidth=2, ...props }) => (
  <svg width={ 23 } height={ 23 } viewBox='0 0 23 23' stroke='white' {...props}>
    <path d='M1,1 L22,22' style={{ strokeWidth }}></path>
    <path d='M1,1 L22,22' style={{ strokeWidth }} transform='rotate(90, 11.5, 11.5)'></path>
  </svg>
);

CloseIcon.propTypes = {
  strokeWidth: PropTypes.number,
};

export default CloseIcon;
