import validatorjs from 'validatorjs';
import urlParse from 'url-parse';

import contentful from 'services/contentful';
import { DEFAULT_LOCALE } from 'constants/roli';
import { supportedLocaleOptions, supportedLocaleCodes } from 'constants/locales';

class LocaleStore {

  locale = DEFAULT_LOCALE;
  fields = {
    locale: {
      rules: 'required',
      value: '',
      extra: [
        {
          disabled: true,
          value: '',
        },
        ...supportedLocaleOptions,
      ],
    },
  };
  plugins = {
    dvr: validatorjs,
  };
  options = {
    validateOnChange: false,
  };

  constructor(cookieHandler, location) {
    this.cookieHandler = cookieHandler;
    this.checkLocale(location);
    this.fields.locale.value = this.locale;
    // Set the site language on the Contentful service as defined in siteUIStore
    contentful.setLocale(this.locale);
  }

  checkLocale(location) {
    // The standalone header build does not have access to react-cookie during it's initial
    // static site build, so we have to check for it before using it
    const localeCookie = (this.cookieHandler) ? this.cookieHandler.get('locale') : '';
    if (localeCookie) this.locale = localeCookie;
    if (location) {
      const localeParam = urlParse(`${location.pathname}/${location.search}`, true).query.locale;
      if (localeParam) {
        this.updateLocale(localeParam);
      }
    }
  }

  updateLocale(value) {
    // Only set the cookie if it's a supported locale
    if (supportedLocaleCodes.indexOf(value) > -1) {
      this.cookieHandler.set('locale', value, {
        maxAge: (30 * 24) * 3600,
        path: '/',
      });
      this.locale = value;
      // Set the site language on the Contentful service as defined in siteUIStore
      contentful.setLocale(this.locale);
    }
  }
}

export default LocaleStore;
