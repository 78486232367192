import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './MenuButton.styl';

const MenuButton = ({ onButtonClicked, open, used }) => {
  const classes = classNames({
    [styles.menuButton]: true,
    [styles.menuButtonOpen]: open,
    [styles.isUsed]: used, // Adds the 'mouse off' animation on first click. This prevents the animation playing immediately on page load.
  });

  /* We use an anchor here instead of a button, as you can't add pseudo content to a button element */
  return (
    <a href='#' className={classes} onClick={(e) => {
      e.preventDefault();
      onButtonClicked();
    }}>
      <span className={styles.label}>Menu</span>
    </a>
  );
};

MenuButton.propTypes = {
  onButtonClicked: PropTypes.func,
  open: PropTypes.bool,
  used: PropTypes.bool,
};

export default withStyles(styles)(MenuButton);
