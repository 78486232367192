import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './LoggedOutContent.styl';

import { AUTH_URL, ROLI_COM_URL } from 'constants/roli';

import LinkButton from 'components/common/buttons/LinkButton';

const LoggedOutContent = () => (
  <div className={styles.loggedOutContent}>
    <h2 className={styles.title}>Not logged in</h2>
    <LinkButton
      label={'Log in'}
      to={`${AUTH_URL}/signin?redirect=${ROLI_COM_URL}`}
      target={'_self'}
    />
  </div>
);

export default withStyles(styles)(LoggedOutContent);
