exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".accountPanel_AccountWrapper_accountWrapper_3san1{position:relative;z-index:105;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:stretch;-webkit-align-items:stretch;-ms-flex-align:stretch;align-items:stretch;width:32px}.accountPanel_AccountWrapper_accountWrapper_3san1:before{content:\"\";position:absolute;bottom:0;left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);display:block;opacity:0;border-width:8px;border-color:transparent transparent #fafafa;border-style:solid;-webkit-transition:opacity .1s linear;transition:opacity .1s linear}.accountPanel_AccountWrapper_accountWrapperOpen_10MLr:before{opacity:1}", ""]);

// exports
exports.locals = {
	"accountWrapper": "accountPanel_AccountWrapper_accountWrapper_3san1",
	"accountWrapperOpen": "accountPanel_AccountWrapper_accountWrapperOpen_10MLr"
};