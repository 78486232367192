exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cartPreviewPanel_CartPanelContent_emptyCartMessage_RuqBL,.cartPreviewPanel_CartPanelContent_title_3h0DY{margin:0;padding:0 0 2px;color:#545559;font-size:18px;font-weight:400;line-height:24px;letter-spacing:-.5px}.cartPreviewPanel_CartPanelContent_emptyCartMessage_RuqBL{padding-bottom:0}.cartPreviewPanel_CartPanelContent_subtotal_X2dpK{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;margin:8px 0;padding:8px 16px;border-radius:4px;background:#f2f2f2}.cartPreviewPanel_CartPanelContent_subtotalTitle_3XnjQ{width:53%;margin:0;padding:0 10px 0 0;font-size:12px;line-height:16px;letter-spacing:.3px;text-align:right;-webkit-box-sizing:border-box;box-sizing:border-box}.cartPreviewPanel_CartPanelContent_subtotalValue_9nclq{width:47%;margin:0;font-size:18px;font-weight:700;line-height:22px;letter-spacing:-.5px;text-align:right}.cartPreviewPanel_CartPanelContent_cartOptions_18-wH{text-align:right}", ""]);

// exports
exports.locals = {
	"emptyCartMessage": "cartPreviewPanel_CartPanelContent_emptyCartMessage_RuqBL",
	"title": "cartPreviewPanel_CartPanelContent_title_3h0DY",
	"subtotal": "cartPreviewPanel_CartPanelContent_subtotal_X2dpK",
	"subtotalTitle": "cartPreviewPanel_CartPanelContent_subtotalTitle_3XnjQ",
	"subtotalValue": "cartPreviewPanel_CartPanelContent_subtotalValue_9nclq",
	"cartOptions": "cartPreviewPanel_CartPanelContent_cartOptions_18-wH"
};