import { observable, action, runInAction } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Loads and manages campaign page data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class CampaignStore {

  INCLUDE_REFERENCE_DEPTH = 4;

  @observable.shallow campaignData = undefined;
  @observable lastLoadedSlug = undefined;
  @observable loading = true;
  @observable notFound = false;
  @observable shopifyDataLoaded = false;
  @observable pending = false;
  @observable success = false;
  @observable errorMessage = undefined;
  @observable subnavSections = [];
  @observable pageTakeoverActive = false;
  @observable sideNavDisabled = false;
  // Used just for jumping to a subsection of a module (ie. a particular question)
  @observable clickedSubsection = undefined;
  // This is what's actually displayed to the user
  @observable displayedActiveSubsection = 0;
  // This is used to decide where to jump the Q&A slideshow to when navigating
  // to it from the sidenav
  @observable userScrollingDown = true;
  requestedSlug = false;
  shopifyProductStore = undefined;

  get pending() {
    return this.pendingStatus;
  }

  get competitionTitle() {
    return this.success ?
      getField(this.campaignData, 'competition.fields.successTitle'):
      getField(this.campaignData, 'competition.fields.title');
  }

  get competitionIntro() {
    return this.success ?
      getField(this.campaignData, 'competition.fields.successMessage'):
      getField(this.campaignData, 'competition.fields.introCopy');
  }

  constructor(shopifyProductStore, siteMetaStore, siteUIStore, preloadedData) {
    this.shopifyProductStore = shopifyProductStore;
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();
    this.siteUIStore = siteUIStore;
    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.campaignStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  getSlugFromRoute(match) {
    // Campaign pages can be loaded via urls other than campaign/:slug
    // So if slug doesn't exist in the route match object, use the first path segment
    let slug = '';
    if (match) {
      if (match && match.params && match.params.slug) {
        slug = match.params.slug;
      } else if (match.path) {
        const pathSegments = match.path.split('/');
        slug = pathSegments[0] !== '' ? pathSegments[0] : pathSegments[1];
      } else {
        slug = '';
      }
    }

    return slug;
  }

  @action('Update side nav disabled state')
  setSideNavDisabled(value) {
    this.sideNavDisabled = value;
  }

  @action('Update page takeover value')
  setPageTakeoverActive(value) {
    this.pageTakeoverActive = value;
  }

  @action('Updating clicked subsection')
  setClickedSubsection(index) {
    this.displayedActiveSubsection = index;
    this.clickedSubsection = index;
  }

  @action('Set the displayed subnav to a particular value')
  setDisplayedActiveSubsection(index) {
    this.displayedActiveSubsection = index;
  }

  @action('Clearing the clicked subsection')
  clearClickedSubsection() {
    this.clickedSubsection = undefined;
  }

  @action('Update the acive page section index')
  setUserScrollingDown(value) {
    this.userScrollingDown = value;
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);
    let slug = this.getSlugFromRoute(match);
    this.lastLoadedSlug = slug;

    this.loading = true;
    const loadedData = {
      campaignStore: {
        lastLoadedSlug: this.lastLoadedSlug,
      },
      shopifyProductStore: {},
    };

    return this.requestCampaignData(match)
      .then(response => {
        loadedData.campaignStore.campaignData = response.items[0];

        return loadedData;
      })
      .catch(error => {
        console.log('CampaignStore preload error', error); // eslint-disable-line no-console
      });
  }

  requestCampaignData(match) {
    const slug = this.getSlugFromRoute(match);
    this.lastLoadedSlug = slug;

    return contentful.getEntries('campaignPage', {
      'fields.slug': slug,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .then(response => {
        if (!response.items[0]) {
          throw new Error('No matching campaign data found');
        }

        return response;
      })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
        throw error;
      });
  }

  requestShopifyData(campaignData) {
    if (campaignData) {
      const productsOnShopify = (campaignData.fields.products || []).filter(product =>
        getField(product, 'shopifyHandle') !== undefined
      );
      const promises = productsOnShopify.map(product =>
        this.shopifyProductStore.loadShopifyProduct(getField(product, 'shopifyHandle'))
      );

      return Promise.all(promises);
    } else {
      return new Promise(resolve => resolve(undefined));
    }
  }

  /**
   * Load a campaign by it's slug
   * @param {string} slug The slug to use when loading
   * @param {object} match The matching route object
   */
  @action('Load a campaign')
  loadCampaignData(match) {
    const slug = this.getSlugFromRoute(match);
    if (slug !== this.lastLoadedSlug) {
      this.loading = true;
      this.notFound = false;
      this.lastLoadedSlug = slug;
      // Clear previous campaign to ensure it doesn't show up when going straight from
      // campaign page to campaign page
      this.campaignData = undefined;
      this.requestCampaignData(match)
        .then(response => this.onCampaignLoaded(response.items[0], slug))
        .then(() => this.loadShopifyData(this.campaignData))
        .catch(error => runInAction(() => {
          console.log(error); //eslint-disable-line no-console
          this.notFound = true;
        }));
    } else if (!this.shopifyDataLoaded) {
      this.loadShopifyData(this.campaignData);
    }
  }

  /**
   * Load all Shopify data for a campaign
   * @param {object} campaignData Campaign data loaded from Contentful
   */
  @action('Load Shopify data for a campaign')
  loadShopifyData(campaignData) {
    this.requestShopifyData(campaignData)
      .then(() => this.onShopifyDataLoaded());
  }

  @action('Updating pending status')
  setPendingStatus(value) {
    this.pending = value;
  }

  @action('Updating success status')
  setSuccessStatus(value) {
    this.success = value;
  }

  @action('Updating error message')
  setErrorMessage(message) {
    this.errorMessage = message;
  }

  @action('Getting subnav sections')
  gatherSubnavSections() {
    const pageSections = getField(this.campaignData, 'pageSections');
    this.subnavSections = [];
    (pageSections || []).forEach(section => {
      const deepLinkId = getField(section, 'deepLinkId');
      if (deepLinkId) {
        const subsectionsField = getField(section, 'subsections');
        const subsections = subsectionsField
          ? this.gatherSubsectionNames(subsectionsField)
          : undefined;
        this.subnavSections.push({
          deepLinkId,
          subsections,
        });
      }
    });
  }

  gatherSubsectionNames(subsectionsField) {
    return subsectionsField.map((section, index) => (
      {
        index,
        name: getField(section, 'subnavCopy'),
      }
    ));
  }

  // TODO: Kill this off when a proper sticky subnav solution has been added
  containsQAndASection() {
    let containsQAndASection = false;
    (getField(this.campaignData, 'pageSections') || []).forEach(section => {
      if (section.sys.contentType.sys.id === 'qaVideoAccordion') containsQAndASection = true;
    });

    return containsQAndASection;
  }

  @action('Shopify data for a campaign load successful')
  onShopifyDataLoaded() {
    this.loading = false;
    this.shopifyDataLoaded = true;
  }

  @action('Successfully loaded campaign data')
  onCampaignLoaded(campaignData, slug) {
    // Ensure this is the last requested slug. This ensures the correct campaign is shown despite fast clicking between campaign pages
    if (!slug || slug === this.lastLoadedSlug) {
      this.campaignData = campaignData;
      this.loading = false;
      this.siteMetaStore.setPageMetadata(getField(this.campaignData, 'pageMetadata.fields'), getField(this.campaignData, 'title'));
      // Check if this page has the Q&A accordion (ie. Start Making It)
      // This page uses the sidenav, others don't. This needs to be done in a better way
      // TODO: Potentially as part of the re-usable StickySubnav component
      if (this.containsQAndASection()) {
        this.gatherSubnavSections();
      }

      (getField(this.campaignData, 'products') || []).forEach(product => {
        if (getField(product, 'displayRegion') && !getField(product, 'displayRegion').includes(this.siteUIStore.regionData.REGION)){
          product.fields.promoBannerLightbox = undefined;
          product.fields.promoBannerOverlay = undefined;
          product.fields.promoBannerText = undefined;
        }

        if (getField(product, 'bundles')) {
          const bundleOptions = getField(product, 'bundles');
          const variantField = `variantIDs_${this.siteUIStore.regionData.REGION}`;
          const priceField = `price_${this.siteUIStore.regionData.REGION}`;
          const comparePriceField = `comparePrice_${this.siteUIStore.regionData.REGION}`;

          product.fields.bundles = bundleOptions.filter((option) => {
            return getField(option, variantField) && getField(option, priceField);
          }).map((option) => {
            return {
              id: getField(option, variantField).join(','),
              title: getField(option, 'title'),
              price: getField(option, priceField) * 100,
              compare_at_price: getField(option, comparePriceField) * 100,
              available: getField(option, 'available'),
            };
          });

          if (product.fields.bundles.length == 0) {
            product.fields.bundles = undefined;
          }
        }
      });
    }
  }

  @action('Preloaded campaign data found')
  onPreloadedDataFound(preloadedData) {
    this.lastLoadedSlug = preloadedData.campaignStore.lastLoadedSlug;
    this.loading = false;
    this.onCampaignLoaded(preloadedData.campaignStore.campaignData);
  }
}

export default CampaignStore;
