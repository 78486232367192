import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';
import { isInternal, makeRelative, stripTrailingSlash } from 'utils/linkUtils';

import contentful from 'services/contentful';
import { HEADER_ID } from 'constants/contentful';

/**
 * Loads the tranlatable content shown in the header
 * @class
 * @param {object} preloadedData Object containing the data loaded server-side
 */
class SiteHeaderStore {

  INCLUDE_REFERENCE_DEPTH = 2;

  @observable headerContent = undefined;
  @observable globalBannerData = {};
  @observable mainNavOptions = [];

  constructor(preloadedData) {
    if (preloadedData && preloadedData.siteHeaderStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(locale) {
    contentful.setLocale(locale);

    return this.requstHeaderContent()
      .then(response => ({
        siteHeaderStore: {
          headerData: response.items[0],
        },
      }));
  }

  /**
   * Returns an array of subnav items that belong to a family
   * @param {string} slug The slug for the nav item
   */
  getSubnavItems(slug) {
    slug = slug.replace('store/', '');
    const matchingItem = this.mainNavOptions.filter(item => {
      const matchingFamily = this.isFamilyStorePage(item.slug, slug);

      return matchingFamily;
    })[0];

    return matchingItem ? matchingItem.subnavItems : [];
  }

  isFamilyStorePage(itemSlug, currentSlug) {
    const itemFamily = this.getFamilyFromSlug(itemSlug);
    const currentFamily = this.getFamilyFromSlug(currentSlug);

    return itemFamily === currentFamily;
  }

  getFamilyFromSlug(slug) {
    let family = stripTrailingSlash(slug).replace('/products/', '');
    if (family[0] === '/') family = family.replace('/', '');

    return family.split('/')[0];
  }

  requstHeaderContent() {
    return contentful.getEntries('siteHeader', {
      'sys.id': HEADER_ID,
      'limit': 1,
      'include': this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load links and copy to be shown in the header
   */
  loadHeaderContent() {
    if (!this.headerContent) {
      return this.requstHeaderContent()
        .then(response => this.onHeaderContentLoaded(response.items[0]));
    }
  }

  @action('Successfully loaded header content')
  onHeaderContentLoaded(headerData) {
    this.headerContent = headerData.fields;
    //this.mainNavLinks = getField(headerData, 'mainNavLinks');
    this.mainNavOptions = getField(headerData, 'mainNavigation').map(navOption => {
      let url = getField(navOption, 'link.fields.url');
      const internal = isInternal(url);
      if (internal) url = makeRelative(url);

      const subnavItems = getField(navOption, 'subnavItems');
      const updatedSubnavItems = [];
      (subnavItems || []).forEach(item => {
        const contentType = item.sys.contentType.sys.id;
        item.contentType = contentType;
        updatedSubnavItems.push(item);
      });

      return {
        name: getField(navOption, 'link.fields.title'),
        slug: url,
        subnavItems: updatedSubnavItems,
      };
    });
    this.globalBannerData = {
      'text': this.headerContent.globalBannerText,
      'label': this.headerContent.globalBannerCtaLabel,
      'url': this.headerContent.globalBannerCtaUrl,
    };
  }

  @action('Preloaded header content found')
  onPreloadedDataFound(preloadedData) {
    this.onHeaderContentLoaded(preloadedData.siteHeaderStore.headerData);
  }
}

export default SiteHeaderStore;
