import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './MessageBubble.styl';

import { isInternal, makeRelative } from 'utils/linkUtils';

import CloseButton from 'components/common/buttons/CloseButton';

class MessageBubble extends Component {

  constructor(props) {
    super(props);
    this.bubbleElement = undefined;
  }

  render() {
    const {
      visible,
      title,
      linkLabel,
      linkUrl,
      fixed,
      unfixedPosition,
      onCloseClicked,
    } = this.props;

    const internalLink = isInternal(linkUrl);
    const LinkComponent = internalLink ? Link : 'a';
    const parsedUrl = internalLink ? makeRelative(linkUrl) : linkUrl;
    const linkProps = {
      className: styles.link,
      onClick: onCloseClicked,
    };
    if (internalLink) {
      linkProps.to = parsedUrl;
    } else {
      linkProps.href = parsedUrl;
      linkProps.target = '_blank';
    }

    const bubbleClasses = classNames({
      [styles.messageBubble]: true,
      [styles.messageBubbleHidden]: !visible,
    });
    const bubbleContentClasses = classNames({
      [styles.messageBubbleContent]: true,
      [styles.messageBubbleContentHidden]: !visible,
    });

    const bubbleHeight = this.bubbleElement ? this.bubbleElement.clientHeight : 0;

    return (
      <aside
        className={bubbleClasses}
        ref={element => this.bubbleElement = element}
        style={{
          position: fixed ? 'fixed': 'absolute',
          top: fixed ? 'auto' : unfixedPosition - bubbleHeight,
          bottom: fixed ? 0 : 'auto',
        }}
      >
        <div className={bubbleContentClasses}>
          <div className={styles.closeButtonContainer}>
            <CloseButton
              className={styles.closeButton}
              small={true}
              onButtonClicked={onCloseClicked}
            />
          </div>
          {title && <h2 className={styles.title}>{title}</h2>}
          {(linkLabel && linkUrl) &&
            <LinkComponent {...linkProps}>{linkLabel}</LinkComponent>
          }
        </div>
      </aside>
    );
  }
}

MessageBubble.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  fixed: PropTypes.bool,
  unfixedPosition: PropTypes.number,
  onCloseClicked: PropTypes.func.isRequired,
};

MessageBubble.defaultProps = {
  unfixedPosition: 0,
  fixed: true,
};

export default withStyles(styles)(MessageBubble);
