import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobXPropTypes } from 'mobx-react';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './CartPanelContent.styl';

import { ROLI_COM_URL } from 'constants/roli';
import { makeRelative } from 'utils/linkUtils';

import CartItem from 'components/apps/cartPreviewPanel/CartItem';
import ShopButton from 'components/common/buttons/ShopButton';

const CartPanelContent = ({
  shopifyProducts,
  outsideApp,
  totalItems,
  subtotal,
  onRemoveButtonClicked,
  onCheckoutClicked,
}) => {
  const productList = shopifyProducts.map((product, i) => (
    <CartItem
      product={product}
      key={i}
      onRemoveButtonClicked={onRemoveButtonClicked}
      lineIndex={i} />
  ));

  const cartUrl = `${ROLI_COM_URL}/cart`;

  return totalItems
    ? <div className={styles.cartContent}>
      <h2 className={styles.title}>You have {totalItems} items in your bag</h2>
      {productList}
      <dl className={styles.subtotal}>
        <dt className={styles.subtotalTitle}>Subtotal:</dt>
        <dd className={styles.subtotalValue}>{subtotal}</dd>
      </dl>
      <div className={styles.cartOptions}>
        <ShopButton
          to={outsideApp ? cartUrl : makeRelative(cartUrl)}
          label={'View full cart'}
          onClicked={onCheckoutClicked}
          target={outsideApp ? '_self' : undefined}
        />
      </div>
    </div>
    : <h2 className={styles.emptyCartMessage}>Your cart is empty</h2>;
};

CartPanelContent.propTypes = {
  outsideApp: PropTypes.bool,
  shopifyProducts: MobXPropTypes.arrayOrObservableArray,
  totalItems: PropTypes.number,
  subtotal: PropTypes.string,
  onRemoveButtonClicked: PropTypes.func.isRequired,
  onCheckoutClicked: PropTypes.func.isRequired,
};

export default withStyles(styles)(CartPanelContent);
