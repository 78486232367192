import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ROLI_COM_URL } from 'constants/roli';
import { isInternal, makeRelative } from 'utils/linkUtils';

const TextLink = ({ outsideApp, label, to, className, target }) => {
  to = (outsideApp && to.charAt(0) === '/') ? `${ROLI_COM_URL}${to}` : to;
  const internal = isInternal(to) && !outsideApp;
  const attrs = {
    className,
    title: label,
  };
  if (internal) {
    attrs.to = makeRelative(to);
  } else {
    attrs.href = to;
    attrs.target = target || '_self';
  }

  const Component = internal ? Link : 'a';

  return (
    <Component {...attrs}>{label}</Component>
  );
};

TextLink.propTypes = {
  outsideApp: PropTypes.bool,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  target: PropTypes.string,
};

export default TextLink;
