import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './CartPreviewWrapper.styl';

const CartPreviewWrapper = ({ open, children }) => {
  const classes = classNames({
    [styles.cartPreviewWrapper]: true,
    [styles.cartPreviewWrapperOpen]: open,
  });

  return (
    <li className={classes}>
      {children}
    </li>
  );
};

CartPreviewWrapper.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
};

export default withStyles(styles)(CartPreviewWrapper);
