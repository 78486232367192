import { getField } from 'utils/contentfulUtils';
import { getRegionData } from 'utils/shopifyUtils';
import ShopifyProductStore from 'stores/domain/ShopifyProductStore';
import contentful from 'services/contentful';

/**
 * Loads and manages product catalogue data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class ProductCatalogueStore {

  INCLUDE_REFERENCE_DEPTH = 2;

  shopifyProductStore = undefined;

  constructor() {
    this.shopifyProductStore = new ShopifyProductStore();
  }

  __preloadServerSideData(match, locale, location, regionName) {
    contentful.setLocale(locale);

    this.shopifyCurrency = getRegionData(regionName).CURRENCY_CODE;

    const loadedData = {
      productCatalogueStore: {},
      shopifyProductStore: {},
      shopifyCurrency: this.shopifyCurrency,
    };

    return this.requestProducts()
      .then(response => {
        loadedData.productCatalogueStore.loadedProducts = response.items;
      })
      .then(() => {
        if (loadedData.productCatalogueStore.loadedProducts) {
          return this.requestShopifyData(loadedData.productCatalogueStore.loadedProducts);
        }
      })
      .then(() => {
        loadedData.shopifyProductStore.loadedProducts = this.shopifyProductStore.loadedProducts;

        return loadedData;
      })
      .catch(error => {
        console.log('ProductCatalogueStore preload error', error); // eslint-disable-line no-console
      });
  }

  requestProducts() {
    return contentful.getEntries('product', {
      limit: 50,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .then(response => {
        if (!response.items[0]) {
          throw new Error('No products found');
        }

        return response;
      })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
        throw error;
      });
  }

  requestShopifyData(products) {
    if (products) {
      const productsOnShopify = (products || []).filter(product =>
        getField(product, 'shopifyHandle') !== undefined
      );
      const promises = productsOnShopify.map(product =>
        this.shopifyProductStore.loadShopifyProduct(getField(product, 'shopifyHandle'))
      );

      return Promise.all(promises);
    } else {
      return new Promise(resolve => resolve(undefined));
    }
  }

}

export default ProductCatalogueStore;
