exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteHeader_FloatingPanel_panelWrapper_2m1Md{position:absolute;top:100%;right:-84px;z-index:50}@media (min-width:544px){.siteHeader_FloatingPanel_panelWrapper_2m1Md{right:-50px}}.siteHeader_FloatingPanel_smallPanel_1Bqtx{right:-84px}@media (min-width:544px){.siteHeader_FloatingPanel_smallPanel_1Bqtx{right:-10px}}.siteHeader_FloatingPanel_panel_20zma{display:block;width:calc(100vw - 32px);padding:22px 14px 24px;color:#545559;text-align:left;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:0 0 8px 8px;background:#fafafa;-webkit-box-shadow:0 0 5px 0 rgba(83,85,88,.1);box-shadow:0 0 5px 0 rgba(83,85,88,.1)}@media (min-width:544px){.siteHeader_FloatingPanel_panel_20zma{width:408px}}.siteHeader_FloatingPanel_smallPanel_1Bqtx .siteHeader_FloatingPanel_panel_20zma{width:calc(100vw - 32px)}@media (min-width:544px){.siteHeader_FloatingPanel_smallPanel_1Bqtx .siteHeader_FloatingPanel_panel_20zma{width:300px}}.siteHeader_FloatingPanel_panelContent_8bnKG{max-height:60vh;padding:0 10px;overflow:auto}.siteHeader_FloatingPanel_panelContent_8bnKG::-webkit-scrollbar{width:8px}.siteHeader_FloatingPanel_panelContent_8bnKG::-webkit-scrollbar-thumb{background:#e8e8e9;border-radius:4px}.siteHeader_FloatingPanel_panelContent_8bnKG::-webkit-scrollbar-thumb:active{background:#b8b9bb}.siteHeader_FloatingPanel_panelEnter__6YXc{opacity:0}.siteHeader_FloatingPanel_panelEnterActive_1aaPX{opacity:1;-webkit-transition:opacity .1s linear;transition:opacity .1s linear}.siteHeader_FloatingPanel_panelLeave_2UpwA{opacity:1}.siteHeader_FloatingPanel_panelLeaveActive_15S8V{opacity:0;-webkit-transition:opacity .1s linear;transition:opacity .1s linear}", ""]);

// exports
exports.locals = {
	"panelWrapper": "siteHeader_FloatingPanel_panelWrapper_2m1Md",
	"smallPanel": "siteHeader_FloatingPanel_smallPanel_1Bqtx",
	"panel": "siteHeader_FloatingPanel_panel_20zma",
	"panelContent": "siteHeader_FloatingPanel_panelContent_8bnKG",
	"panelEnter": "siteHeader_FloatingPanel_panelEnter__6YXc",
	"panelEnterActive": "siteHeader_FloatingPanel_panelEnterActive_1aaPX",
	"panelLeave": "siteHeader_FloatingPanel_panelLeave_2UpwA",
	"panelLeaveActive": "siteHeader_FloatingPanel_panelLeaveActive_15S8V"
};