import urlParse from 'url-parse';

export const YOUTUBE = 'youtube';

export const getYoutubeIdFromUrl = (url) => {
  const urlData = urlParse(url, true);
  const pattern = new RegExp('youtu(be.com|.be)', 'i');
  if (pattern.test(urlData.host) &&
    urlData.query.v) {
    return urlData.query.v;
  }

  /* eslint-disable no-console */
  console.error(new Error('Given URL does not contain a YouTube video ID'));
  /* eslint-enable no-console */

  return undefined;
};

export const getYoutubeStartTimeFromUrl = (url) => {
  const urlData = urlParse(url, true);
  const pattern = new RegExp('youtu(be.com|.be)', 'i');
  if (pattern.test(urlData.host) &&
    urlData.query.t) {
    return urlData.query.t;
  }

  return '0s';
};

export const getVimeoIdFromUrl = (url) => {
  const urlData = urlParse(url, true);
  const path = urlData.pathname.replace(/\//g, '');
  const queryStart = path.indexOf('?');
  const id = (queryStart >= 0) ? path.substring(0, queryStart-1) : path;
  if (id) return id;

  return undefined;
};

// We only serve up YouTube videos at the time of writing, but this will likely change
export const getVideoType = (url) => {
  if (url.indexOf('youtube.com') > -1 || url.indexOf('youtu.be') > -1) {
    return YOUTUBE;
  }

  return undefined;
};
