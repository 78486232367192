import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './SelectField.styl';

const SelectField = observer(({
  field,
  id,
  placeholder,
  className,
  wrapperClass,
  onChange,
  ...props
}) => {
  const wrapperClasses = classNames({
    [styles.selectWrapper]: true,
    [wrapperClass]: wrapperClass,
  });

  const selectClasses = classNames({
    [styles.selectField]: true,
    [styles.hasLabel]: placeholder,
    [className]: !!className,
  });

  return (
    <div className={wrapperClasses}>
      {placeholder && <label htmlFor={id} className={styles.label}>{placeholder}</label>}
      <select
        id={props.id}
        className={selectClasses}
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
        {...props}
      >
        {field.extra.map((option) =>
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        )}
      </select>
    </div>
  );
});

SelectField.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
};

export default withStyles(styles)(SelectField);
