import React from 'react';
import PropTypes from 'prop-types';

const RoliLogo = ({ className }) => (
  <svg className={className} version='1.1' viewBox='0 0 36 36' preserveAspectRatio='xMidYMid meet'>
    <title>ROLI</title>
    <path d='M24.5938965,25.5108 C24.4628439,25.2913263 24.2537912,25.1362737 24.0058965,25.0740632 C22.9031596,24.7974316 21.9191596,23.8544842 21.020107,22.8768 C20.6392649,22.4621684 20.1655807,21.9461684 19.6735807,21.4923789 L19.5782123,21.4049053 L19.700107,21.3606947 C22.1528439,20.4764842 23.8006333,18.1301684 23.8006333,15.5223789 C23.8006333,12.1361684 21.0820018,9.48385263 17.6114754,9.48385263 L14.1551596,9.48385263 C13.6274754,9.48385263 13.1980018,9.91301053 13.1973702,10.4403789 L13.1970544,10.4539579 C13.203686,10.9769053 13.6309491,11.4000632 14.1551596,11.4000632 L17.6114754,11.4000632 C20.0105281,11.4000632 21.8897912,13.2108 21.8897912,15.5223789 C21.8897912,17.8857474 19.9707386,19.8085895 17.6114754,19.8085895 L14.1551596,19.8085895 C13.6268439,19.8085895 13.1970544,20.2380632 13.1970544,20.7663789 C13.1970544,21.2946947 13.6268439,21.7244842 14.1551596,21.7244842 L16.0151596,21.7244842 C17.3626333,21.7244842 18.4565281,22.9150105 19.6148439,24.1753263 C20.684107,25.3386947 21.867686,26.5099579 23.5394754,26.9305895 C23.7870544,26.9921684 24.044107,26.9549053 24.2638965,26.8235368 C24.483686,26.6924842 24.6384228,26.4837474 24.7006333,26.2355368 C24.7631596,25.9876421 24.7252649,25.7302737 24.5938965,25.5108 M35.9929491,17.9031158 C35.9929491,27.7908 27.9775807,35.8061684 18.0898965,35.8061684 C8.20221228,35.8061684 0.18684386,27.7908 0.18684386,17.9031158 C0.18684386,8.01543158 8.20221228,6.31578946e-05 18.0898965,6.31578946e-05 C27.9775807,6.31578946e-05 35.9929491,8.01543158 35.9929491,17.9031158'></path>
  </svg>
);

RoliLogo.propTypes = {
  className: PropTypes.string,
};

export default RoliLogo;
