import { getEnvVar } from 'utils/envUtils';

export const SPACE_ID = 'k28kmvrzx80m';
export const ACCESS_TOKEN = 'a65823586edce0b36b99e3c706bed4e18564622d3bc174744cca4b451b34c35c';
export const ACCESS_TOKEN_PREVIEW = '099977aca3efdf7df1cf7a1ebae744d602e3e096646e2c888ea8fbeb169fca9c';

export const CONTENTFUL_API_MODE = getEnvVar('CONTENTFUL_API_MODE') || 'production';
export const CONTENTFUL_IMAGE_PROXY = getEnvVar('CONTENTFUL_IMAGE_PROXY') || undefined;
export const CONTENTFUL_JSON_PROXY = getEnvVar('CONTENTFUL_JSON_PROXY') || undefined;
export const CONTENTFUL_HOST = CONTENTFUL_JSON_PROXY || 'cdn.contentful.com';
export const CONTENTFUL_HOST_PREVIEW = 'preview.contentful.com';

export const HEADER_ID = '1bFuBkasIGAoWuEIsa64Qi';
export const FOOTER_ID = '31HcOp3x6UeKY68IIKWOMS';
export const HOMEPAGE_ID = '4H2rru6eBakouCm066cSmk';
export const GLOBAL_COPY_ID = '2yJ5I5jSeQeaA0agqm8yEm';
export const TEAM_PAGE_ID = '4ALTJfs7LaO6sISgKO6uWA';
export const ABOUT_PAGE_ID = '1p8g6XgWEY4YwKguM88aMq';
export const CONTACT_PAGE_ID = '1sRKNFy3VG6oCQuQoeycsA';
export const LEGAL_LANDING_PAGE_ID = '6niSuuTB96GKu06QQOqg2k';
export const CAREERS_PAGE_ID = '617lchttgkseuYoyEo46aO';
export const NOT_FOUND_PAGE_ID = '1tSzP2juao6gG8GYwwcKmG';
export const BLOG_LANDING_PAGE_ID = '3yFQkXNtraysqkA4ksEcoA';
export const CART_PAGE_ID = '2GglT924QwaAm0Mek44OWM';
export const LEARN_PAGE_ID = '1nljG6YcUkA4qoosusqwmm';
export const REGISTRATION_START_ID = 'gdiQCrhR044Gay6Qgu8Oa';
export const COMPETITION_PAGE_ID = '6GywaCk21asiim6smeK4Mm';
