import { observable, action } from 'mobx';

/**
 * Class that loads and stores meta data for the site
 * @class
 */
class SiteMetaStore {

  @observable metadata = null;
  @observable title = null;
  @observable isArticle = false;

  @action('Set page metadata')
  setPageMetadata(metadata, title, isArticle = false) {
    this.metadata = metadata;
    this.title = title;
    this.isArticle = isArticle;
  }
}

export default SiteMetaStore;
