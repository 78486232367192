import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';
import { stripTrailingSlash } from 'utils/linkUtils';

import contentful from 'services/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Loads and manages a text page's data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */

class TextPageStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable textPageData = undefined;
  @observable loading = true;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.textPageStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale, location, regionName, slug) {
    contentful.setLocale(locale);
    this.loading = true;

    return this.requestTextPage(slug)
      .then(response => ({
        textPageStore: {
          textPageData: response.items[0],
        },
      }))
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  @action('Load a text page by slug')
  loadTextPageBySlug(slug) {
    this.loading = true;
    this.textPageData = undefined;

    return this.requestTextPage(slug)
      .then(response => this.onTextPageLoadComplete(response.items[0]))
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  requestTextPage(slug) {
    // Strip trailing slash if one exists
    slug = stripTrailingSlash(slug);

    return contentful.getEntries('textPage', {
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
      'fields.slug': slug,
    });
  }

  @action('Successfully loaded a text page')
  onTextPageLoadComplete(textPageData) {
    this.textPageData = textPageData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.textPageData, 'pageMetadata.fields'), getField(this.textPageData, 'title'));
  }

  @action('Preloaded text page data found')
  onPreloadedDataFound(preloadedData) {
    this.onTextPageLoadComplete(preloadedData.textPageStore.textPageData);
  }
}

export default TextPageStore;
