import { observable, action, computed } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { getQueryParam } from 'utils/linkUtils';
import { BLOG_LANDING_PAGE_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Loads and manages paginated lists of blog posts
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class BlogListStore {

  INCLUDE_REFERENCE_DEPTH = 2;
  POSTS_PER_PAGE = 9;
  @observable blogPosts = [];
  @observable totalPages = 0;
  @observable currentPage = 0;
  @observable loading = true;
  @observable landingPageData = undefined;
  @observable featuredBlog = undefined;
  @observable tagLoaded = false;
  @observable previousQuery = undefined;
  activeTags = undefined;

  /**
   * Get the total number of posts loaded
   */
  @computed get totalPostsLoaded() {
    return this.blogPosts.length;
  }

  /**
   * Check if all pages have been loaded
   * @returns {boolean}
   */
  @computed get allPagesLoaded() {
    return (this.currentPage >= this.totalPages);
  }

  constructor(location, siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    if (location) this.activeTags = getQueryParam(location.search, 'tags');
    if (preloadedData && preloadedData.blogListStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale, location) {
    this.loading = true;
    const tags = getQueryParam(location.search, 'tags').toLowerCase();
    const loadedData = {
      blogListStore: {},
    };
    this.tagLoaded = (tags) ? true : false;

    return this.requestLandingPageData(location)
      .then(response => {
        this.onLandingPageDataLoaded(response.items[0]);
        loadedData.blogListStore.landingPageData = response.items[0];
      })
      .then(() => this.requestPage(tags))
      .then(response => {
        this.onFirstBlogPostsLoaded(response);
        loadedData.blogListStore.response = response;

        return loadedData;
      });
  }

  @action('Clear blog list data')
  resetPagination() {
    this.currentPage = 0;
    this.totalPages = 0;
    this.blogPosts = [];
  }

  @action('Load first page of blog posts')
  loadFirstPage(location) {
    this.resetPagination();

    // Don't reload the landing page data if we already have it
    return !this.landingPageData
      ? this.requestLandingPageData()
        .then(response => this.onLandingPageDataLoaded(response.items[0]))
        .then(() => this.loadPage(location))
      : this.loadPage(location);
  }

  @action('Load a page of blog posts')
  loadPage = (location) => {
    this.previousQuery = location.search;
    const newTags = getQueryParam(location.search, 'tags').toLowerCase();
    if (newTags !== this.activeTags || (newTags === this.activeTags && (!this.allPagesLoaded || this.currentPage === 0))) {
      this.loading = true;
      this.activeTags = getQueryParam(location.search, 'tags').toLowerCase();
      this.tagLoaded = (this.activeTags) ? true : false;

      return this.requestPage(this.activeTags)
        .then(response => {
          if (this.currentPage === 0) {
            this.onFirstBlogPostsLoaded(response);
          } else {
            this.onBlogPostsLoaded(response);
          }

          return response.items;
        })
        .catch(error => {
          console.log(error); // eslint-disable-line no-console
        });
    }
  }

  /**
   * Load the data found on the blog landing page
   */
  @action('Load blog landing page data')
  requestLandingPageData() {
    return contentful.getEntries('blogLandingPage', {
      'sys.id': BLOG_LANDING_PAGE_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the most recent blog posts
   */
  @action('Load recent blog posts')
  requestPage(tags) {
    if (this.currentPage === 0) this.resetPagination();

    return contentful.getEntries('blogPost', {
      limit: this.POSTS_PER_PAGE,
      skip: this.currentPage * this.POSTS_PER_PAGE,
      include: this.INCLUDE_REFERENCE_DEPTH,
      'fields.tags[match]': tags,
      order: '-fields.postDate',
      select: 'fields.title,fields.slug,fields.postDate,fields.headerImage,fields.tags',
    });
  }

  @action('Successfully loaded blog landing page data')
  onLandingPageDataLoaded(landingPageData) {
    this.landingPageData = landingPageData;
    this.featuredBlog = getField(this.landingPageData, 'featuredBlogPost');
    this.siteMetaStore.setPageMetadata(getField(landingPageData, 'pageMetadata.fields'), getField(landingPageData, 'title'));
  }

  @action('Successfully loaded first page of blog posts')
  onFirstBlogPostsLoaded(response) {
    this.totalPages = Math.ceil(response.total / this.POSTS_PER_PAGE);
    this.onBlogPostsLoaded(response);
  }

  @action('Successfully loaded a page of blog posts')
  onBlogPostsLoaded(response) {
    this.totalPages = Math.ceil(response.total / this.POSTS_PER_PAGE);
    const blogPosts = response.items;
    let filteredBlogPosts = [];
    if (!this.activeTags) {
      this.tagLoaded = false;
      // Filter out the featured blog post, and the two recent posts
      filteredBlogPosts = blogPosts.filter(post => {
        let duplicateFound = false;
        // Check the post isn't the featured post
        if (post.sys.id === this.featuredBlog.sys.id) {
          duplicateFound = true;
        }
        // Check the two recent posts
        (getField(this.landingPageData, 'recentBlogPosts') || []).forEach(recentPost => {
          if (post.sys.id === recentPost.sys.id) {
            duplicateFound = true;
          }
        });

        return !duplicateFound;
      });
    } else {
      this.tagLoaded = true;
      filteredBlogPosts = blogPosts;
    }
    this.blogPosts = this.blogPosts.concat(filteredBlogPosts);
    this.currentPage++;
    this.totalPosts = this.blogPosts.total;
    this.loading = false;
  }

  @action('Preloaded blog list data found')
  onPreloadedDataFound(preloadedData) {
    this.onLandingPageDataLoaded(preloadedData.blogListStore.landingPageData);
    this.onFirstBlogPostsLoaded(preloadedData.blogListStore.response);
  }
}

export default BlogListStore;
