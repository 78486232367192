import { observable, action } from 'mobx';

import contentful from 'services/contentful';
import { FOOTER_ID } from 'constants/contentful';
/**
 * Loads the tranlatable content shown in the footer
 * @class
 * @param {object} preloadedData Object containing the data loaded server-side
 */
class SiteFooterStore {

  INCLUDE_REFERENCE_DEPTH = 2;

  @observable.shallow footerContent = undefined;

  constructor(preloadedData) {
    if (preloadedData && preloadedData.siteFooterStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(locale) {
    contentful.setLocale(locale);

    return this.requestFooterContent()
      .then(response => ({
        siteFooterStore: {
          footerData: response.items[0],
        },
      }));
  }

  requestFooterContent() {
    return contentful.getEntries('siteFooter', {
      'sys.id': FOOTER_ID,
      'limit': 1,
      'include': this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  loadFooterContent() {
    if (!this.footerContent) {
      return this.requestFooterContent()
        .then(footerData => this.onFooterContentLoaded(footerData.items[0]));
    }
  }

  @action('Successfully loaded footer content')
  onFooterContentLoaded(footerData) {
    this.footerContent = footerData;
  }

  @action('Preloaded footer content found')
  onPreloadedDataFound(preloadedData) {
    this.onFooterContentLoaded(preloadedData.siteFooterStore.footerData);
  }

}

export default SiteFooterStore;
