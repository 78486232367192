import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';

/**
 * Loads and hands out translated strings to components
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class TranslationStore {

  @observable translations = undefined;
  @observable globalTranslations = {};
  @observable actionTranslations = {};
  @observable cartTranslations = {};
  @observable formTranslations = {};
  @observable learnTranslations = {};
  @observable tags = undefined;
  @observable tagArray = [];

  constructor(preloadedData) {
    if (preloadedData && preloadedData.translationStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(locale) {
    contentful.setLocale(locale);
    this.loading = true;

    return this.requestTranslations()
      .then(translations => ({
        translationStore: {
          translations: translations.items[0],
        },
      }));
  }

  requestTranslations() {
    return contentful.getEntries('translations', {
      limit: 1,
      include: 1,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  @action('Load global translations')
  loadTranslations() {
    this.loading = true;
    this.requestTranslations()
      .then(translations => this.onTranslationsLoaded(translations.items[0]));
  }

  @action('Updating array of all blog tags')
  generateTagArray() {
    const tagArray = [];
    Object.keys(this.tags).forEach(key => {
      if (key !== 'internalTitle') tagArray.push(this.tags[key]);
    });
    this.tagArray = tagArray;
  }

  translateTag(tag) {
    if (!tag) return undefined;

    return this.tags[tag.toLowerCase()];
  }

  @action('Translations load successful')
  onTranslationsLoaded(translations) {
    this.globalTranslations = getField(translations, 'globalTranslations.fields');
    this.actionTranslations = getField(translations, 'actionTranslations.fields');
    this.formTranslations = getField(translations, 'formTranslations.fields');
    this.learnTranslations = getField(translations, 'learnTranslations.fields');
    this.cartTranslations = getField(translations, 'cartTranslations.fields');
    this.tags = getField(translations, 'tags.fields');
    this.generateTagArray();
  }

  onPreloadedDataFound(preloadedData) {
    this.onTranslationsLoaded(preloadedData.translationStore.translations);
  }
}

export default TranslationStore;
