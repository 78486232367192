import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Loads and manages a single blog post
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class BlogPostStore {

  INCLUDE_REFERENCE_DEPTH = 2;

  @observable post = undefined;
  @observable lastLoadedSlug = undefined;
  @observable loading = true;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.blogPostStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);
    this.loading = true;
    this.lastLoadedSlug = match.params.slug;

    return this.requestBlogPost(match.params.slug)
      .then(response => ({
        blogPostStore: {
          postData: response.items[0],
          lastLoadedSlug: match.params.slug,
        },
      }))
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  @action('Load a blog post by slug')
  loadPostBySlug(slug) {
    if (slug !== this.lastLoadedSlug) {
      this.loading = true;
      this.lastLoadedSlug = slug;
      // Clear previous post to ensure it doesn't show up when going straight from
      // blog post to blog post
      this.post = undefined;

      return this.requestBlogPost(slug)
        .then(response => this.onBlogPostLoadComplete(response.items[0]))
        .catch(error => {
          console.log(error); // eslint-disable-line no-console
        });
    }
  }

  requestBlogPost(slug) {
    return contentful.getEntries('blogPost', {
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
      'fields.slug': slug,
    });
  }

  @action('Translating tags in loaded blog post')
  translateTags(post, tagDictionary) {
    const tags = (getField(post, 'tags') || []).map(tag => (
      tagDictionary[tag.toLowerCase()]
    ));
    post.fields.tags = tags;

    return post;
  }

  @action('Successfully loaded a blog post')
  onBlogPostLoadComplete(postData) {
    this.post = postData;
    this.loading = false;
    this.siteMetaStore.setPageMetadata(getField(this.post, 'pageMetadata.fields'), getField(this.post, 'title'), true);
  }

  @action('Preloaded blog post data found')
  onPreloadedDataFound(preloadedData) {
    this.lastLoadedSlug = preloadedData.blogPostStore.lastLoadedSlug;
    this.onBlogPostLoadComplete(preloadedData.blogPostStore.postData);
  }
}

export default BlogPostStore;
