export const takeNAroundIndex = (array, i, n) => {
  const output = [array[i]];
  const offsetToMaintainSign = Math.ceil(n / array.length) * array.length;
  for (let j = 1; j <= n; j++) {
    output.push(array[(offsetToMaintainSign + i + j) % array.length]);
    output.unshift(array[(offsetToMaintainSign + i - j) % array.length]);
  }

  return output;
};

export const getShuffledIndexes = (array) => {
  const numberArray = array.map((value, index) => index);
  let currentIndex = numberArray.length, tempValue, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    tempValue = numberArray[currentIndex];
    numberArray[currentIndex] = numberArray[randomIndex];
    numberArray[randomIndex] = tempValue;
  }

  return numberArray;
};
