import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './RoliLogo.styl';

import { ROLI_COM_URL } from 'constants/roli';

import RoliLockup from 'components/common/icons/RoliLockup';

const RoliLogo = ({ outsideApp }) => (
  <div className={styles.roliLogo}>
    {(!outsideApp)
      ? <Link to={'/'} className={styles.logoLink}>
        <RoliLockup className={styles.logo} />
      </Link>
      : <a href={ROLI_COM_URL} className={styles.logoLink}>
        <RoliLockup className={styles.logo} />
      </a>
    }
  </div>
);

RoliLogo.propTypes = {
  outsideApp: PropTypes.bool,
};

export default withStyles(styles)(RoliLogo);
