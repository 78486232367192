export const supportedLocaleCodes = [
  'en-US',
  'de',
  'es-US',
  'fr',
  'it',
  'ko',
  'zh-Hans',
];

export const supportedLocaleOptions = [
  {
    value: 'en-US',
    label: 'English',
  },
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'es-US',
    label: 'Español',
  },
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'it',
    label: 'Italiano',
  },
  {
    value: 'ko',
    label: '한국어',
  },
  {
    value: 'zh-Hans',
    label: '简体中文',
  },
];
