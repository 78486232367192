import { observable, action } from 'mobx';

import debounce from 'utils/debounce';

import { THROTTLE_DELAY } from 'constants/roli';

class SiteUIStore {

  @observable NODE_ENV = '';
  @observable runningOnServer = false;
  @observable fixedNavigationHeight = 0;
  @observable regionData = undefined;
  @observable countryCode = undefined;
  @observable isMobileDevice = false
  @observable isPhone = false;
  @observable windowWidth = 0;
  @observable windowHeight = 0;
  @observable siteHeaderHeight = 0;
  @observable siteFooterHeight = 0;
  @observable siteSubnavHeight = 0;
  @observable siteFooterOffsetTop = 0;
  @observable mobileSiteNavVisible = false;
  @observable pageScrollLocked = false;
  @observable activeVideoUrl = undefined;
  @observable headerBannerHeight = 0;
  @observable userData = undefined;
  resizeTimer = undefined;

  constructor(preloadedData) {
    this.NODE_ENV = process.env.NODE_ENV;
    if (preloadedData) {
      this.setRegionData(preloadedData.regionData);
      this.setCountryCode(preloadedData.countryCode);
      this.setUserData(preloadedData.userData);
    }
    if (typeof window !== 'undefined') {
      this.checkForMobileDevice();
      this.createListeners();
      this.updateDimensions();
    }
  }

  @action('Set the header slim banner height')
  setHeaderBannerHeight(value) {
    this.headerBannerHeight = value;
  }

  @action('Set scroll lock')
  setPageScrollLock(value) {
    this.pageScrollLocked = value;
  }

  @action('Set the active video url')
  setActiveVideoUrl(value) {
    this.activeVideoUrl = value;
  }

  @action('Check for mobile device')
  checkForMobileDevice() {
    this.isMobileDevice = (typeof navigator !== 'undefined' && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    this.isPhone = (typeof navigator !== 'undefined' && /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }

  createListeners() {
    window.addEventListener('resize', this.onWindowResized);
  }

  @action('Picking region data by regionName')
  setRegionData(regionData) {
    this.regionData = regionData;
  }

  setCountryCode(countryCode) {
    this.countryCode = countryCode;
  }

  @action('Update logged in state')
  setUserData(userData) {
    this.userData = userData;
  }

  @action
  updateDimensions = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = undefined;
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  @action('Updating site header height')
  updateSiteHeaderHeight = (mainNavHeight, subnavHeight, bannerHeight = 0) => {
    this.siteHeaderHeight = mainNavHeight + bannerHeight;
    this.siteSubnavHeight = subnavHeight;
  }

  @action('Updating site footer height')
  updateSiteFooterHeight = (footerHeight) => {
    this.siteFooterHeight = footerHeight;
  }

  @action('Updating site footer offset top')
  updateSiteFooterOffsetTop = (offsetTop) => {
    this.siteFooterOffsetTop = offsetTop;
  }

  @action('Updating mobile site nav visibility')
  updateMobileSiteNavVisibility = (visibility) => {
    this.mobileSiteNavVisible = visibility;
  }

  onWindowResized = debounce(() => {
    if (!this.resizeTimer) {
      this.resizeTimer = setTimeout(this.updateDimensions, 20);
    }
  }, THROTTLE_DELAY)
}

export default SiteUIStore;
