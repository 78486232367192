import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { REGISTRATION_START_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Class that loads and stores data for the start page - a page users are directed to after buying a product
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class RegistrationStartStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable pageData = undefined;
  @observable loading = false;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.registrationStartStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData() {
    this.loading = true;

    return this.requestPageData()
      .then(response => ({
        startPageStore: {
          pageData: response.items[0],
        },
      }));
  }

  requestPageData() {
    return contentful.getEntries('registrationStartPage', {
      'sys.id': REGISTRATION_START_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the start page data
   */
  @action('Load start page data')
  loadPageData() {
    if (!this.pageData) {
      this.loading = true;
      this.requestPageData()
        .then(pageData => this.onPageDataLoaded(pageData.items[0]));
    } else {
      this.onPageDataLoaded(this.pageData);
    }
  }

  @action('Successfully loaded start page data')
  onPageDataLoaded(pageData) {
    this.pageData = pageData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.pageData, 'pageMetadata.fields'), getField(this.pageData, 'title'));
  }

  @action('Preloaded start page data found')
  onPreloadedDataFound(preloadedData) {
    this.onPageDataLoaded(preloadedData.registrationStartStore.pageData);
  }
}

export default RegistrationStartStore;
