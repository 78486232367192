import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Loads and manages a single lesson
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class LearnLessonPageStore {

  INCLUDE_REFERENCE_DEPTH = 4;

  @observable.shallow lesson = undefined;
  @observable lastLoadedSlug = undefined;
  @observable loading = true;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.learnLessonPageStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);
    this.loading = true;
    this.lastLoadedSlug = match.params.slug;

    return this.requestLesson(match.params.slug)
      .then(response => ({
        learnLessonPageStore: {
          lessonData: response.items[0],
          lastLoadedSlug: match.params.slug,
        },
      }))
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  @action('Load a lesson by slug')
  loadLessonBySlug(slug) {
    if (slug !== this.lastLoadedSlug) {
      this.loading = true;
      this.lastLoadedSlug = slug;
      // Clear previous post to ensure it doesn't show up when going straight from
      // lesson to lesson
      this.lesson = undefined;

      return this.requestLesson(slug)
        .then(response => this.onLessonLoadComplete(response.items[0]))
        .catch(error => {
          console.log(error); // eslint-disable-line no-console
        });
    }
  }

  requestLesson(slug) {
    return contentful.getEntries('lesson', {
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
      'fields.slug': slug,
    });
  }

  @action('Successfully loaded a lesson')
  onLessonLoadComplete(lessonData) {
    this.lesson = lessonData;
    this.loading = false;
    this.siteMetaStore.setPageMetadata(getField(this.lesson, 'pageMetadata.fields'), getField(this.lesson, 'title'), true);
  }

  @action('Preloaded lesson data found')
  onPreloadedDataFound(preloadedData) {
    this.lastLoadedSlug = preloadedData.learnLessonPageStore.lastLoadedSlug;
    this.onLessonLoadComplete(preloadedData.learnLessonPageStore.lessonData);
  }
}

export default LearnLessonPageStore;
