import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withCookies, Cookies } from 'react-cookie';

import throttle from 'utils/throttle';
import { getField } from 'utils/contentfulUtils';

import { THROTTLE_DELAY, BREAKPOINTS } from 'constants/roli';

import MessageBubble from 'components/common/floatingMessages/MessageBubble';

@inject('siteUIStore') @observer
class FloatingMessage extends Component {

  constructor(props) {
    super(props);
    this.SHOW_DELAY = 3000;
    this.timer = undefined;
    this.state = {
      cookieFound: false,
      visible: false,
      timerStarted: false,
      bubbleFixed: true,
      unfixedPosition: 0,
    };
    this.linkedToCompetition = !!this.props.associatedCompetition;
    this.cookieName = this.linkedToCompetition ?
      `competition_popup_${getField(this.props.associatedCompetition, 'uniqueId')}_closed`
      :
      `popup_${this.props.id}_closed`;
  }

  checkCookie(cookies) {
    this.setState({
      cookieFound: !!cookies.get(this.cookieName),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cookies && !this.props.cookies) {
      this.checkCookie();
    }
  }

  componentDidMount() {
    if (this.props.siteUIStore.windowWidth > BREAKPOINTS.MOBILE) {
      this.initialiseTimer();
    }
    if (!this.props.siteUIStore.runningOnServer) {
      window.addEventListener('resize', this.onWindowScrolled);
      window.addEventListener('scroll', this.onWindowScrolled);
    }
    if (this.props.cookies) this.checkCookie(this.props.cookies);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    if (!this.props.siteUIStore.runningOnServer) {
      window.removeEventListener('resize', this.onWindowScrolled);
      window.removeEventListener('scroll', this.onWindowScrolled);
    }
  }

  initialiseTimer = () => {
    this.setState({
      timerStarted: true,
    });
    this.timer = setTimeout(this.show, this.SHOW_DELAY);
  }

  show = () => {
    this.setState({
      visible: true,
    });
  }

  onCloseClicked = () => {
    this.setState({
      visible: false,
    });

    // only set cookie if it's not got an associated competiton
    // otherwise set on successful submission
    if (!this.linkedToCompetition) {
      this.props.cookies.set(this.cookieName, true, {
        path: '/',
        maxAge: 30 * 86400, // Number of days * number of seconds in a day
      });
    }
  }

  onWindowScrolled = throttle(() => {
    // if on mobile initialise the timer on scroll
    if (!this.state.timerStarted) {
      this.initialiseTimer();
    }

    const footerTop = this.props.siteUIStore.siteFooterOffsetTop;
    // Note that we assume fixed position if scroll top is zero, in order to better handle
    // jumps in page height due to slow loading asynchronous content
    this.setState({
      bubbleFixed: (window.pageYOffset === 0 || (window.pageYOffset + window.innerHeight < footerTop)),
      unfixedPosition: footerTop,
    });
  }, THROTTLE_DELAY);

  render() {
    const { title, linkLabel, linkUrl, currentPath } = this.props;
    const excludedPaths = [
      '/cart',
      '/start-making-it',
      '/campaign/start-making-it',
    ];

    return (!this.state.cookieFound && excludedPaths.indexOf(currentPath) === -1)
      ? <MessageBubble
        visible={this.state.visible}
        fixed={this.state.bubbleFixed}
        unfixedPosition={this.state.unfixedPosition}
        title={title}
        linkLabel={linkLabel}
        linkUrl={linkUrl}
        onCloseClicked={this.onCloseClicked}
      />
      : null;
  }
}

FloatingMessage.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  siteUIStore: PropTypes.object,
  id: PropTypes.string,
  title: PropTypes.string,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  associatedCompetition: PropTypes.object,
  currentPath: PropTypes.string,
};

export default withCookies(FloatingMessage);
