import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './AccountButton.styl';

import { MY_ROLI_URL } from 'constants/roli';

import AccountIcon from 'components/common/icons/AccountIcon';

const AccountButton = ({ onButtonClicked }) => (
  <a
    href={MY_ROLI_URL}
    className={styles.accountButton}
    onClick={(e) => {
      e.preventDefault();
      onButtonClicked(e);
    }}
    target='_blank'
  >
    <AccountIcon className={styles.accountIcon} />
  </a>
);

AccountButton.propTypes = {
  onButtonClicked: PropTypes.func,
};

export default withStyles(styles)(AccountButton);
