exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteFooter_SocialLinks_socialLinks_F49rh{display:block;margin:0;padding:0}.siteFooter_SocialLinks_title_NSYAb{display:none;font-size:18px;line-height:32px;font-weight:700;margin-top:0;margin-bottom:8px;letter-spacing:0}@media (min-width:544px){.siteFooter_SocialLinks_title_NSYAb{display:block}}@media (min-width:1176px){.siteFooter_SocialLinks_title_NSYAb{font-size:24px}}.siteFooter_SocialLinks_links_3JewL{list-style:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;max-width:240px;margin:0 auto;padding:0}@media (min-width:544px){.siteFooter_SocialLinks_links_3JewL{margin:0}}.siteFooter_SocialLinks_icon_2_6ms svg{width:24px;height:24px;fill:#fafafa}.siteFooter_SocialLinks_link_161Kr{will-change:opacity;-webkit-transition:opacity .15s linear;transition:opacity .15s linear}.siteFooter_SocialLinks_link_161Kr:focus,.siteFooter_SocialLinks_link_161Kr:hover{outline:none;opacity:.5}", ""]);

// exports
exports.locals = {
	"socialLinks": "siteFooter_SocialLinks_socialLinks_F49rh",
	"title": "siteFooter_SocialLinks_title_NSYAb",
	"links": "siteFooter_SocialLinks_links_3JewL",
	"icon": "siteFooter_SocialLinks_icon_2_6ms",
	"link": "siteFooter_SocialLinks_link_161Kr"
};