import { getEnvVar } from 'utils/envUtils';

export const REGIONS = {
  STAGING: 'staging',
  UK: 'uk',
  EU: 'eu',
  GLOBAL: 'global',
};

export const STAGING_AUTH = {
  REGION: 'staging',
  ACCESS_TOKEN: getEnvVar('STAGING_ACCESS_TOKEN') || '0a35b1aea7a84655f5e5037367bdf601',
  DOMAIN: 'staging1.store.roli.com',
  APP_ID: '6',
  CURRENCY_CODE : 'EUR',
  CURRENCY_SYMBOL: '€',
  TAX_RATE: 0.2,
  TAX_INCLUDED: true,
};

export const UK_AUTH = {
  REGION: 'uk',
  ACCESS_TOKEN: getEnvVar('UK_ACCESS_TOKEN') || '5ea319bb167b397dc9b2232e87e2146c',
  DOMAIN: 'uk1.store.roli.com',
  APP_ID: '6',
  CURRENCY_CODE : 'GBP',
  CURRENCY_SYMBOL: '£',
  TAX_RATE: 0.2,
  TAX_INCLUDED: true,
};

export const EU_AUTH = {
  REGION: 'eu',
  ACCESS_TOKEN: getEnvVar('EU_ACCESS_TOKEN') || '9185628f7de06cf426a8d71e95b705a6',
  DOMAIN: 'eu1.store.roli.com',
  APP_ID: '6',
  CURRENCY_CODE : 'EUR',
  CURRENCY_SYMBOL: '€',
  TAX_RATE: 0.2,
  TAX_INCLUDED: true,
};

export const GLOBAL_AUTH = {
  REGION: 'global',
  ACCESS_TOKEN: getEnvVar('GLOBAL_ACCESS_TOKEN') || '19dd7fe5834ff560f13371e63ceeb266',
  DOMAIN: 'global1.store.roli.com',
  APP_ID: '6',
  CURRENCY_CODE : 'USD',
  CURRENCY_SYMBOL: '$',
  TAX_RATE: 0.2,
  TAX_INCLUDED: false,
};

export const REGION_CONFIGS = [
  UK_AUTH,
  EU_AUTH,
  GLOBAL_AUTH,
];

export const GBP_STORE_COUNTRIES = [
  'GB',
  'GG',
  'JE',
];

export const EUR_STORE_COUNTRIES = [
  'AT',
  'BA',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'EU',
  'FI',
  'FR',
  'GI',
  'GR',
  'HR',
  'HU',
  'IE',
  'IM',
  'IS',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'ME',
  'MK',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RS',
  'SE',
  'SI',
  'SK',
  'TR',
  'UA',
  'VA',
];
