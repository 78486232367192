import {
  REGIONS,
  STAGING_AUTH,
  UK_AUTH,
  EU_AUTH,
  GLOBAL_AUTH,
} from 'constants/shopify';

export const getRegionData = (regionName) => {
  switch(regionName) {
    case REGIONS.STAGING:
      return STAGING_AUTH;
    case REGIONS.UK:
      return UK_AUTH;
    case REGIONS.EU:
      return EU_AUTH;
    default:
      return GLOBAL_AUTH;
  }
};

export const getSelectedVariant = (shopifyProduct, selectedVariantId) => {
  if (!shopifyProduct) return undefined;
  let selectedVariant = undefined;

  if (selectedVariantId && shopifyProduct.variants) {
    selectedVariant = getSelectedVariantById(shopifyProduct.variants, selectedVariantId);
    selectedVariant.handle = shopifyProduct.handle;

    return selectedVariant;
  }
  if (shopifyProduct.selectedVariant) {
    selectedVariant = shopifyProduct.selectedVariant;
    selectedVariant.handle = shopifyProduct.handle;

    return selectedVariant;
  }

  if (shopifyProduct.variants.length) {
    const selectedVariant = shopifyProduct.variants[0];
    selectedVariant.handle = shopifyProduct.handle;

    return selectedVariant;
  }

  return undefined;
};

export const getSelectedVariantById = (variants, selectedId) => (
  variants.filter((variant) => (variant.id == selectedId))[0]
);

export const getSelectedBundle = (bundles, selectedId) => {
  if (!bundles) return undefined;
  let selectedBundle = undefined;

  if (selectedId && bundles) {
    selectedBundle = getSelectedBundleById(bundles, selectedId);

    return selectedBundle;
  }

  if (bundles.length) {
    selectedBundle = bundles[0];

    return selectedBundle;
  }

  return undefined;
};

export const getSelectedBundleById = (bundles, selectedId) => (
  bundles.filter((bundle) => (bundle.id == selectedId))[0]
);
