import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import globalStyles from 'globalStyles';
import styles from './CartItem.styl';

import RemoveFromCartButton from 'components/common/buttons/RemoveFromCartButton';

const CartItem = ({ product, onRemoveButtonClicked, lineIndex }) => {
  return (
    <article className={styles.cartItem}>
      <div className={styles.imageWrapper}>
        <img src={product.image} alt={product.title} className={styles.productImage} />
      </div>
      <div className={styles.productDetails}>
        <h2 className={styles.title}>{product.title}</h2>
        <dl className={styles.details}>
          <dt className={globalStyles.hidden}>Quantity</dt>
          <dd className={styles.quantity}>x{product.quantity}</dd>
          <dt className={globalStyles.hidden}>Price per item</dt>
          <dd className={styles.pricePerItem}> @ {product.per_price_formatted_with_symbol}</dd>
        </dl>
      </div>
      <div className={styles.removeButtonWrapper}>
        <RemoveFromCartButton
          label={'Remove'}
          small={true}
          onButtonClicked={onRemoveButtonClicked}
          lineIndex={lineIndex}
        />
      </div>
    </article>
  );
};

CartItem.propTypes = {
  product: PropTypes.object.isRequired,
  onRemoveButtonClicked: PropTypes.func.isRequired,
  lineIndex: PropTypes.number.isRequired,
};

export default withStyles(globalStyles, styles)(CartItem);
