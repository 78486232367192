exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".siteFooter_RoliLogo_roliLogo_1Ol8m{-webkit-box-sizing:border-box;box-sizing:border-box;min-width:0;padding-left:8px;padding-right:8px;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;max-width:100%;text-align:center;margin-bottom:32px}@media (min-width:544px){.siteFooter_RoliLogo_roliLogo_1Ol8m{padding-left:12px;padding-right:12px;-webkit-box-sizing:border-box;box-sizing:border-box;min-width:0;padding-left:8px;padding-right:8px;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-basis:25%;-ms-flex-preferred-size:25%;flex-basis:25%;max-width:25%;-webkit-box-ordinal-group:2;-webkit-order:1;-ms-flex-order:1;order:1}}@media (min-width:850px){.siteFooter_RoliLogo_roliLogo_1Ol8m{-webkit-box-sizing:border-box;box-sizing:border-box;min-width:0;padding-left:8px;padding-right:8px;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-basis:16.666666666666664%;-ms-flex-preferred-size:16.666666666666664%;flex-basis:16.666666666666664%;max-width:16.666666666666664%;text-align:left}}@media (min-width:544px) and (min-width:544px){.siteFooter_RoliLogo_roliLogo_1Ol8m{padding-left:12px;padding-right:12px}}@media (min-width:850px) and (min-width:544px){.siteFooter_RoliLogo_roliLogo_1Ol8m{padding-left:12px;padding-right:12px}}.siteFooter_RoliLogo_logo_L56bl{width:100%;max-width:72px;fill:#fafafa}.siteFooter_RoliLogo_logoLink_3Yusv:focus{outline:none}.siteFooter_RoliLogo_logoLink_3Yusv:focus .siteFooter_RoliLogo_logo_L56bl{fill:#888b8d}", ""]);

// exports
exports.locals = {
	"roliLogo": "siteFooter_RoliLogo_roliLogo_1Ol8m",
	"logo": "siteFooter_RoliLogo_logo_L56bl",
	"logoLink": "siteFooter_RoliLogo_logoLink_3Yusv"
};