import { getEnvVar } from 'utils/envUtils';

// Links
export const DEFAULT_LOCALE = getEnvVar('DEFAULT_LOCALE') || 'en-US';
export const ROLI_COM_URL = getEnvVar('ROLI_COM_URL') || 'https://roli.com';
export const SUPPORT_SITE_URL = getEnvVar('SUPPORT_SITE_URL') || 'https://support.roli.com';
export const ROLI_STORES_URL = 'https://roli.com/stores/map';
export const MY_ROLI_URL = 'https://my.roli.com';
export const AUTH_URL = getEnvVar('AUTH_URL') || 'https://auth.roli.com';
export const STORE_LOCATOR_URL = 'https://legacy.roli.com/stores/map';

// Interactions
export const DURATIONS = {
  SLIDESHOW_SLIDE: 8000,
  CONTENT_EXPAND: 500,
  CONTENT_FADE: 500,
};

export const INFINITE_SCROLL_TRIGGER = 200;

// Images
export const RESPONSIVE_IMAGE_SIZES = [544, 850, 1176, 2400];

export const SLIDE_MINIMUM_HEIGHT = 500;

export const ASPECT_RATIOS = {
  VIDEO_HEIGHT_TO_WINDOW_WIDTH: 0.5625, // 16:9 ratio
  SLIDE_HEIGHT_TO_WINDOW_HEIGHT: 0.7,
};

export const THROTTLE_DELAY = 100;
export const SCROLL_THROTTLE_DELAY = 20;

export const BREAKPOINTS = {
  MOBILE: 550,
  TABLET: 850,
  DESKTOP: 1176,
};

export const HEADER_HEIGHT = 52;
