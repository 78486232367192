import { observable, action, computed, runInAction } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import SiteMetaStore from 'stores/domain/SiteMetaStore';

import contentful from 'services/contentful';

/**
 * Loads and manages storefront page data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class StorefrontPageStore {

  INCLUDE_REFERENCE_DEPTH = 3;

  @observable.shallow storefrontPageData = undefined;
  @observable lastLoadedSlug = undefined;
  @observable loading = true;
  @observable notFound = false;
  requestedSlug = false;
  shopifyProductStore = undefined;

  @computed get fields() {
    return this.storefrontPageData ? this.storefrontPageData.fields : undefined;
  }

  constructor(shopifyProductStore, siteMetaStore, preloadedData) {
    this.shopifyProductStore = shopifyProductStore;
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();
    // Store receives preloadedData when the server-side render completes
    if (preloadedData) {
      if (preloadedData.storefrontPageStore) {
        this.onPreloadedDataFound(preloadedData);
      }
    }
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);
    const slug = match.params.family ? `${match.params.family}/${match.params.slug}` : match.params.slug;
    this.lastLoadedSlug = slug;
    this.loading = true;
    const loadedData = {
      storefrontPageStore: {
        lastLoadedSlug: this.lastLoadedSlug,
      },
      shopifyProductStore: {},
    };

    return this.requestStorefrontPageData(this.lastLoadedSlug)
      .then(response => {
        loadedData.storefrontPageStore.storefrontPageData = response.items[0];
      })
      .then(() => {
        if (loadedData.storefrontPageStore.storefrontPageData) {
          return this.requestShopifyData(loadedData.storefrontPageStore.storefrontPageData);
        }
      })
      .then(() => {
        // Store all the stuff ShopifyProductStore loaded in the preload response
        loadedData.shopifyProductStore.loadedProducts = this.shopifyProductStore.loadedProducts;
        this.onShopifyDataLoaded();

        return loadedData;
      })
      .catch(error => {
        console.log('StorefrontPageStore preload error', error); // eslint-disable-line no-console
      });
  }

  requestStorefrontPageData(slug) {
    return contentful.getEntries('storefrontPage', {
      'fields.slug': slug,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .then(response => {
        if (!response.items[0]) {
          throw new Error('No matching storefront page found');
        }

        return response;
      })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
        throw error;
      });
  }

  requestShopifyData(storefrontPageData) {
    if (storefrontPageData) {
      const productsOnShopify = [];
      // Loop through all page sections for this storefront
      (getField(storefrontPageData, 'pageSections') || []).forEach(pageSection => {
        // If this section has a sections field (ie. a product hero array of some sort), loop through it
        (getField(pageSection, 'sections') || []).forEach(section => {
          // If this item has a valid shopifyHandle, add it to the array of slugs to load
          const shopifyHandle = getField(section, 'product.fields.shopifyHandle');
          if (shopifyHandle) {
            productsOnShopify.push(shopifyHandle);
          }
        });
      });

      const promises = productsOnShopify.map(slug =>
        this.shopifyProductStore.loadShopifyProduct(slug)
      );

      return Promise.all(promises);
    } else {
      return new Promise(resolve => resolve(undefined));
    }
  }

  /**
   * Load a storefront page by it's slug
   * @param {string} slug The slug for the requested storefront
   */
  @action('Load a storefront page')
  loadStorefrontPage(slug, family) {
    if (slug !== this.lastLoadedSlug) {
      this.loading = true;
      this.notFound = false;
      slug = family ? `${family}/${slug}` : slug;
      this.lastLoadedSlug = slug;
      this.storefrontPageData = undefined;
      this.requestStorefrontPageData(slug)
        .then(response => this.onStorefrontPageLoaded(response.items[0], slug))
        .then(() => this.loadShopifyData(this.storefrontPageData))
        .catch(error => runInAction(() => {
          console.log(error); //eslint-disable-line no-console
          this.notFound = true;
        }));
    }
  }

  /**
   * Load all Shopify data for a storefornt page
   * @param {object} storefrontPageData Storefront page data loaded from Contentful
   */
  @action('Load Shopify data for a storefront page')
  loadShopifyData(storefrontPageData) {
    this.requestShopifyData(storefrontPageData)
      .then(() => this.onShopifyDataLoaded());
  }

  @action('Shopify data for storefrontpage load successful')
  onShopifyDataLoaded() {
    this.loading = false;
  }

  @action('Successfully loaded storefront page')
  onStorefrontPageLoaded(storefrontPageData, slug) {
    // Ensure this is the last requested slug. This ensures the correct product is shown despite fast clicking between families
    if (!slug || slug === this.lastLoadedSlug) {
      this.storefrontPageData = storefrontPageData;
      this.siteMetaStore.setPageMetadata(getField(this.storefrontPageData, 'pageMetadata.fields'), getField(this.storefrontPageData, 'title'));
    }
  }

  @action('Preloaded storefront page data found')
  onPreloadedDataFound(preloadedData) {
    this.lastLoadedSlug = preloadedData.storefrontPageStore.lastLoadedSlug;
    this.shopifyProductStore.loadedProducts = preloadedData.shopifyProductStore.loadedProducts;
    this.onStorefrontPageLoaded(preloadedData.storefrontPageStore.storefrontPageData);
    this.onShopifyDataLoaded();
  }
}

export default StorefrontPageStore;
