import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import globalStyles from 'globalStyles';
import styles from './FooterWrapper.styl';

const FooterWrapper = ({ title, children }) => (
  <footer className={styles.footerWrapper} id='siteFooter'>
    <h2 className={globalStyles.hidden}>{title}</h2>
    <div className={styles.footerWrapperGrid}>
      {children}
    </div>
  </footer>
);

FooterWrapper.propTypes = {
  onRef: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default withStyles(globalStyles, styles)(FooterWrapper);
