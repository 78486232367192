import { Component } from 'react';
import PropTypes from 'prop-types';

class AppContextProvider extends Component {

  constructor(props) {
    super(props);
  }

  getChildContext() {
    const context = this.props.context;

    return {
      insertCss: (context && context.insertCss) || (() => {}),
    };
  }

  render() {
    return this.props.children;
  }
}

AppContextProvider.propTypes = {
  context: PropTypes.shape({
    insertCss: PropTypes.func,
  }),
  children: PropTypes.element.isRequired,
};

AppContextProvider.childContextTypes = {
  insertCss: PropTypes.func.isRequired,
};

export default AppContextProvider;
