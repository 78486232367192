import { observable, action } from 'mobx';

import shopify from 'services/shopify';

/**
 * Loads and manages product family data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class ShopifyProductStore {

  @observable.shallow loadedProducts = {};
  @observable failedProductHandles = [];

  @action('Load a Shopify product by ID')
  loadShopifyProduct(handle) {
    // Don't load from Shopify if we already have it cached
    if (this.loadedProducts[handle]) return new Promise((resolve) => resolve(this.loadedProducts[handle]));

    return shopify.getProductByHandle(handle)
      .then(product => this.onShopifyProductLoaded(handle, product))
      .catch(error => {
        this.onShopifyProductLoadError(handle, error);
      });
  }

  @action('Shopify product failed to load')
  onShopifyProductLoadError = (handle, error) => {
    this.failedProductHandles.push(handle);
    console.log('Shopify product load error', error); // eslint-disable-line
  }

  @action('Shopify product load successful')
  onShopifyProductLoaded = (loadedProductHandle, shopifyProduct) => {
    this.loadedProducts[loadedProductHandle] = shopifyProduct;
    // Remove this product from the failed to load list, if it was in there
    if (this.failedProductHandles.indexOf(loadedProductHandle) > -1) {
      this.failedProductHandles.splice(this.failedProductHandles.indexOf(loadedProductHandle), 1);
    }

    return shopifyProduct;
  }
}

export default ShopifyProductStore;
