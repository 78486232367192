import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './LoggedInContent.styl';

import { AUTH_URL, MY_ROLI_URL, ROLI_COM_URL } from 'constants/roli';

import LinkButton from 'components/common/buttons/LinkButton';

const LoggedInContent = ({ username }) => (
  <div className={styles.loggedInContent}>
    <h2 className={styles.title}>You are logged in as {username}</h2>
    <ul className={styles.options}>
      <li className={styles.optionsItem}>
        <LinkButton
          label='My ROLI'
          to={MY_ROLI_URL}
          target='_blank'
        />
      </li>
      <li className={styles.optionsItem}>
        <LinkButton
          label='Log out'
          to={`${AUTH_URL}/signout?redirect=${ROLI_COM_URL}`}
          target='_self'
        />
      </li>
    </ul>
  </div>
);

LoggedInContent.propTypes = {
  username: PropTypes.string,
};

export default withStyles(styles)(LoggedInContent);
