import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { REGION_CONFIGS } from 'constants/shopify';

import throttle from 'utils/throttle';
import { getField } from 'utils/contentfulUtils';

import { THROTTLE_DELAY } from 'constants/roli';

import LocaleSelector from 'containers/forms/LocaleSelector';
import NewsletterSubscriptionForm from 'containers/forms/NewsletterSubscriptionForm';

import FooterWrapper from 'components/siteFooter/FooterWrapper';
import FooterSocialWrapper from 'components/siteFooter/FooterSocialWrapper';
import RoliLogo from 'components/siteFooter/RoliLogo';
import FooterLinks from 'components/siteFooter/FooterLinks';
import SocialLinks from 'components/siteFooter/SocialLinks';
import AdditionalFooterCopy from 'components/siteFooter/AdditionalFooterCopy';
import CurrencyOptions from 'components/siteFooter/CurrencyOptions';
import SiteInformation from 'components/siteFooter/SiteInformation';
import SecondaryContentWrapper from 'components/siteFooter/SecondaryContentWrapper';
import FloatingMessage from 'containers/common/FloatingMessage';

@inject('siteFooterStore', 'translationStore', 'siteUIStore') @observer
class SiteFooter extends Component {

  constructor(props) {
    super(props);
    this.containerElement = undefined;
  }

  componentDidMount() {
    if (!this.props.siteUIStore.runningOnServer) {
      window.addEventListener('resize', this.onWindowResized);
      window.addEventListener('scroll', this.onWindowResized);
      this.onWindowResized();
    }
  }

  componentWillUpdate() {
    this.onWindowResized();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResized);
    window.removeEventListener('scroll', this.onWindowResized);
  }

  onWindowResized = throttle(() => {
    if (this.containerElement) {
      this.props.siteUIStore.updateSiteFooterHeight(this.containerElement.clientHeight);
      const offsetTop = this.containerElement.getBoundingClientRect().top + window.pageYOffset;
      this.props.siteUIStore.updateSiteFooterOffsetTop(offsetTop);
    }
  }, THROTTLE_DELAY);

  render() {
    const {
      siteFooterStore,
      translationStore,
      siteUIStore,
      outsideApp,
      currentPath,
    } = this.props;
    const fields = (siteFooterStore.footerContent) ? siteFooterStore.footerContent.fields : undefined;
    const globalTranslations = translationStore.globalTranslations;
    const actionTranslations = translationStore.actionTranslations;

    return fields ? (
      <div ref={element => this.containerElement = element}>
        <FooterWrapper title='More about ROLI'>
          <RoliLogo outsideApp={outsideApp} />

          <FooterSocialWrapper>
            <NewsletterSubscriptionForm
              inputPlaceholder={globalTranslations.newsletterInstruction}
              darkMode={true}
              inlineMessages={true}
              buttonLabel={getField(fields.buttonLabel, 'label')}
            />
            <SocialLinks
              title={globalTranslations.followUs}
              links={fields.socialLinks}
            />
            {!outsideApp &&
              <LocaleSelector currentPath={this.props.currentPath} />
            }
          </FooterSocialWrapper>

          <FooterLinks
            outsideApp={outsideApp}
            firstLinkGroupTitle={fields.firstLinkGroupTitle}
            firstLinkGroup={fields.firstLinkGroup}
            secondLinkGroupTitle={fields.secondLinkGroupTitle}
            secondLinkGroup={fields.secondLinkGroup}
            thirdLinkGroupTitle={fields.thirdLinkGroupTitle}
            thirdLinkGroup={fields.thirdLinkGroup}
            fourthLinkGroupTitle={fields.fourthLinkGroupTitle}
            fourthLinkGroup={fields.fourthLinkGroup}
          />

          <AdditionalFooterCopy copy={fields.additionalCopy} />

        </FooterWrapper>

        <SecondaryContentWrapper>
          <SiteInformation
            outsideApp={outsideApp}
            copyrightNotice={fields.copyrightNotice}
            links={fields.secondaryLinks}
          />
          <CurrencyOptions
            title={actionTranslations.selectCurrency}
            regionConfigs={REGION_CONFIGS}
            activeRegionConfig={siteUIStore.regionData}
          />
        </SecondaryContentWrapper>

        { !outsideApp && fields.floatingMessage &&
          <FloatingMessage
            id={fields.floatingMessage.sys.id}
            title={getField(fields.floatingMessage, 'title')}
            linkLabel={getField(fields.floatingMessage, 'linkCopy')}
            linkUrl={getField(fields.floatingMessage, 'linkUrl')}
            associatedCompetition={getField(fields.floatingMessage, 'associatedCompetition')}
            currentPath={currentPath}
          />
        }
      </div>
    ) : null;
  }
}

SiteFooter.propTypes = {
  outsideApp: PropTypes.bool,
  siteFooterStore: PropTypes.object,
  translationStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  currentPath: PropTypes.string.isRequired,
};

export default SiteFooter;
