import React from 'react';
import PropTypes from 'prop-types';

const RoliLockup = ({ className }) => (
  <svg className={className} version='1.1' viewBox='0 0 46.8 71.6'>
    <title>ROLI</title>
    <path d='M21.6 55.3c-4.5 0-8.1 3.7-8.1 8.2s3.7 8.1 8.2 8.1c4.5 0 8.1-3.7 8.1-8.1 0-4.5-3.7-8.2-8.2-8.2zm0 14.1c-3.2 0-5.8-2.6-5.8-5.8v-.2c0-3.2 2.6-5.8 5.8-5.8s5.8 2.6 5.8 5.8c.1 3.2-2.5 5.9-5.7 6h-.1zM34 55.5h-2.3v15.9H42v-2.1h-8V55.5zm9.7 0H46v15.9h-2.3V55.5zM8.4 65.3c2.2-.5 3.7-2.5 3.7-4.8 0-2.8-2.1-5-5.5-5H.7v15.9h2.5V57.8h3.2c1.9 0 3.2 1.1 3.2 2.8 0 1.5-1.1 2.7-2.6 2.8H3.8l6.3 8.1h3.1l-4.8-6.2zM31.9 34.1c-.2-.3-.4-.5-.8-.6-1.4-.4-2.7-1.6-3.9-2.9-.5-.6-1.1-1.2-1.8-1.9l-.1-.1.2-.1c3.2-1.2 5.4-4.3 5.4-7.8 0-4.5-3.6-8.1-8.1-8.1h-4.5c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3h4.5c3.1 0 5.6 2.4 5.6 5.5 0 3.2-2.5 5.7-5.6 5.7h-4.5c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3h2.4c1.8 0 3.2 1.6 4.7 3.3 1.4 1.6 2.9 3.1 5.1 3.7.3.1.7 0 .9-.1.6-.4.8-1.2.5-1.8M46.8 24c0 13.2-10.5 24-23.4 24S0 37.2 0 24 10.5 0 23.4 0s23.4 10.7 23.4 24'/>
  </svg>
);

RoliLockup.propTypes = {
  className: PropTypes.string,
};

export default RoliLockup;

