import { action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

/**
 * Loads and manages product kit data
 * @class
 * @param {object} shopifyProductStore
 */
class ProductKitStore {

  INCLUDE_REFERENCE_DEPTH = 3;

  shopifyProductStore = undefined;

  constructor(shopifyProductStore) {
    this.shopifyProductStore = shopifyProductStore;
  }

  requestShopifyData(productKits) {
    const products = [];
    productKits.forEach(kit => {
      products.push(getField(kit, 'kitProduct'));
    });

    if (products.length) {
      const productsOnShopify = (products || []).filter(product =>
        product.fields['shopifyHandle'] !== undefined
      );
      const promises = productsOnShopify.map(product =>
        this.shopifyProductStore.loadShopifyProduct(getField(product, 'shopifyHandle'))
      );

      return Promise.all(promises);
    } else {
      return new Promise(resolve => resolve(undefined));
    }
  }

  /**
   * Load all Shopify data for a product kits
   * @param {object} productKits Product kit data loaded from Contentful
   */
  @action('Load Shopify data for a product kits')
  loadShopifyData(productKits) {
    this.requestShopifyData(productKits);
  }
}

export default ProductKitStore;
