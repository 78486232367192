import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { CART_PAGE_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Class that loads and stores data for the cart page
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class CartPageStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable cartPageData = undefined;
  @observable loading = false;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.cartPageStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData() {
    this.loading = true;

    return this.requestCartPageData()
      .then(response => ({
        cartPageStore: {
          cartPageData: response.items[0],
        },
      }));
  }

  requestCartPageData() {
    return contentful.getEntries('cartPage', {
      'sys.id': CART_PAGE_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the cart page data
   */
  @action('Load cart page data')
  loadCartPageData() {
    if (!this.cartPageData) {
      this.loading = true;
      this.requestCartPageData()
        .then(response => this.onCartPageDataLoaded(response.items[0]));
    } else {
      this.onCartPageDataLoaded(this.cartPageData);
    }
  }

  @action('Successfully loaded cart page data')
  onCartPageDataLoaded(cartPageData) {
    this.cartPageData = cartPageData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.cartPageData, 'pageMetadata.fields'), getField(this.cartPageData, 'title'));
  }

  @action('Preloaded cart page data found')
  onPreloadedDataFound(preloadedData) {
    this.onCartPageDataLoaded(preloadedData.cartPageStore.cartPageData);
  }
}

export default CartPageStore;
