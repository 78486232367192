import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Loads and manages an education landing page
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class EducationLandingPageStore {

  INCLUDE_REFERENCE_DEPTH = 1;

  @observable pageData = undefined;
  @observable loading = true;
  @observable lastLoadedSlug = undefined;

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.educationLandingPageStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);
    this.loading = true;

    return this.requestLandingPage(match.params.slug)
      .then(response => ({
        educationLandingPageStore: {
          pageData: response.items[0],
          lastLoadedSlug: match.params.slug,
        },
      }))
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  @action('Load an education landing page by slug')
  loadLandingPageBySlug(slug) {
    if (slug !== this.lastLoadedSlug) {
      this.loading = true;
      // Clear previous pageData to ensure it doesn't show up when going between landing pages directly
      this.pageData = undefined;

      return this.requestLandingPage(slug)
        .then(response => this.onLandingPageLoadComplete(response.items[0]))
        .catch(error => {
          console.log(error); // eslint-disable-line no-console
        });
    }
  }

  requestLandingPage(slug) {
    this.lastLoadedSlug = slug;

    return contentful.getEntries('educationLandingPage', {
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
      'fields.slug': slug,
    });
  }

  @action('Successfully loaded an education landing page')
  onLandingPageLoadComplete(pageData) {
    this.pageData = pageData;
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.pageData, 'pageMetadata.fields'), getField(this.pageData, 'title'));
  }

  @action('Preloaded education landing page data found')
  onPreloadedDataFound(preloadedData) {
    this.lastLoadedSlug = preloadedData.educationLandingPageStore.lastLoadedSlug;
    this.onLandingPageLoadComplete(preloadedData.educationLandingPageStore.pageData);
  }
}

export default EducationLandingPageStore;
