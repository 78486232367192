import { observable, action, computed } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { getQueryParam } from 'utils/linkUtils';
import { LEARN_PAGE_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Class that loads and stores learn data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class LearnLandingPageStore {

  INCLUDE_REFERENCE_DEPTH = 2;

  POSTS_PER_PAGE = 9;
  @observable.shallow featuredLessons = [];
  @observable.shallow lessons = [];
  @observable totalPages = 0;
  @observable currentPage = 0;
  @observable loading = true;
  @observable.shallow learnPageData = undefined;
  @observable featuredIds = [];
  @observable tagLoaded = false;
  activeTags = undefined;

  /**
   * Get the total number of posts loaded
   */
  @computed get totalLessonsLoaded() {
    return this.lessons.length;
  }

  /**
   * Check if all pages have been loaded
   * @returns {boolean}
   */
  @computed get allPagesLoaded() {
    return (this.currentPage >= this.totalPages);
  }

  constructor(location, siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();
    if (location) this.activeTags = getQueryParam(location.search, 'tags', false);
    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.learnLandingPageStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData(match, locale, location) {
    this.loading = true;
    const tags = getQueryParam(location.search, 'tags', false);
    const loadedData = {
      learnLandingPageStore: {},
    };
    this.tagLoaded = (tags) ? true : false;
    this.activeTags = tags;

    return this.requestLearnPageData()
      .then(response => {
        loadedData.learnLandingPageStore.learnPageData = response.items[0];
        this.featuredIds = (getField(response.items[0], 'featuredLessons') || []).map(lesson => (
          lesson.sys.id
        ));
      })
      .then(() => this.requestPage(tags))
      .then(response => {
        loadedData.learnLandingPageStore.response = response;

        return loadedData;
      });
  }

  @action('Load page data and first page of lessons')
  loadLearnPage(location) {
    // Don't reload the landing page data if we already have it
    return !this.learnPageData
      ? this.requestLearnPageData()
        .then(response => this.onLearnPageDataLoaded(response.items[0]))
        .then(() => this.loadPage(location))
      : this.loadPage(location);
  }

  @action('Clear lesson list data')
  resetPagination() {
    this.currentPage = 0;
    this.totalPages = 0;
    this.lessons = [];
  }

  @action('Load a page of lessons')
  loadPage = (location) => {
    const newTags = getQueryParam(location.search, 'tags', false);
    if (
      newTags !== this.activeTags || (
        newTags === this.activeTags && (!this.allPagesLoaded || this.currentPage === 0)
      )
    ) {
      this.loading = true;
      this.activeTags = getQueryParam(location.search, 'tags', false);
      this.tagLoaded = (this.activeTags) ? true : false;

      return this.requestPage(this.activeTags)
        .then(response => {
          this.onLessonsLoaded(response);

          return response.items;
        })
        .catch(error => {
          console.log(error); // eslint-disable-line no-console
        });
    }
  }

  /**
   * Load the most recent lessons
   */
  @action('Load recent lessons')
  requestPage(tags) {
    if (this.currentPage === 0) this.resetPagination();

    let options = {
      limit: this.POSTS_PER_PAGE,
      skip: this.currentPage * this.POSTS_PER_PAGE,
      include: this.INCLUDE_REFERENCE_DEPTH,
      order: 'fields.customOrder',
      select: 'fields.title,fields.slug,fields.postDate,fields.thumbnail,fields.description,fields.visibleTags',
    };

    if (tags) {
      options['fields.tags[in]'] = tags;
    } else {
      options['sys.id[nin]'] = this.featuredIds.join(',');
    }

    return contentful.getEntries('lesson', options);
  }

  /**
   * Load the data found on the learn page
   */
  @action('Request learn page data')
  requestLearnPageData() {
    return contentful.getEntries('learnHomepage', {
      'sys.id': LEARN_PAGE_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the learn data
   */
  @action('Load learn page data')
  loadLearnPageData() {
    if (!this.learnPageData) {
      this.loading = true;
      this.requestLearnPageData()
        .then(response => this.onLearnPageDataLoaded(response.items[0]));
    } else {
      this.onLearnPageDataLoaded(this.learnPageData);
    }
  }

  @action('Successfully loaded a page of lessons')
  onLessonsLoaded(response) {
    this.totalPages = Math.ceil(response.total / this.POSTS_PER_PAGE);
    this.lessons = this.lessons.concat(response.items);
    this.currentPage++;
    this.totalPosts = response.total;
    this.loading = false;
  }

  @action('Successfully loaded learn page')
  onLearnPageDataLoaded(learnPageData) {
    this.learnPageData = learnPageData;
    this.loading = false;

    this.featuredLessons = getField(learnPageData, 'featuredLessons');
    // get the ids of featured lessons so we can exclude them from the search
    this.featuredIds = (this.featuredLessons || []).map(lesson => (
      lesson.sys.id
    ));

    this.siteMetaStore.setPageMetadata(getField(this.learnPageData, 'pageMetadata.fields'), getField(this.learnPageData, 'title'));
  }

  @action('Preloaded learn data found')
  onPreloadedDataFound(preloadedData) {
    this.onLearnPageDataLoaded(preloadedData.learnLandingPageStore.learnPageData);
    this.onLessonsLoaded(preloadedData.learnLandingPageStore.response);
  }
}

export default LearnLandingPageStore;
