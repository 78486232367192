import { observable, action } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { HOMEPAGE_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

/**
 * Class that loads and stores homepage data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class HomeStore {

  INCLUDE_REFERENCE_DEPTH = 3;

  @observable homepageData = undefined;
  @observable loading = false;
  @observable slides = [];

  constructor(siteMetaStore, siteUIStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();
    this.siteUIStore = siteUIStore;

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.homeStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData() {
    this.loading = true;

    return this.requestHomepage()
      .then(response => ({
        homeStore: {
          homepageData: response.items[0],
        },
      }));
  }

  requestHomepage() {
    return contentful.getEntries('homepage', {
      'sys.id': HOMEPAGE_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  /**
   * Load the homepage data
   */
  @action('Load homepage data')
  loadHomepageData() {
    if (!this.homepageData) {
      this.loading = true;
      this.requestHomepage()
        .then(response => this.onHomepageDataLoaded(response.items[0]));
    } else {
      this.onHomepageDataLoaded(this.homepageData);
    }
  }

  @action('Successfully loaded product family')
  onHomepageDataLoaded(homepageData) {
    this.homepageData = homepageData;
    this.slides = getField(this.homepageData, 'slideshow.fields.slides').filter(
      slide => (!getField(slide, 'displayRegion') || getField(slide, 'displayRegion').includes(this.siteUIStore.regionData.REGION))
    );
    this.loading = false;

    this.siteMetaStore.setPageMetadata(getField(this.homepageData, 'pageMetadata.fields'), getField(this.homepageData, 'title'));
  }

  @action('Preloaded homepage data found')
  onPreloadedDataFound(preloadedData) {
    this.onHomepageDataLoaded(preloadedData.homeStore.homepageData);
  }
}

export default HomeStore;
