require('es6-promise').polyfill();

import { fetchJsonp } from 'utils/httpUtils';
import { generateMailchimpUrl } from 'utils/mailchimpUtils';

class MailchimpService {

  /**
   * Returns a JSON object with result and message values
   * Result contains either 'success' or 'error'
   */
  addToList(listId, email, fields) {
    let mailchimpUrl = `${generateMailchimpUrl(true, listId)}&EMAIL=${encodeURIComponent(email)}`;

    for (var key in fields) {
      if (key == 'GROUP' && typeof fields[key] == 'object' && fields[key].length == 2) {
        mailchimpUrl = mailchimpUrl + `&group[${fields[key][0]}][${fields[key][1]}]=${fields[key][1]}`;
      } else {
        mailchimpUrl = mailchimpUrl + `&${key}=${fields[key]}`;
      }
    }

    return fetchJsonp(mailchimpUrl, {
      callback: 'c',
    })
      .then(response => response.json());
  }
}

const service = new MailchimpService();

export default service;
