import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './FloatingPanel.styl';

import ClickOutsideHandler from 'containers/common/ClickOutsideHandler';

const FloatingPanel = ({
  children,
  small,
  open,
  onDocumentClickedOutside,
}) => {
  const classes = classNames({
    [styles.panelWrapper]: true,
    [styles.smallPanel]: small,
  });

  return (
    <CSSTransitionGroup
      transitionName={{
        enter: styles.panelEnter,
        enterActive: styles.panelEnterActive,
        leave: styles.panelLeave,
        leaveActive: styles.panelLeaveActive,
      }}
      transitionEnterTimeout={100}
      transitionLeaveTimeout={100}
    >
      {open && <ClickOutsideHandler
        key={uuid()}
        className={classes}
        onDocumentClickedOutside={onDocumentClickedOutside}
      >
        <aside className={styles.panel}>
          <div className={styles.panelContent}>
            {children}
          </div>
        </aside>
      </ClickOutsideHandler>}
    </CSSTransitionGroup>
  );
};

FloatingPanel.propTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  open: PropTypes.bool,
  onDocumentClickedOutside: PropTypes.func.isRequired,
};

export default withStyles(styles)(FloatingPanel);
