import { observable, action, computed, runInAction } from 'mobx';

import { getField } from 'utils/contentfulUtils';
import SiteMetaStore from 'stores/domain/SiteMetaStore';

import contentful from 'services/contentful';

/**
 * Class that loads and stores product data
 * @class
 */
class ProductStore {

  INCLUDE_REFERENCE_DEPTH = 4;

  @observable.shallow product = undefined;
  @observable shopifyProduct = undefined;
  @observable loading = true;
  @observable notFound = false;
  @observable selectedVariantId = undefined;
  shopifyProductStore = undefined;

  @computed get productFields() {
    if (this.product) {
      const fields = this.product.fields;
      if (this.shopifyProduct && fields) fields.variants = this.shopifyProduct.variants;

      return fields;
    }

    return undefined;
  }

  @computed get productStats() {
    return this.product ? this.product.sys : undefined;
  }

  constructor(shopifyProductStore, siteMetaStore, siteUIStore, preloadedData) {
    this.shopifyProductStore = shopifyProductStore;
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();
    this.siteUIStore = siteUIStore;

    if (preloadedData) {
      if (preloadedData.productStore) {
        this.onPreloadedDataFound(preloadedData);
      }
    }
  }

  @action('Setting selected variant on project page')
  setSelectedVariantId(value) {
    this.selectedVariantId = value;
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);
    const family = match.params.family;
    const product = match.params.product;
    let loadedData = {};
    this.loading = true;
    this.lastLoadedSlug = `${family}/${product}`;

    return this.requestProduct(this.lastLoadedSlug)
      .then(response => {
        loadedData = {
          productStore: {
            product: response.items[0],
            lastLoadedSlug: this.lastLoadedSlug,
          },
        };

        return loadedData;
      })
      .catch(error => {
        console.log('ProductStore preload error', error); //eslint-disable-line no-console
      });
  }

  requestProduct(slug) {
    this.requestedSlug = slug;
    // Clear out the previously selected variant
    this.selectedVariant = undefined;

    return contentful.getEntries('product', {
      'fields.slug': slug,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .then(response => {
        if (!response.items[0]) {
          throw new Error(`No product found for slug '${slug}'`);
        }

        return response;
      })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
        throw error;
      });
  }

  /**
   * Load a product by it's slug
   * This will then use the value of the field 'shopifyHandle' in Contentful to load the matching Shopify data
   * @param {string} slug The value of the 'slug' field in Contentful
   */
  @action('Load a single product by slug')
  loadProductBySlug(family, product) {
    const slug = `${family}/${product}`;
    if (slug !== this.lastLoadedSlug) {
      this.loading = true;
      this.lastLoadedSlug = slug;
      this.notFound = false;
      // Clear previous product to ensure it doesn't show up when going straight from
      // product page to product page
      this.product = undefined;
      this.shopifyProduct = undefined;
      this.requestProduct(slug)
        .then(response => this.onProductLoaded(response.items[0], slug))
        .then(() => {
          if (getField(this.product, 'shopifyHandle')) {
            this.loadShopifyProduct(this.product);
          } else {
            return;
          }
        })
        .catch(error => runInAction(() => {
          console.log(error); //eslint-disable-line no-console
          this.notFound = true;
        }));
    } else if (!this.shopifyProduct) {
      this.loadShopifyProduct(this.product);
    }
  }

  requestShopifyProduct(product) {
    return (product && getField(product, 'shopifyHandle'))
      ? this.shopifyProductStore.loadShopifyProduct(getField(product, 'shopifyHandle'))
      : new Promise(resolve => resolve(undefined));
  }

  loadShopifyProduct(product) {
    return (getField(product, 'shopifyHandle'))
      ? this.requestShopifyProduct(product)
        .then(shopifyProduct => this.onShopifyProductLoaded(shopifyProduct))
        .catch((error) => this.onShopifyProductLoadFailed(error))
      : new Promise(resolve => resolve(undefined));
  }

  @action('Preloaded product data found')
  onPreloadedDataFound(preloadedData) {
    this.lastLoadedSlug = preloadedData.productStore.lastLoadedSlug;
    this.loading = false;
    this.onProductLoaded(preloadedData.productStore.product);
  }

  @action('Shopify product load successful')
  onShopifyProductLoaded(shopifyProduct) {
    if (shopifyProduct) {
      this.shopifyProduct = shopifyProduct;
    }
    this.loading = false;

    return shopifyProduct;
  }

  onShopifyProductLoadFailed(error) {
    console.error(error); // eslint-disable-line no-console

    return undefined;
  }

  @action('Contentful product load successful')
  onProductLoaded = (product, slug) => {
    if ((!slug || slug === this.requestedSlug) && product) {
      this.product = product;
      this.siteMetaStore.setPageMetadata(getField(this.product, 'pageMetadata.fields'), getField(this.product, 'title'));
    }

    if (getField(product, 'displayRegion') && !getField(product, 'displayRegion').includes(this.siteUIStore.regionData.REGION)){
      this.product.fields.promoBannerLightbox = undefined;
      this.product.fields.promoBannerOverlay = undefined;
      this.product.fields.promoBannerText = undefined;
    }

    if (getField(this.product, 'bundles')) {
      const bundleOptions = getField(this.product, 'bundles');
      const variantField = `variantIDs_${this.siteUIStore.regionData.REGION}`;
      const priceField = `price_${this.siteUIStore.regionData.REGION}`;
      const comparePriceField = `comparePrice_${this.siteUIStore.regionData.REGION}`;

      this.product.fields.bundles = bundleOptions.filter((option) => {
        return getField(option, variantField) && getField(option, priceField);
      }).map((option) => {
        return {
          id: getField(option, variantField).join(','),
          title: getField(option, 'title'),
          price: getField(option, priceField) * 100,
          compare_at_price: getField(option, comparePriceField) * 100,
          available: getField(option, 'available'),
        };
      });

      if (this.product.fields.bundles.length == 0) {
        this.product.fields.bundles = undefined;
      }
    }

    return product;
  }
}

export default ProductStore;
