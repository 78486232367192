import { observable, action, computed, runInAction } from 'mobx';

import { getField } from 'utils/contentfulUtils';
import SiteMetaStore from 'stores/domain/SiteMetaStore';
import contentful from 'services/contentful';

/**
 * Loads and manages product family data
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class ProductFamilyStore {

  INCLUDE_REFERENCE_DEPTH = 3;

  @observable.shallow productFamily = undefined;
  @observable lastLoadedSlug = undefined;
  @observable loading = true;
  @observable notFound = false;
  @observable shopifyDataLoaded = false;
  requestedSlug = false;
  shopifyProductStore = undefined;

  @computed get familyFields() {
    return this.productFamily ? this.productFamily.fields : undefined;
  }

  @computed get familyStats() {
    return this.productFamily ? this.productFamily.sys : undefined;
  }

  constructor(shopifyProductStore, siteMetaStore, siteUIStore, preloadedData) {
    this.shopifyProductStore = shopifyProductStore;
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();
    this.siteUIStore = siteUIStore;
    // Store receives preloadedData when the server-side render completes
    if (preloadedData) {
      if (preloadedData.productFamilyStore) {
        this.onPreloadedDataFound(preloadedData);
      }
    }
  }

  __preloadServerSideData(match, locale) {
    contentful.setLocale(locale);
    this.lastLoadedSlug = match.params.family;

    this.loading = true;
    const loadedData = {
      productFamilyStore: {
        lastLoadedSlug: this.lastLoadedSlug,
      },
      shopifyProductStore: {},
    };

    return this.requestProductFamily(this.lastLoadedSlug)
      .then(response => {
        loadedData.productFamilyStore.productFamily = response.items[0];

        return loadedData;
      })
      .catch(error => {
        console.log('ProductFamilyStore preload error', error); // eslint-disable-line no-console
      });
  }

  requestProductFamily(slug) {
    this.lastLoadedSlug = slug;

    return contentful.getEntries('productFamily', {
      'fields.slug': slug,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .then(response => {
        if (!response.items[0]) {
          throw new Error('No product family found');
        }

        return response;
      })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
        throw error;
      });
  }

  requestShopifyData(family) {
    if (family) {
      const productsOnShopify = (family.fields.products || []).filter(product =>
        getField(product, 'shopifyHandle') !== undefined
      );
      const promises = productsOnShopify.map(product =>
        this.shopifyProductStore.loadShopifyProduct(getField(product, 'shopifyHandle'))
      );

      return Promise.all(promises);
    } else {
      return new Promise(resolve => resolve(undefined));
    }
  }

  /**
   * Load a product family by it's slug
   * @param {string} slug The slug version of the family name
   */
  @action('Load a product family')
  loadProductFamily(slug) {
    if (slug !== this.lastLoadedSlug) {
      this.loading = true;
      this.notFound = false;
      this.lastLoadedSlug = slug;
      // Clear previous product to ensure it doesn't show up when going straight from
      // product page to product page
      this.productFamily = undefined;
      this.requestProductFamily(slug)
        .then(response => this.onFamilyLoaded(response.items[0], slug))
        .then(() => this.loadShopifyData(this.productFamily))
        .catch(error => runInAction(() => {
          console.log(error); //eslint-disable-line no-console
          this.notFound = true;
        }));
    } else if (!this.shopifyDataLoaded) {
      this.loadShopifyData(this.productFamily);
    }
  }

  /**
   * Load all Shopify data for a product family
   * @param {object} family Family data loaded from Contentful
   */
  @action('Load Shopify data for a product family')
  loadShopifyData(family) {
    this.requestShopifyData(family)
      .then(() => this.onShopifyDataLoaded());
  }

  @action('Shopify data for a product family load successful')
  onShopifyDataLoaded() {
    this.loading = false;
    this.shopifyDataLoaded = true;
  }

  @action('Successfully loaded product family')
  onFamilyLoaded(productFamily, slug) {
    // Ensure this is the last requested slug. This ensures the correct product is shown despite fast clicking between families
    if (!slug || slug === this.lastLoadedSlug) {
      this.productFamily = productFamily;
      this.siteMetaStore.setPageMetadata(getField(this.productFamily, 'pageMetadata.fields'), getField(this.productFamily, 'title'));
    }

    (getField(this.productFamily, 'products') || []).forEach(product => {
      if (getField(product, 'displayRegion') && !getField(product, 'displayRegion').includes(this.siteUIStore.regionData.REGION)){
        product.fields.promoBannerLightbox = undefined;
        product.fields.promoBannerOverlay = undefined;
        product.fields.promoBannerText = undefined;
      }

      if (getField(product, 'bundles')) {
        const bundleOptions = getField(product, 'bundles');
        const variantField = `variantIDs_${this.siteUIStore.regionData.REGION}`;
        const priceField = `price_${this.siteUIStore.regionData.REGION}`;
        const comparePriceField = `comparePrice_${this.siteUIStore.regionData.REGION}`;

        product.fields.bundles = bundleOptions.filter((option) => {
          return getField(option, variantField) && getField(option, priceField);
        }).map((option) => {
          return {
            id: getField(option, variantField).join(','),
            title: getField(option, 'title'),
            price: getField(option, priceField) * 100,
            compare_at_price: getField(option, comparePriceField) * 100,
            available: getField(option, 'available'),
          };
        });

        if (product.fields.bundles.length == 0) {
          product.fields.bundles = undefined;
        }
      }
    });
  }

  @action('Preloaded product family data found')
  onPreloadedDataFound(preloadedData) {
    this.lastLoadedSlug = preloadedData.productFamilyStore.lastLoadedSlug;
    this.loading = false;
    this.onFamilyLoaded(preloadedData.productFamilyStore.productFamily);
  }
}

export default ProductFamilyStore;
