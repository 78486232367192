import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, useStaticRendering } from 'mobx-react';
import { withCookies, Cookies } from 'react-cookie';
let DevTools;
if (process.env.NODE_ENV === 'development') {
  DevTools = require('mobx-react-devtools').default;
}

import SiteUIStore from 'stores/ui/SiteUIStore';
import TranslationStore from 'stores/ui/TranslationStore';
import SiteHeaderStore from 'stores/domain/SiteHeaderStore';
import SiteFooterStore from 'stores/domain/SiteFooterStore';
import HomeStore from 'stores/domain/HomeStore';
import BlogListStore from 'stores/domain/BlogListStore';
import BlogPostStore from 'stores/domain/BlogPostStore';
import ShopifyProductStore from 'stores/domain/ShopifyProductStore';
import ProductStore from 'stores/domain/ProductStore';
import ProductFamilyStore from 'stores/domain/ProductFamilyStore';
import LearnLandingPageStore from 'stores/domain/LearnLandingPageStore';
import LearnLessonPageStore from 'stores/domain/LearnLessonPageStore';
import ProductCatalogueStore from 'stores/domain/ProductCatalogueStore';
import CartStore from 'stores/domain/CartStore';
import TeamStore from 'stores/domain/TeamStore';
import AboutStore from 'stores/domain/AboutStore';
import CartPageStore from 'stores/domain/CartPageStore';
import ContactStore from 'stores/domain/ContactStore';
import LocaleStore from 'stores/domain/LocaleStore';
import TextPageStore from 'stores/domain/TextPageStore';
import CareersStore from 'stores/domain/CareersStore';
import CompetitionStore from 'stores/domain/CompetitionStore';
import NotFoundStore from 'stores/domain/NotFoundStore';
import EducationLandingPageStore from 'stores/domain/EducationLandingPageStore';
import SoundpackCarouselStore from 'stores/domain/SoundpackCarouselStore';
import SiteMetaStore from 'stores/domain/SiteMetaStore';
import RegistrationStartStore from 'stores/domain/RegistrationStartStore';
import RegistrationCompleteStore from 'stores/domain/RegistrationCompleteStore';
import ProductKitStore from 'stores/domain/ProductKitStore';
import CampaignStore from 'stores/domain/CampaignStore';
import StorefrontPageStore from 'stores/domain/StorefrontPageStore';

class StoreProvider extends Component {

  static __preloadServerSideData(locale) {
    // Load content for global elements on the server
    const promises = [];
    promises.push(new SiteFooterStore().__preloadServerSideData(locale));
    promises.push(new SiteHeaderStore().__preloadServerSideData(locale));
    promises.push(new TranslationStore().__preloadServerSideData(locale));

    return Promise.all(promises)
      .then(responses => {
        return responses.length ? Object.assign(...responses) : {};
      })
      .catch(error => {
        console.log(error);  // eslint-disable-line no-console
      });
  }

  constructor(props) {
    super(props);
    const preloadedData = props.preloadedData;

    this.translationStore = new TranslationStore(preloadedData);
    this.localeStore = new LocaleStore(props.cookies, this.props.location, this.translationStore);
    this.siteUIStore = new SiteUIStore(preloadedData, this.props.location);
    this.siteHeaderStore = new SiteHeaderStore(preloadedData);
    this.siteFooterStore = new SiteFooterStore(preloadedData);
    if (props.server) {
      this.siteUIStore.runningOnServer = props.server;
      // Stop MobX from getting too clever about reactions when running on the server
      useStaticRendering(true);
    }

    // Page stores
    this.siteMetaStore = new SiteMetaStore();
    this.homeStore = new HomeStore(this.siteMetaStore, this.siteUIStore, preloadedData);
    this.blogListStore = new BlogListStore(this.props.location, this.siteMetaStore, preloadedData);
    this.blogPostStore = new BlogPostStore(this.siteMetaStore, preloadedData);
    this.shopifyProductStore = new ShopifyProductStore(preloadedData);
    this.productStore = new ProductStore(this.shopifyProductStore, this.siteMetaStore, this.siteUIStore, preloadedData);
    this.productFamilyStore = new ProductFamilyStore(this.shopifyProductStore, this.siteMetaStore, this.siteUIStore, preloadedData);
    this.productCatalogueStore = new ProductCatalogueStore(this.shopifyProductStore, this.siteMetaStore, preloadedData);
    this.cartStore = new CartStore(this.siteUIStore);
    this.teamStore = new TeamStore(this.siteMetaStore, preloadedData);
    this.learnLandingPageStore = new LearnLandingPageStore(this.props.location, this.siteMetaStore, preloadedData);
    this.learnLessonPageStore = new LearnLessonPageStore(this.siteMetaStore, preloadedData);
    this.aboutStore = new AboutStore(this.siteMetaStore, preloadedData);
    this.cartPageStore = new CartPageStore(this.siteMetaStore, preloadedData);
    this.contactStore = new ContactStore(this.siteMetaStore, preloadedData);
    this.textPageStore = new TextPageStore(this.siteMetaStore, preloadedData);
    this.careersStore = new CareersStore(this.siteMetaStore, preloadedData);
    this.competitionStore = new CompetitionStore(this.siteMetaStore, preloadedData);
    this.notFoundStore = new NotFoundStore(this.siteMetaStore, preloadedData);
    this.educationLandingPageStore = new EducationLandingPageStore(this.siteMetaStore, preloadedData);
    this.soundpackCarouselStore = new SoundpackCarouselStore(preloadedData);
    this.registrationStartStore = new RegistrationStartStore(this.siteMetaStore, preloadedData);
    this.registrationCompleteStore = new RegistrationCompleteStore(this.siteMetaStore, preloadedData);
    this.productKitStore = new ProductKitStore(this.shopifyProductStore, preloadedData);
    this.campaignStore = new CampaignStore(this.shopifyProductStore, this.siteMetaStore, this.siteUIStore, preloadedData);
    this.storefrontPageStore = new StorefrontPageStore(this.shopifyProductStore, this.siteMetaStore, preloadedData);
  }

  componentWillMount() {
    // Load the content needed for global elements, unless it has already been loaded server-side
    if (!this.siteHeaderStore.preloaded && !this.siteUIStore.runningOnServer) this.siteHeaderStore.loadHeaderContent();
    if (!this.siteFooterStore.preloaded && !this.siteUIStore.runningOnServer) this.siteFooterStore.loadFooterContent();
    if (!this.translationStore.preloaded && !this.siteUIStore.runningOnServer) this.translationStore.loadTranslations();
  }

  render() {
    return (
      <Provider
        siteUIStore={this.siteUIStore}
        translationStore={this.translationStore}
        siteHeaderStore={this.siteHeaderStore}
        siteFooterStore={this.siteFooterStore}
        productFamilyStore={this.productFamilyStore}
        productCatalogueStore={this.productCatalogueStore}
        shopifyProductStore={this.shopifyProductStore}
        productStore={this.productStore}
        homeStore={this.homeStore}
        blogListStore={this.blogListStore}
        blogPostStore={this.blogPostStore}
        learnLandingPageStore={this.learnLandingPageStore}
        learnLessonPageStore={this.learnLessonPageStore}
        cartStore={this.cartStore}
        teamStore={this.teamStore}
        aboutStore={this.aboutStore}
        cartPageStore={this.cartPageStore}
        contactStore={this.contactStore}
        localeStore={this.localeStore}
        textPageStore={this.textPageStore}
        careersStore={this.careersStore}
        competitionStore={this.competitionStore}
        notFoundStore={this.notFoundStore}
        soundpackCarouselStore={this.soundpackCarouselStore}
        educationLandingPageStore={this.educationLandingPageStore}
        siteMetaStore={this.siteMetaStore}
        registrationStartStore={this.registrationStartStore}
        registrationCompleteStore={this.registrationCompleteStore}
        productKitStore={this.productKitStore}
        campaignStore={this.campaignStore}
        storefrontPageStore={this.storefrontPageStore}
      >
        <div>
          {this.siteUIStore.NODE_ENV === 'development' && <DevTools position={{top: 50, right: 20}}/>}
          { this.props.children }
        </div>
      </Provider>
    );
  }
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  server: PropTypes.bool,
  region: PropTypes.string,
  preloadedData: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  location: PropTypes.object,
};

export default withCookies(StoreProvider);
