import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import CartPreviewButton from 'components/common/buttons/CartPreviewButton';
import CartPreviewWrapper from 'components/apps/cartPreviewPanel/CartPreviewWrapper';
import FloatingPanel from 'components/siteHeader/FloatingPanel';
import CartPanelContent from 'components/apps/cartPreviewPanel/CartPanelContent';

@inject('cartStore', 'shopifyProductStore', 'siteUIStore') @observer
class CartPreviewPanel extends Component {

  constructor(props) {
    super(props);
    this.previewWrapper = undefined;
    this.state = {
      panelOpen: false,
    };
  }

  onDocumentClickedOutside = () => {
    this.setState({
      panelOpen: false,
    });
  }

  onCartButtonClicked = () => {
    this.setState({
      panelOpen: !this.state.panelOpen,
    });
  }

  onRemoveButtonClicked = (lineIndex) => {
    this.props.cartStore.removeItem(lineIndex);
  }

  onCheckoutClicked = () => {
    this.setState({
      panelOpen: false,
    });
  }

  render() {
    const { cartStore, outsideApp } = this.props;

    return (
      <CartPreviewWrapper open={this.state.panelOpen}>
        <CartPreviewButton
          outsideApp={outsideApp}
          totalItems={cartStore.totalItems}
          onButtonClicked={this.onCartButtonClicked}
        />
        <FloatingPanel
          open={this.state.panelOpen}
          onDocumentClickedOutside={this.onDocumentClickedOutside}
        >
          <CartPanelContent
            outsideApp={outsideApp}
            open={this.state.panelOpen}
            shopifyProducts={cartStore.products}
            totalItems={cartStore.totalItems}
            subtotal={cartStore.subtotalFormatted}
            onRemoveButtonClicked={this.onRemoveButtonClicked}
            onCheckoutClicked={this.onCheckoutClicked}
            onDocumentClickedOutside={this.onDocumentClickedOutside}
          />
        </FloatingPanel>
      </CartPreviewWrapper>
    );
  }
}

CartPreviewPanel.propTypes = {
  cartStore: PropTypes.object,
  shopifyProductStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  outsideApp: PropTypes.bool,
};

export default CartPreviewPanel;
