import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MobxReactForm from 'mobx-react-form';
import { inject, observer } from 'mobx-react';
import urlParse from 'url-parse';

import LocaleSelection from 'components/forms/LocaleSelection';

class FormHandler extends MobxReactForm {

  localeStore;

  onSuccess(form) {
    this.localeStore.updateLocale(form.values().locale);
    if (typeof window !== 'undefined') {
      const previousUrl = urlParse(window.location.href, true);
      // Clear locale from the query string if it was set
      let query = previousUrl.query;
      if (query.locale) {
        delete query.locale;
        previousUrl.set('query', query);
      }

      window.location = previousUrl.href;
    }
  }
}

@inject('localeStore', 'siteUIStore', 'translationStore') @observer
class LocaleSelector extends Component {

  constructor(props) {
    super(props);

    const { localeStore } = props;

    this.formHandler = new FormHandler(
      { fields: localeStore.fields },
      {
        plugins: localeStore.plugins,
        options: localeStore.options,
      },
    );

    this.formHandler.localeStore = localeStore;
  }

  render() {
    const { siteUIStore, currentPath, translationStore, localeStore } = this.props;

    return (
      <LocaleSelection
        form={this.formHandler}
        label={translationStore.globalTranslations.localeSelectionLabel}
        runningOnServer={siteUIStore.runningOnServer}
        currentPath={currentPath}
        currentLocale={localeStore.locale}
        submitButtonLabel={translationStore.globalTranslations.submit}
      />
    );
  }
}

LocaleSelector.propTypes = {
  localeStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  translationStore: PropTypes.object,
  currentPath: PropTypes.string.isRequired,
};

export default LocaleSelector;
