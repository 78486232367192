require('es6-promise').polyfill();

import fetch from 'isomorphic-fetch';

import { fetchJsonp } from 'utils/httpUtils';

class ShopifyService {

  client = undefined;
  regionData = undefined;
  url = null;
  currency_symbol = null;
  currency_code = null;
  tax_rate = null;
  tax_included = null;
  clientPromise = undefined;
  runningOnServer = true;
  cartHasLoaded = false;

  setRegionData(regionData) {
    this.regionData = regionData;
    // TODO: Set a cookie via react-cookie so this region can be used by the standalone header

    this.url = `https://${this.regionData.DOMAIN}`;
    this.currency_symbol = this.regionData.CURRENCY_SYMBOL;
    this.currency_code = this.regionData.CURRENCY_CODE;
    this.tax_rate = this.regionData.TAX_RATE;
    this.tax_included = this.regionData.TAX_INCLUDED;
  }

  getProductByHandle(handle) {
    this.cartHasLoaded = false;

    return fetch(`${this.url}/products/${handle}.js`)
      .then(response => response.json())
      .catch(error => this.onShopifyError(error));
  }

  getCart() {
    // Protected when running on server
    return (typeof window !== 'undefined' && typeof document !== 'undefined')
      ? fetchJsonp(`${this.url}/cart.json`)
        .then(response => response.json())
        .catch(error => this.onShopifyError(error))
      : new Promise(resolve => resolve());
  }

  addToCart(id, quantity) {
    this.cartHasLoaded = false;

    let updates = [];
    id.toString().split(',').forEach(id => {
      updates.push(`updates[${id}]=${quantity}`);
    });

    return fetch(`${this.url}/cart/update?${updates.join('&')}`, {credentials: 'include', mode: 'no-cors'})
      .then(() => {
        return this.getCart();
      })
      .catch(error => this.onShopifyError(error));
  }

  updateItem(id, quantity) {
    this.cartHasLoaded = false;

    let params = `?id=${id}&quantity=${quantity}`;

    return fetch(`${this.url}/cart/change${params}`, {credentials: 'include', mode: 'no-cors'})
      .then(() => this.getCart())
      .catch(error => this.onShopifyError(error));
  }

  updateItemByIndex(index, quantity) {
    this.cartHasLoaded = false;

    // note that Shopify uses a 1-based index
    index++;

    let params = `?line=${index}&quantity=${quantity}`;

    return fetch(`${this.url}/cart/change${params}`, {credentials: 'include', mode: 'no-cors'})
      .then(() => this.getCart())
      .catch(error => this.onShopifyError(error));
  }

  onShopifyError(error) {
    if (!this.cartHasLoaded) {
      // Call getCart to help Safari 9 keep going, otherwisethe user will just get an eternal spinner
      this.getCart();
      this.cartHasLoaded = true;
    }

    throw error;
  }

}

const service = new ShopifyService();

export default service;
