import { observable, action, computed } from 'mobx';

import { getField } from 'utils/contentfulUtils';

import contentful from 'services/contentful';
import { TEAM_PAGE_ID } from 'constants/contentful';
import SiteMetaStore from 'stores/domain/SiteMetaStore';
import { getShuffledIndexes } from 'utils/arrayUtils';

/**
 * Class that loads and stores data for the team page
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class TeamStore {

  INCLUDE_REFERENCE_DEPTH = 1;
  ITEMS_PER_PAGE = 50;

  @observable teamData = undefined;
  @observable arrangedMembers = [];
  @observable totalMembers = 0;
  @observable currentPage = 0;
  @observable totalPages = 0;
  @observable loading = false;
  shuffledIndexes = [];
  prioritisedIds = [];

  @computed get allPagesLoaded() {
    return this.totalPages <= this.currentPage;
  }

  constructor(siteMetaStore, preloadedData) {
    this.siteMetaStore = siteMetaStore || new SiteMetaStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.teamStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData() {
    this.loading = true;
    const loadedData = {
      teamStore: {},
    };

    return this.requestTeamData()
      .then(teamData => {
        loadedData.teamStore.teamData = teamData.items[0];
      })
      .then(() => this.requestPageOfMembers())
      .then(response => {
        loadedData.teamStore.members = response.items;
        loadedData.teamStore.shuffledIndexes = getShuffledIndexes(response.items);
        loadedData.teamStore.totalPages = Math.ceil(response.total / this.ITEMS_PER_PAGE);

        return loadedData;
      });
  }

  requestTeamData() {
    return contentful.getEntries('teamPage', {
      'sys.id': TEAM_PAGE_ID,
      limit: 1,
      include: this.INCLUDE_REFERENCE_DEPTH,
    })
      .catch(error => {
        console.log(error); // eslint-disable-line no-console
      });
  }

  requestPageOfMembers() {
    return contentful.getEntries('teamMember', {
      limit: this.ITEMS_PER_PAGE,
      include: this.INCLUDE_REFERENCE_DEPTH,
      skip: this.currentPage * this.ITEMS_PER_PAGE,
    });
  }

  @action('Load another page of team members')
  loadPageOfMembers = () => {
    this.loading = true;

    return this.requestPageOfMembers()
      .then(response => this.onPageOfMembersLoaded(response));
  }

  /**
   * Load the team data
   */
  @action('Load team page data')
  loadTeamData() {
    this.loading = true;
    this.requestTeamData()
      .then(teamData => {
        this.onTeamDataLoaded(teamData.items[0]);

        return this.requestPageOfMembers();
      })
      .then(response => this.onPageOfMembersLoaded(response));
  }

  @action('Adding prioritised team members first')
  prioritiseMembers() {
    const prioritisedMembers = getField(this.teamData, 'prioritisedTeamMembers') || [];
    prioritisedMembers.forEach(member => {
      this.prioritisedIds.push(member.sys.id);
      this.arrangedMembers.push(member);
    });
  }

  arrangeMembersByIndex(newMembers, indexArray) {
    const arrangedMembers = [];
    indexArray.forEach(index => {
      // Ignore prioritised members
      if (this.prioritisedIds.indexOf(newMembers[index].sys.id) === -1) {
        arrangedMembers.push(newMembers[index]);
      }
    });

    return arrangedMembers;
  }

  @action('Successfully loaded team page data')
  onTeamDataLoaded(teamData) {
    this.teamData = teamData;
    this.loading = false;
    this.siteMetaStore.setPageMetadata(getField(this.teamData, 'pageMetadata.fields'), getField(this.teamData, 'title'));
  }

  @action('Loaded a page of team members')
  onPageOfMembersLoaded(response) {
    const newMembers = response.items;

    // If this is the first page, add the prioritised members to the array first
    if (this.currentPage === 0) this.prioritiseMembers();
    // Randomise a set of indexes based off the length of the newMembers array
    const shuffledIndexes = getShuffledIndexes(newMembers);
    // Append the randomised new members to the existing array
    this.arrangedMembers = this.arrangedMembers.concat(this.arrangeMembersByIndex(newMembers, shuffledIndexes));
    this.currentPage++;
    this.totalPages = Math.ceil(response.total / this.ITEMS_PER_PAGE);
    this.loading = false;
  }

  @action('Preloaded team page data found')
  onPreloadedDataFound(preloadedData) {
    this.currentPage = 1;
    this.totalPages = preloadedData.teamStore.totalPages;
    this.totalMembers = preloadedData.teamStore.members.total;

    this.onTeamDataLoaded(preloadedData.teamStore.teamData);
    // Prioritise Roland (and possibly others, defined in CMS) first
    this.prioritiseMembers();
    // Get the randomised index order that was generated server-side
    const shuffledIndexes = preloadedData.teamStore.shuffledIndexes;
    // Add the members to the existing set (which will just be the prioritised ones at this point)
    // by the set of randomised indexes
    this.arrangedMembers = this.arrangedMembers.concat(this.arrangeMembersByIndex(preloadedData.teamStore.members, shuffledIndexes));
  }
}

export default TeamStore;
