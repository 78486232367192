import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { inject, observer } from 'mobx-react';

import AccountButton from 'components/common/buttons/AccountButton';
import FloatingPanel from 'components/siteHeader/FloatingPanel';
import AccountWrapper from 'components/common/accountPanel/AccountWrapper';
import LoggedInContent from 'components/common/accountPanel/LoggedInContent';
import LoggedOutContent from 'components/common/accountPanel/LoggedOutContent';

@inject('siteUIStore') @observer
class AccountPanel extends Component {

  constructor(props) {
    super(props);
    this.previewWrapper = undefined;
    this.state = {
      panelOpen: false,
      loggedIn: false,
      username: '',
      email: '',
      firstName: '',
      lastName: '',
    };
  }

  componentWillMount() {
    const userData = this.props.siteUIStore.userData || {};
    this.setState({
      loggedIn: !!userData.username,
      username: userData.username || '',
      email: userData.email || '',
      firstName: userData.first_name || '',
      lastName: userData.last_name || '',
    });
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.dataLayer && window.dataLayer.push({
        'event': 'showFreshChat',
        'chat_first_name': this.state.firstName,
        'chat_last_name': this.state.lastName,
        'chat_email': this.state.email,
      });
    }
  }

  onAccountButtonClicked = () => {
    this.setState({
      panelOpen: !this.state.panelOpen,
    });
  }

  onDocumentClickedOutside = () => {
    this.setState({
      panelOpen: false,
    });
  }

  render() {
    const { outsideApp } = this.props;

    return (
      <AccountWrapper open={this.state.panelOpen}>
        <AccountButton
          outsideApp={outsideApp}
          onButtonClicked={this.onAccountButtonClicked}
        />
        <FloatingPanel
          small={true}
          open={this.state.panelOpen}
          onDocumentClickedOutside={this.onDocumentClickedOutside}
        >
          {this.state.loggedIn
            ? <LoggedInContent username={this.state.username} />
            : <LoggedOutContent />
          }
        </FloatingPanel>
      </AccountWrapper>
    );
  }
}

AccountPanel.propTypes = {
  siteUIStore: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  outsideApp: PropTypes.bool,
};

export default withCookies(AccountPanel);
