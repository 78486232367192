import React from 'react';
import PropTypes from 'prop-types';

const CartIcon = ({ className }) => (
  <svg className={className} viewBox='0 0 32 32' version='1.1' preserveAspectRatio='xMidYMid meet'>
    <title>Cart icon</title>
    <g fill='none' fillRule='evenodd'>
      <path fill='#F2F2F2' fillRule='nonzero' d='M9 13v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V13H9zm-2-2h18v12a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V11z' />
      <path fill='#F2F2F2' fillRule='nonzero' d='M22 15v-4.507C22 7.434 19.291 5 16 5s-6 2.434-6 5.493v4.495a1 1 0 0 0 2 0v-4.495C12 8.59 13.768 7 16 7s4 1.59 4 3.493V15a1 1 0 0 0 2 0z' />
    </g>
  </svg>
);

CartIcon.propTypes = {
  className: PropTypes.string,
};

export default CartIcon;
