import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './RemoveFromCartButton.styl';

const RemoveFromCartButton = ({ label, small, onButtonClicked, lineIndex }) => {
  const classes = classNames({
    [styles.crossButton]: true,
    [styles.crossButtonSmall]: small,
  });

  return (
    <a
      className={classes}
      onClick={() => onButtonClicked(lineIndex)}
    >
      <span className={styles.removeFromCartButton}>
        {label}
      </span>
    </a>
  );
};

RemoveFromCartButton.propTypes = {
  label: PropTypes.string,
  small: PropTypes.bool,
  onButtonClicked: PropTypes.func,
  lineIndex: PropTypes.number,
};

export default withStyles(styles)(RemoveFromCartButton);
